import styled from "styled-components";

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 80vh;
  /* background: linear-gradient(90deg, #281a4b 0%, #281a4b 15%, #314f8b 100%); */
`;

export const LogoImage = styled.img`
  width: 200px;
  height: auto;
  margin-bottom: 10px;
`;
export const LogoText = styled.img`
  width: 200px;
  height: auto;
`;
