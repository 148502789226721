import { Stack, Switch, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { Div, Label } from "../../pages/news/divNewsDetail";

export const Settings = (props) => {
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 40,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 30,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(23px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
          "#00A79D",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  return (
    <>
      <Box
        style={{ marginTop: "5%" }}
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "50%" },
          display: "grid",
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <Label dark={props.dark}>Domain or Ip</Label>
          <Div dark={props.dark}>{props.domain}</Div>
          <Label dark={props.dark}>Port</Label>
          <Div dark={props.dark}>{props.port}</Div>
          <Label dark={props.dark}>Angle</Label>
          <Div dark={props.dark}>{props.angle}</Div>
          <Label dark={props.dark}>Frequency</Label>
          <Div dark={props.dark}>{props.frequency}</Div>
          <Label dark={props.dark}>Accuracy</Label>
          <Div dark={props.dark}>{props.accuracy}</Div>
          <Label dark={props.dark}>Distance</Label>
          <Div dark={props.dark}>{props.distance}</Div>
          <div style={{textAlign: "left" }}>
            <Stack
              direction="row"
              style={{ float: "right" }}
              spacing={1}
              alignItems="center"
            >
              <Typography style={{ color: "#00A79D", fontWeight: "bold" }}>
                OFFLINE BUFFERING
              </Typography>
              <AntSwitch
                inputProps={{ "aria-label": "ant design" }}
                checked={props.offline_buffering}
                defaultValue={true}
              />
            </Stack>
          </div>
        </div>
      </Box>
    </>
  );
};
