import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "../../components/Pagination";
import moment from 'moment-timezone';
import "../../index.css";
import Button from "@mui/material/Button";

import {
  Checkbox,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TableCell,
  TextField,
} from "@mui/material";
import { UserContext } from "../../context/UserContext.js";
import { refresh } from "../../refresh.js";
import { setItemPerPage } from "../../reducers/userSlice";
import useWindowDimensions from "../../hooks/dimension";
// import DialogDeleteConfirmation from "../../components/DialogDeleteConfirmation";
// import secureLocalStorage from "react-secure-storage";
import jwt_decode from "jwt-decode";

const MenuProps = {
  PaperProps: {
    style: {
      height: "auto",
      width: 250,
    },
  },
};

const ListDevice = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const user = useSelector((state) => state.userSlice.user);
  const itemPerPage = useSelector((state) => state.userSlice.itemPerPage);
  const navigate = useNavigate();
  const [deviceList, setDeviceList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [totalCount, setTotalCount] = useState(0);
  // const [pageOrdering, setPageOrdering] = useState(0);
  const [status, setStatus] = useState("all");
  const [type, setType] = useState("all");
  const { setValue, dark } = useContext(UserContext);
  // const [id, setId] = useState(null);
  // const [open, setOpen] = useState(false);
  const { width } = useWindowDimensions();
  const [customerId, setCustomerId] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [allowed, setAllowed] = useState(false);
  const handleChangePage = (event) => {
    const {
      target: { value },
    } = event;
    dispatch(setItemPerPage(value));
  };
  const [order, setOrder] = useState(null);
  const handleChange = (event) => {
    const value = event.target.value;
    setStatus(value);
    setCurrentPage(1);
  };

  const handleChangeType = (event) => {
    const value = event.target.value;
    setType(value);
    setCurrentPage(1);
  };

  const handleChangeCustomer = (event) => {
    const value = event.target.value;
    setCustomerId(value);
    setCurrentPage(1);
  };

  const StatusCircle = (props) => {
    let color =
      props.status === 1 ? "green" : props.status === 0 ? "red" : "red";
    return (
      <div
        style={{
          width: "25px",
          height: "25px",
          backgroundColor: color,
          margin: "auto",
          borderRadius: "20px",
        }}
      ></div>
    );
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const deleteHandler = (id) => {
  //   setValue(true);
  //   axios
  //     .delete(`${REACT_APP_API_ENDPOINT}/api/devices/` + id, {
  //       headers: {
  //         Authorization: "Bearer " + user.token,
  //       },
  //     })
  //     .then((res) => {
  //       const newList = deviceList.filter((item) => item.id !== id);
  //       setDeviceList(newList);
  //       setTotalCount((prev) => prev - 1);
  //       setCurrentPage(1);
  //       setPageOrdering((prev) => prev + 1);
  //     })
  //     .catch((err) => {
  //       if (err.response.status === 401) {
  //         const refresh_token = localStorage.getItem("refresh_token");
  //         if (refresh_token) {
  //           axios
  //             .post(`${REACT_APP_API_ENDPOINT}/token/refresh`, {
  //               refresh_token: refresh_token,
  //             })
  //             .then((data) => {
  //               console.log("refresh");
  //               axios
  //                 .delete(`${REACT_APP_API_ENDPOINT}/api/devices/` + id, {
  //                   headers: {
  //                     Authorization: "Bearer " + data.data.token,
  //                   },
  //                 })
  //                 .then((res) => {
  //                   const newList = deviceList.filter((item) => item.id !== id);
  //                   setDeviceList(newList);
  //                   setTotalCount((prev) => prev - 1);
  //                   setCurrentPage(1);
  //                   setPageOrdering((prev) => prev + 1);
  //                   dispatch(
  //                     login({
  //                       loggedIn: true,
  //                       token: data.data.token,
  //                     })
  //                   );
  //                 });
  //             })
  //             .catch((e) => {
  //               console.log(e);
  //               dispatch(logout());
  //               navigate("/login");
  //             });
  //         } else {
  //           console.log("no refresh token");
  //           dispatch(logout());
  //           navigate("/login");
  //         }
  //       }
  //     })
  //     .finally(() => {
  //       setValue(false);
  //       setOpen(false);
  //     });
  // };
  useEffect(() => {
    if (user === null) return;
    const roles = jwt_decode(user.token).roles;
    if (roles.includes("ROLE_ADMIN")) {
      setValue(true);
      axios
        .get(`${REACT_APP_API_ENDPOINT}/api/AllCustomers`, {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        })
        .then((data) => {
          setCustomerList(data.data["hydra:member"]);
          setAllowed(true);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            refresh(dispatch, navigate);
          }
        })
        .finally(() => {
          setValue(false);
        });
    }
  }, [REACT_APP_API_ENDPOINT, dispatch, navigate, setValue, user]);

  const [inputCountry, setInputCountry] = useState("");
  const [debouncedInputCountry, setDebouncedInputCountry] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedsearchTerm, setDebouncedsearchTerm] = useState("");

  const handleInputCountryChange = (event) => {
    setInputCountry(event.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedInputCountry(inputCountry);
      setCurrentPage(1);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [inputCountry, 500]);

  const handleInputSearchTerm = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedsearchTerm(searchTerm);
      setCurrentPage(1);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchTerm, 500]);

  useEffect(() => {
    if (user == null) {
      navigate("/login");
      return;
    }
    const s = status === "all" ? "" : status === "active" ? 1 : status === "inactive" ? 0 : 0;
    const t = type === "all" ? "" : type;
    setValue(true);
    let url = "";
    if (customerId === "") {
      url = `${REACT_APP_API_ENDPOINT}/api/devices?page=${currentPage}&itemsPerPage=${itemPerPage}&order%5BcreatedAt%5D=desc&status=${s}&type=${t}&user.email=${debouncedsearchTerm}&user.country.name=${debouncedInputCountry}&order[updatedAt]=${order}`;
    } else {
      url = `${REACT_APP_API_ENDPOINT}/api/devices?page=${currentPage}&itemsPerPage=${itemPerPage}&order%5BcreatedAt%5D=desc&status=${s}&type=${t}&customer=${customerId}&user.email=${debouncedsearchTerm}&user.country.name=${debouncedInputCountry}&order[updatedAt]=${order}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((data) => {
        setDeviceList(data.data["hydra:member"]);
        setTotalCount(data.data["hydra:totalItems"]);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          refresh(dispatch, navigate);
        }
      })
      .finally(() => {
        setValue(false);
      });
  }, [
    REACT_APP_API_ENDPOINT,
    currentPage,
    dispatch,
    navigate,
    user,
    status,
    debouncedInputCountry,
    debouncedsearchTerm,
    setValue,
    itemPerPage,
    customerId,
    type,
    order
  ]);

  function convertUtcToLocal(utcDateTime) {
    // Convert UTC date/time to local time zone
    const localDateTime = moment.utc(utcDateTime).local();

    // Format the local date/time as desired
    const formattedDateTime = localDateTime.format('YYYY-MM-DD HH:mm:ss');

    return formattedDateTime;
  }

  const tableContainerSx = {
    border: "0px",
    marginTop: 4,
    borderRadius: 2,
    backgroundColor: "rgba(0, 0, 0, 0)",
    width: "100%",
    maxWidth: width - 260,
  };

  return (
    <>
      <div className="device-filter">
        <div>
          <InputLabel
            id="demo-multiple-checkbox-label"
            style={{ color: dark ? "white" : "" }}
          >
            Country
          </InputLabel>
          <TextField
            size="small"
            id="outlined-basic"
            variant="outlined"
            sx={{
              width: 150,
              input: {
                color: dark ? "white" : "",
              },
              "& .MuiOutlinedInput-root": {
                "& > fieldset": { borderColor: dark ? "white" : "" },
              },
            }}
            placeholder="Country name"
            onChange={(event) => {
              handleInputCountryChange(event);
            }}
          ></TextField>
        </div>
        &nbsp; &nbsp;
        <div>
          <InputLabel
            id="demo-multiple-checkbox-label"
            style={{ color: dark ? "white" : "" }}
          >
            User
          </InputLabel>
          <TextField
            size="small"
            id="outlined-basic"
            variant="outlined"
            sx={{
              width: 150,
              input: {
                color: dark ? "white" : "",
              },
              "& .MuiOutlinedInput-root": {
                "& > fieldset": { borderColor: dark ? "white" : "" },
              },
            }}
            placeholder="Username"
            onChange={(event) => {
              handleInputSearchTerm(event);
            }}
          ></TextField>
        </div>
        &nbsp; &nbsp;
        <div>
          <div>
            <InputLabel
              id="demo-multiple-checkbox-label"
              style={{ color: dark ? "white" : "" }}
            >
              OS
            </InputLabel>
            <Select
              style={{ width: "100px", height: "40px" }}
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={type}
              onChange={handleChangeType}
              input={<OutlinedInput label="Status" />}
              MenuProps={MenuProps}
              sx={{
                color: dark ? "white" : "",
                background: dark
                  ? "linear-gradient(90deg, #314f8b 0%, #281a4b 95%, #281a4b 100%)"
                  : "",
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
            >
              <MenuItem em key={1} value={"all"}>
                <ListItemText primary={"all"} />
              </MenuItem>

              <MenuItem key={2} value={"android"}>
                <ListItemText primary={"android"} />
              </MenuItem>

              <MenuItem key={3} value={"ios"}>
                <ListItemText primary={"ios"} />
              </MenuItem>
            </Select>
          </div>
        </div>
        &nbsp; &nbsp;
        <div>
          <div>
            <InputLabel
              id="demo-multiple-checkbox-label"
              style={{ color: dark ? "white" : "" }}
            >
              Status
            </InputLabel>
            <Select
              style={{ width: "100px", height: "40px" }}
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={status}
              onChange={handleChange}
              input={<OutlinedInput label="Status" />}
              MenuProps={MenuProps}
              sx={{
                color: dark ? "white" : "",
                background: dark
                  ? "linear-gradient(90deg, #314f8b 0%, #281a4b 95%, #281a4b 100%)"
                  : "",
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
            >
              <MenuItem em key={1} value={"all"}>
                <ListItemText primary={"all"} />
              </MenuItem>

              <MenuItem key={2} value={"active"}>
                <ListItemText primary={"active"} />
              </MenuItem>

              <MenuItem key={3} value={"inactive"}>
                <ListItemText primary={"inactive"} />
              </MenuItem>
            </Select>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {allowed && (
            <>
              <div>
                <InputLabel
                  id="demo-multiple-checkbox-label"
                  style={{ color: dark ? "white" : "" }}
                >
                  Customer
                </InputLabel>
                <Select
                  style={{ width: "100px", height: "40px" }}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={customerId}
                  onChange={handleChangeCustomer}
                  input={<OutlinedInput label="Customer" />}
                  MenuProps={MenuProps}
                  sx={{
                    color: dark ? "white" : "",
                    background: dark
                      ? "linear-gradient(90deg, #314f8b 0%, #281a4b 95%, #281a4b 100%)"
                      : "",
                    "& .MuiOutlinedInput-root": {
                      "& > fieldset": { borderColor: dark ? "white" : "" },
                    },
                  }}
                >
                  {customerList.map((data, index) => (
                    <MenuItem key={index} value={data.id}>
                      <ListItemText primary={data.name} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div style={{ height: 50, marginTop: 20 }}>
                <button
                  onClick={() => setCustomerId("")}
                  style={{
                    color: "white",
                    padding: 10,
                    background:
                      "linear-gradient(90deg, #314f8b 0%, #281a4b 95%, #281a4b 100%)",
                    border: "1px solid white",
                    borderRadius: 10,
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                >
                  reset
                </button>
              </div>
            </>
          )}
          <FormControlLabel 
            sx={{color: dark ? "white" : ""}} 
            control={<Checkbox sx={{color: dark ? "white" : ""}} />} 
            label="Sort by lastseen" 
            onChange={(event) => {
              setOrder(event.target.checked ? "desc" : null);
            }}
          />

        </div>
        {/* <DialogDeleteConfirmation
          open={open}
          handleClose={handleClose}
          message={"Are you sure you want to delete this device ?"}
          deleteHandler={deleteHandler}
          id={id}
        /> */}
      </div>

      <br />
      <br />
      <TableContainer sx={tableContainerSx}>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                "& th": {
                  fontSize: "0.8rem",
                  color: dark ? "white" : "",
                },
              }}
            >
              <TableCell align="center">name</TableCell>
              <TableCell align="center">os</TableCell>
              <TableCell align="center">battery</TableCell>
              <TableCell align="center">lastseen</TableCell>
              <TableCell align="center">user</TableCell>
              <TableCell align="center">country</TableCell>
              <TableCell align="center">status</TableCell>
              {/* <TableCell align="center"></TableCell> */}
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deviceList.map((row, index) => (
              <TableRow
                hover
                key={index}
                sx={{
                  "& td": {
                    color: dark ? "white" : "",
                    fontSize: "0.6rem",
                  },
                }}
              >
                <TableCell align="center">{row.device_id}</TableCell>
                <TableCell align="center">{row.type}</TableCell>
                <TableCell align="center">
                  {row.battery ? row.battery + '%' : ''}
                </TableCell>
                <TableCell align="center">
                  {convertUtcToLocal(row.updatedAt)}
                </TableCell>
                <TableCell align="center">{row.user.firstname} {row.user.lastname}</TableCell>
                <TableCell align="center">{row.user?.country?.name}</TableCell>
                <TableCell align="center">
                  <StatusCircle status={row.status} />
                </TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#00c8ff",
                      fontSize: 11,
                      padding: 1,
                    }}
                    onClick={() => {
                      navigate("/showDevice/" + row.id);
                    }}
                  >
                    Details
                  </Button>
                </TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#84c4bf",
                      fontSize: 11,
                      padding: 1,
                    }}
                    onClick={() => {
                      navigate("/showUser/" + row.user.id);
                    }}
                  >
                    User Info
                  </Button>
                </TableCell>

                {/* <TableCell align="center">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#92c8ec",
                      fontSize: 11,
                      padding: 1,
                    }}
                    onClick={() => {
                      navigate("/editDevice/" + row.id);
                    }}
                  >
                    Edit
                  </Button>
                </TableCell>
                <TableCell align="center">
                  <button
                    onClick={() => {
                      setId(row.id);
                      setOpen(true);
                    }}
                    style={{ fontSize: 13 }}
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                </TableCell> */}
                <TableCell align="center">
                  <Button
                    variant="contained"
                    sx={{
                      background: dark ? "rgba(0, 0, 0, 0)" : "",
                      border: "0px",
                      fontSize: 11,
                      padding: 1,
                    }}
                    onClick={() => {
                      navigate("/trace/" + row.id);
                    }}
                  >
                    Trace
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <hr style={{ color: dark ? "white" : "black" }} />
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <InputLabel
          id="demo-multiple-checkbox-label"
          style={{ color: dark ? "white" : "", fontSize: "10px" }}
        >
          Rows per page
        </InputLabel>
        &nbsp; &nbsp; &nbsp;
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          value={itemPerPage}
          onChange={handleChangePage}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected}
          MenuProps={MenuProps}
          sx={{
            color: dark ? "white" : "",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: dark ? "white" : "",
            },
            ".MuiSvgIcon-root ": {
              fill: "white !important",
            },
          }}
        >
          <MenuItem key={1} value={5}>
            <ListItemText primary={5} />
          </MenuItem>

          <MenuItem key={2} value={20}>
            <ListItemText primary={20} />
          </MenuItem>

          <MenuItem key={3} value={50}>
            <ListItemText primary={50} />
          </MenuItem>
        </Select>
      </div>

      <Pagination
        className="pagination-bar"
        page={currentPage}
        count={Math.ceil(totalCount / itemPerPage)}
        onChange={(event, value) => {
          setCurrentPage(value);
        }}
      />
    </>
  );
};

export default ListDevice;