import { Box, FormGroup, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "./styles.scss";

import Moment from "moment";

// import required modules
import { FreeMode, Pagination } from "swiper";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { Label } from "./divNewsDetail";
import { refresh } from "../../refresh";

const ShowNews = () => {
  const { setValue, dark } = useContext(UserContext);
  const dispatch = useDispatch();
  const { REACT_APP_API_ENDPOINT } = process.env;
  const auth = useSelector((state) => state.userSlice.user);
  const navigate = useNavigate();
  const [news, setNews] = useState();

  const id = useParams()["id"];
  useEffect(() => {
    setValue(true);
    if (auth == null) {
      console.log(auth);
      navigate("/login");
      return;
    }
    axios
      .get(`${REACT_APP_API_ENDPOINT}/api/news/` + id, {
        headers: { Authorization: "Bearer " + auth.token },
      })
      .then((data) => setNews(data.data))
      .catch((err) => {
        if (err.response.status === 401) {
          refresh(dispatch, navigate);
        }
      })
      .finally(() => {
        setValue(false);
      });
  }, [REACT_APP_API_ENDPOINT, auth, id, navigate, dispatch, setValue]);
  console.log(news);

  return (
    <>
      <h2 style={{ color: dark ? "white" : "" }}>Show News</h2>
      <div style={{ width: "100%", marginBottom: "5%", marginTop: "5%" }}>
        <div style={{ width: "80%", margin: "auto" }}>
          {news && (
            <>
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-around"}
                alignItems={"center"}
              >
                <img
                  src={news.fileUrl}
                  alt={""}
                  loading="lazy"
                  width={"400px"}
                  margin={"auto"}
                />

                <Swiper
                  modules={[FreeMode, Pagination]}
                  pagination={{
                    dynamicBullets: true,
                  }}
                  freeMode={true}
                  className="mySwiper"
                  // slidesPerView={1}
                  // spaceBetween={30}
                >
                  {news.country.map((data, index) => (
                    <SwiperSlide
                      key={index}
                      style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                    >
                      <img
                        style={{ width: "100px", height: "60px" }}
                        src={data.flagUrl}
                        alt=""
                      />
                      <p style={{ color: dark ? "white" : "" }}>{data.name}</p>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div style={{ color: dark ? "white" : "" }}>
                  <b style={{ fontSize: 25 }}>Published at : </b>
                  {Moment(news.publishedAt).format("MMMM Do YYYY")}
                </div>
              </Box>
              <br />
              <br />
              <FormGroup>
                <div
                  style={{
                    color: dark ? "white" : "",
                    textAlign: "center",
                    fontSize: 30,
                  }}
                >
                  {news.title}
                </div>
                <br />
                {news.description && (
                  <>
                    <Label dark={dark}>Content</Label>
                    <div style={{ color: dark ? "white" : "" }}>
                      <Typography align="justify" fontSize={20}>
                        {news.description}
                      </Typography>
                    </div>
                  </>
                )}
                <br />
                <br />
                <br />
                <br />
                {news.url && (
                  <>
                    <p style={{ color: dark ? "white" : "", margin: "auto" }}>
                      Source
                    </p>
                    <div
                      style={{
                        color: dark ? "white" : "",
                        border: dark ? "1px solid white" : "1px solid black",
                        width: "30%",
                        margin: "auto",
                        textAlign: "center",
                        borderRadius: 10,
                      }}
                    >
                      <a style={{ color: dark ? "white" : "" }} href={news.url}>
                        Click the link
                      </a>
                    </div>
                  </>
                )}

                <br />
                <br />
              </FormGroup>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ShowNews;
