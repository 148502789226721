import {
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Button,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import secureLocalStorage from "react-secure-storage";
import { UserContext } from "../../context/UserContext";
import { login, logout } from "../../reducers/userSlice";

const useHelperTextStyles = makeStyles(() => ({
  root: {
    color: "#9694ad",
  },
}));

const EditDevice = () => {
  const helperTextStyles = useHelperTextStyles();
  const { setValue, dark } = useContext(UserContext);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const auth = useSelector((state) => state.userSlice.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [user, setUser] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const id = useParams()["id"];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  let valid = name !== "" && type !== "" && user !== "";

  const onSubmit = () => {
    if (valid) {
      setLoading(true);

      axios
        .put(
          `${REACT_APP_API_ENDPOINT}/api/devices/` + id,
          {
            name: name,
            type: type,
            user: "/api/users/" + user,
          },
          {
            headers: {
              Authorization: "Bearer " + auth.token,
            },
          }
        )
        .then((data) => {
          console.log(data);
          navigate("/");
        })
        .catch((err) => {
          console.log(err);
          dispatch(logout());
          navigate("/login");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleChange = (event) => {
    setUser(event.target.value);
  };

  useEffect(() => {
    // Accessing scss variable "--background-color"
    // and "--text-color" using plain JavaScript
    // and changing the same according to the state of "darkTheme"
    const root = document.documentElement;
    root?.style.setProperty("--text-color", dark ? "#fff" : "#000");
  }, [dark]);
  
  useEffect(() => {
    if (!auth) {
      return;
    }
    setValue(true);
    axios
      .get(`${REACT_APP_API_ENDPOINT}/api/getAllUsers`, {
        headers: {
          Authorization: "Bearer " + auth.token,
        },
      })
      .then((data) => {
        setUsers(data.data);
        axios
          .get(`${REACT_APP_API_ENDPOINT}/api/devices/` + id, {
            headers: { Authorization: "Bearer " + auth.token },
          })
          .then((data) => {
            setName(data.data.name);
            setType(data.data.type);
            setUser(data.data.user.id);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          const refresh_token = localStorage.getItem("refresh_token");
          if (refresh_token) {
            axios
              .post(`${REACT_APP_API_ENDPOINT}/token/refresh`, {
                refresh_token: refresh_token,
              })
              .then((data) => {
                console.log("refresh");
                axios
                .get(`${REACT_APP_API_ENDPOINT}/api/getAllUsers`, {
                  headers: {
                    Authorization: "Bearer " + data.data.token,
                  },
                })
                .then((data) => {
                  setUsers(data.data);
                  axios
                    .get(`${REACT_APP_API_ENDPOINT}/api/devices/` + id, {
                      headers: { Authorization: "Bearer " + data.data.token },
                    })
                    .then((data) => {
                      setName(data.data.name);
                      setType(data.data.type);
                      setUser(data.data.user.id);
                      dispatch(
                        login({
                          loggedIn: true,
                          token: data.data.token,
                        })
                      );
                    })
                    .catch((err) => console.log(err));
                })
              })
              .catch((e) => {
                console.log(e);
                dispatch(logout());
                navigate("/login");
              });
          } else {
            console.log("no refresh token");
            dispatch(logout());
            navigate("/login");
          }
        }
      })
      .finally(() => {
        setValue(false);
      });
  }, [REACT_APP_API_ENDPOINT, dispatch, navigate, auth, id, setValue]);

  return (
    <>
      <h2 style={{ color: dark ? "white" : "" }}>Edit Device</h2>
      <div style={{ width: "100%", marginBottom: "5%", marginTop: "5%" }}>
        <div style={{ width: "80%", margin: "auto" }}>
          <FormGroup>
            <TextField
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
              id="outlined-basic"
              label="Name"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
              FormHelperTextProps={{
                classes: {
                  root: helperTextStyles.root,
                },
              }}
            ></TextField>
            <br />
            <TextField
              value={type}
              onChange={(event) => {
                setType(event.target.value);
              }}
              id="outlined-basic"
              label="Type"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
              FormHelperTextProps={{
                classes: {
                  root: helperTextStyles.root,
                },
              }}
            ></TextField>
            <br />
            <InputLabel
              id="demo-checkbox-label"
              style={{ color: dark ? "white" : "" }}
            >
              User
            </InputLabel>
            <Select
              labelId="demo-checkbox-label"
              id="demo-checkbox"
              value={user}
              onChange={handleChange}
              input={<OutlinedInput label="Users" />}
              MenuProps={MenuProps}
              labelStyle={{ color: "#ff0000" }}
              sx={{
                color: "white",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: dark ? "white" : "",
                },
                ".MuiSvgIcon-root ": {
                  fill: "white !important",
                },
              }}
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  <ListItemText
                    primary={
                      user.id + " - " + user.firstname + " " + user.lastname
                    }
                  />
                </MenuItem>
              ))}
            </Select>
            <br />
            <br />

            <Button
              disabled={!valid || loading}
              onClick={onSubmit}
              style={{
                width: "20%",
                borderRadius: 10,
                padding: "5px 20px",
                fontSize: "18px",
                backgroundColor: "#00A79D",
                alignSelf: "center",
              }}
              variant="contained"
            >
              Submit
            </Button>
          </FormGroup>
        </div>
      </div>
    </>
  );
};

export default EditDevice;
