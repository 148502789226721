// import React from "react";
// import classnames from "classnames";
// import { usePagination, DOTS } from "../../hooks/pagination";
// import "./pagination.scss";
// const Page = (props) => {
//   const {
//     onPageChange,
//     totalCount,
//     siblingCount = 1,
//     currentPage,
//     pageSize,
//     className,
//   } = props;

import { Pagination, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContext, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import "./pagination.scss";
//   const paginationRange = usePagination({
//     currentPage,
//     totalCount,
//     siblingCount,
//     pageSize,
//   });

//   // If there are less than 2 times in pagination range we shall not render the component
//   if (currentPage === 0 || paginationRange.length < 2) {
//     return null;
//   }

//   const onNext = () => {
//     onPageChange(currentPage + 1);
//   };

//   const onPrevious = () => {
//     onPageChange(currentPage - 1);
//   };

//   let lastPage = paginationRange[paginationRange.length - 1];
//   return (
//     <ul
//       className={classnames("pagination-container", { [className]: className })}
//     >
//       {/* Left navigation arrow */}
//       <li
//         className={classnames("pagination-item", {
//           disabled: currentPage === 1,
//         })}
//         onClick={onPrevious}
//       >
//         <div className="arrow left" />
//       </li>
//       {paginationRange.map((pageNumber, index) => {
//         // If the pageItem is a DOT, render the DOTS unicode character
//         if (pageNumber === DOTS) {
//           return (
//             <li key={index} className="pagination-item dots">
//               &#8230;
//             </li>
//           );
//         }

//         // Render our Page Pills
//         return (
//           <>
//             <li
//               key={index}
//               className={classnames("pagination-item", {
//                 selected: pageNumber === currentPage,
//               })}
//               onClick={() => onPageChange(pageNumber)}
//             >
//               {pageNumber}
//             </li>
//             {pageNumber !== lastPage && (
//               <li className={classnames("dot-item")}>.</li>
//             )}
//           </>
//         );
//       })}
//       {/*  Right Navigation arrow */}
//       <li
//         className={classnames("pagination-item", {
//           disabled: currentPage === lastPage,
//         })}
//         onClick={onNext}
//       >
//         <div className="arrow right" />
//       </li>
//     </ul>
//   );
// };

const Page = (props) => {
  const { dark } = useContext(UserContext);

  useEffect(() => {
    // Accessing scss variable "--background-color"
    // and "--text-color" using plain JavaScript
    // and changing the same according to the state of "darkTheme"
    const root = document.documentElement;
    root?.style.setProperty(
      "--background-color",
      dark ? "rgba(255, 255, 255, 0.08)" : "rgba(0, 0, 0, 0.08)"
    );
    root?.style.setProperty("--text-color", dark ? "#fff" : "#000");
  }, [dark]);

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .Mui-selected": {
        backgroundColor: dark ? "rgba(255, 255, 255, 0.08)" : "",
        color: dark ? "#fff" : "",
      },
      "& ul > li:not(:first-child):not(:last-child) > button:not(.Mui-selected)":
        {
          backgroundColor: dark ? "transparent" : "",
          color: dark ? "#fff" : "",
        },
      "& .MuiPaginationItem-icon": {
        color: dark ? "#13a89e" : "",
      },
    },
  }));

  const classes = useStyles();
  return (
    <Stack spacing={2}>
      <Pagination
        className={classes.root}
        count={props.count}
        defaultPage={1}
        page={props.page}
        onChange={props.onChange}
        style={{ alignSelf: "center" }}
      />
    </Stack>
  );
};
export default Page;
