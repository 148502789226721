import {
  Box,
  Button,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { login, logout } from "../../reducers/userSlice";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import "./style.scss";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import secureLocalStorage from "react-secure-storage";
import { refresh } from "../../refresh";
import { toast } from "react-toastify";

const { REACT_APP_API_ENDPOINT } = process.env;

export const Settings = () => {
  const [domain, setDomain] = useState("");
  // const [ip, setIp] = useState("");
  const [port, setPort] = useState("");
  const [angle, setAngle] = useState("");
  const [accuracy, setAccuracy] = useState("");
  const [distance, setDistance] = useState("");
  const [frequency, setFrequency] = useState("");
  const [offline_buffering, setOffline_buffering] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setValue, dark } = useContext(UserContext);

  const [customerId, setCustomerId] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [allowed, setAllowed] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.userSlice.user);

  const valid =
    domain !== "" &&
    accuracy !== "" &&
    angle !== "" &&
    distance !== "" &&
    distance >= 0 &&
    angle >= 0 &&
    frequency >= 0 &&
    frequency !== "" &&
    // ip !== "" &&
    port !== "";

  useEffect(() => {
    // Accessing scss variable "--background-color"
    // and "--text-color" using plain JavaScript
    // and changing the same according to the state of "darkTheme"
    const root = document.documentElement;
    root?.style.setProperty("--text-color", dark ? "#fff" : "#000");
  }, [dark]);

  useEffect(() => {
    if (user === null) return;
    const roles = jwt_decode(user.token).roles;
    if (roles.includes("ROLE_ADMIN")) {
      setValue(true);
      axios
        .get(`${REACT_APP_API_ENDPOINT}/api/AllCustomers`, {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        })
        .then((data) => {
          if (data.data["hydra:member"].length > 0) {
            // setCustomerId(data.data["hydra:member"][0].id);
          }
          setCustomerList(data.data["hydra:member"]);
          setAllowed(true);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            refresh(dispatch, navigate);
          }
        })
        .finally(() => {
          setValue(false);
        });
    }
  }, [dispatch, navigate, setValue, user]);

  useEffect(() => {
    if (!user) return;
    setValue(true);
    let url = "";
    const roles = jwt_decode(user.token).roles;
    if (roles.includes("ROLE_ADMIN")) {
      if (customerId === "") return;
      url = `${REACT_APP_API_ENDPOINT}/api/getSettingCustomer/${customerId}`;
    } else {
      url = `${REACT_APP_API_ENDPOINT}/api/getSettings`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((data) => {
        if (data.data !== null) {
          setDomain(data.data.domain);
          setAccuracy(data.data.accuracy);
          setAngle(data.data.angle);
          setDistance(data.data.distance);
          setFrequency(data.data.frequency / 1000);
          setOffline_buffering(data.data.offline_buffering);
          // setIp(data.data.ip);
          setPort(data.data.port);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          refresh(dispatch, navigate);
        }
      })
      .finally(() => {
        setValue(false);
      });
  }, [allowed, customerId, dispatch, navigate, setValue, user]);

  const handleDomain = (event) => {
    setDomain(event.target.value);
  };
  // const handleIp = (event) => {
  //   setIp(event.target.value);
  // };
  const handlePort = (event) => {
    setPort(event.target.value);
  };
  const handleAngle = (event) => {
    setAngle(event.target.value);
  };
  const handleAccuracy = (event) => {
    setAccuracy(event.target.value);
  };
  const handleDistance = (event) => {
    setDistance(event.target.value);
  };
  const handleFrequency = (event) => {
    setFrequency(event.target.value);
  };
  const handleOfflineBuffering = (event) => {
    setOffline_buffering(event.target.checked);
  };

  const handleSubmit = () => {
    if (valid) {
      setLoading(true);
      let url = "";
      if (allowed) {
        url = `${REACT_APP_API_ENDPOINT}/api/users/updateAdminCustomerSetting?customer=${customerId}`;
      } else {
        url =
          `${REACT_APP_API_ENDPOINT}/api/users/updateCustomerSetting/` +
          jwt_decode(user.token).id;
      }
      axios
        .post(
          url,
          {
            settings: {
              domain: domain,
              accuracy: accuracy,
              angle: angle * 1,
              distance: distance * 1,
              frequency: frequency * 1000,
              offline_buffering: offline_buffering,
              // ip: ip,
              port: port * 1,
            },
          },
          {
            headers: {
              Authorization: "Bearer " + user.token,
            },
          }
        )
        .then((res) => {
          toast.success("settings updated");
          console.log(res.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            const refresh_token = localStorage.getItem("refresh_token");
            if (refresh_token) {
              axios
                .post(`${REACT_APP_API_ENDPOINT}/token/refresh`, {
                  refresh_token: refresh_token,
                })
                .then((data) => {
                  console.log("refresh");
                  axios
                    .post(
                      url + jwt_decode(user.token).id,
                      {
                        settings: {
                          domain: domain,
                          accuracy: accuracy,
                          angle: angle,
                          distance: distance,
                          frequency: frequency * 1000,
                          offline_buffering: offline_buffering,
                          // ip: ip,
                          port: port,
                        },
                      },
                      {
                        headers: {
                          Authorization: "Bearer " + data.data.token,
                        },
                      }
                    )
                    .then(() => {
                      dispatch(
                        login({
                          loggedIn: true,
                          token: data.data.token,
                        })
                      );
                    });
                })
                .catch((e) => {
                  console.log(e);
                  dispatch(logout());
                  navigate("/login");
                });
            } else {
              console.log("no refresh token");
              dispatch(logout());
              navigate("/login");
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 40,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 30,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(23px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#00A79D",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const useHelperTextStyles = makeStyles(() => ({
    root: {
      color: dark ? "white !important" : "",
    },
  }));

  const helperTextStyles = useHelperTextStyles();

  const handleChangeCustomer = (event) => {
    const value = event.target.value;
    setCustomerId(value);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        height: "auto",
        width: 250,
      },
    },
  };

  return (
    <>
      <h1 style={{ color: dark ? "white" : "" }}>Settings</h1>
      {allowed && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          {/* <Button
            variant="contained"
            sx={{
              backgroundColor: "#13a89e",
            }}
            onClick={() => {
              navigate("/createDevice");
            }}
          >
            Create
          </Button> */}
          <div>
            <InputLabel
              id="demo-multiple-checkbox-label"
              style={{ color: dark ? "white" : "" }}
            >
              Customer
            </InputLabel>
            <Select
              style={{ width: "100px", height: "40px" }}
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={customerId}
              onChange={handleChangeCustomer}
              input={<OutlinedInput label="Customer" />}
              MenuProps={MenuProps}
              sx={{
                color: dark ? "white" : "",
                background: dark
                  ? "linear-gradient(90deg, #314f8b 0%, #281a4b 95%, #281a4b 100%)"
                  : "",
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
            >
              {customerList.map((data, index) => (
                <MenuItem key={index} value={data.id}>
                  <ListItemText primary={data.name} />
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      )}
      <Box
        style={{ textAlign: "center" }}
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "50%" },
          display: "grid",
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            id="outlined-select-currency"
            label="Domain or Ip"
            value={domain}
            onChange={handleDomain}
            helperText="Please select a domain or ip"
            sx={{
              input: { color: dark ? "white" : "" },
              "& .MuiOutlinedInput-root": {
                "& > fieldset": { borderColor: dark ? "white" : "" },
              },
            }}
            InputLabelProps={{
              style: { color: dark ? "#fff" : "" },
            }}
            FormHelperTextProps={{
              classes: {
                root: helperTextStyles.root,
              },
            }}
          ></TextField>
          {/* <TextField
            id="outlined-select-currency"
            label="Ip"
            value={ip}
            onChange={setIp}
            helperText="Please select an ip"
          ></TextField> */}
          <TextField
            id="outlined-select-currency"
            label="Port"
            value={port}
            onChange={handlePort}
            helperText="Please select a port"
            sx={{
              input: { color: dark ? "white" : "" },
              "& .MuiOutlinedInput-root": {
                "& > fieldset": { borderColor: dark ? "white" : "" },
              },
            }}
            InputLabelProps={{
              style: { color: dark ? "#fff" : "" },
            }}
            FormHelperTextProps={{
              classes: {
                root: helperTextStyles.root,
              },
            }}
          ></TextField>
          <TextField
            id="outlined-select-currency"
            type="number"
            label="Angle"
            value={angle}
            onChange={handleAngle}
            helperText="Please select an angle"
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            sx={{
              input: { color: dark ? "white" : "" },
              "& .MuiOutlinedInput-root": {
                "& > fieldset": { borderColor: dark ? "white" : "" },
              },
            }}
            InputLabelProps={{
              style: { color: dark ? "#fff" : "" },
            }}
            FormHelperTextProps={{
              classes: {
                root: helperTextStyles.root,
              },
            }}
          ></TextField>

          <TextField
            id="outlined-select-currency"
            type="number"
            label="Frequency"
            value={frequency}
            onChange={handleFrequency}
            helperText="Please select a frequency"
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            sx={{
              input: { color: dark ? "white" : "" },
              "& .MuiOutlinedInput-root": {
                "& > fieldset": { borderColor: dark ? "white" : "" },
              },
            }}
            InputLabelProps={{
              style: { color: dark ? "#fff" : "" },
            }}
            FormHelperTextProps={{
              classes: {
                root: helperTextStyles.root,
              },
            }}
          ></TextField>
          <br />
          <TextField
            select
            id="outlined-select-currency"
            label="Accuracy"
            value={accuracy}
            onChange={handleAccuracy}
            helperText="Please select an accuracy"
            sx={{
              input: { color: "white" },
              "& .MuiOutlinedInput-root": {
                "& > fieldset": { borderColor: dark ? "white" : "" },
              },
              ".MuiSvgIcon-root ": {
                fill: "#00A79D !important",
              },
            }}
            InputLabelProps={{
              style: { color: dark ? "#fff" : "" },
            }}
            FormHelperTextProps={{
              classes: {
                root: helperTextStyles.root,
              },
            }}
          >
            <MenuItem key={1} value={"high"}>
              High
            </MenuItem>
            <MenuItem key={2} value={"mid"}>
              Mid
            </MenuItem>
            <MenuItem key={3} value={"low"}>
              Low
            </MenuItem>
          </TextField>
          <br />
          <TextField
            id="outlined-select-currency"
            type="number"
            label="Distance"
            value={distance}
            onChange={handleDistance}
            helperText="Please select a distance"
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            sx={{
              input: { color: dark ? "white" : "" },
              "& .MuiOutlinedInput-root": {
                "& > fieldset": { borderColor: dark ? "white" : "" },
              },
            }}
            InputLabelProps={{
              style: { color: dark ? "#fff" : "" },
            }}
            FormHelperTextProps={{
              classes: {
                root: helperTextStyles.root,
              },
            }}
          ></TextField>
          <br />
          <br />
          <div style={{ width: "50%", textAlign: "right", margin: "auto" }}>
            <Stack
              direction="row"
              style={{ float: "right" }}
              spacing={1}
              alignItems="center"
            >
              <Typography style={{ color: "#00A79D", fontWeight: "bold" }}>
                OFFLINE BUFFERING
              </Typography>
              <AntSwitch
                defaultChecked
                inputProps={{ "aria-label": "ant design" }}
                checked={offline_buffering}
                onChange={handleOfflineBuffering}
              />
            </Stack>
          </div>
          <br />
          <br />
          <Button
            disabled={!valid || loading}
            onClick={handleSubmit}
            style={{
              width: "20%",
              borderRadius: 10,
              padding: "5px 20px",
              fontSize: "18px",
              backgroundColor: "#00A79D",
            }}
            variant="contained"
          >
            Submit
          </Button>
        </div>
      </Box>
    </>
  );
};
