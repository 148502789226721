import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router";
import Box from "@mui/material/Box";
import Sidebar from "../../components/Sidebar";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import CreateDevice from "../device/create";
import TrackDevice from "../device/track";
import ListDevice from "../device";
import ShowDevice from "../device/show";
import EditDevice from "../device/edit";
import ListNews from "../news";
import ShowNews from "../news/show";
import CreateNews from "../news/create";
import EditNews from "../news/edit";
import ListUsers from "../users";
import ShowUser from "../users/show";
import { CreateUser } from "../users/create";
import { EditUser } from "../users/edit";
import ListAlerts from "../alerts";
import { Settings } from "../settings";
import UserContacts from "../users/contacts";
import History from "../device/history";
import Monitor from "../monitor";
import MyLoader from "../../components/Loader";
import { EditContact } from "../users/editContact";
import Trace from "../device/trace";
import { UserContext } from "../../context/UserContext";
import HomePage from "./home";
import { CreateContact } from "../users/AddContact";
import PolygonMap from "../device/geofenceName";
import Show from "../alerts/show";
import CustomerList from "../client";
import CreateCustomer from "../client/create";
import EditCustomer from "../client/edit";
import { CreateAdmin } from "../client/admin";
import Message from "../messages";
import CreateMessage from "../messages/create";

const Home = () => {
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const { REACT_APP_API_ENDPOINT } = process.env;
  const user = useSelector((state) => state.userSlice.user);

  const [value, setValue] = useState(false);
  const [dark, setDark] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (user == null) {
      console.log(user);
      navigate("/login");
      return;
    }
    // axios
    //   .get(`${REACT_APP_API_ENDPOINT}/api/me`, {
    //     headers: {
    //       Authorization: "Bearer " + user.token,
    //     },
    //   })
    //   .then((data) => {
    //     console.log(data);
    //   })
    //   .catch((err) => {
    //     // console.log(err);
    //     navigate('/login')
    //   });
  }, [REACT_APP_API_ENDPOINT, navigate, user]);

  return (
    <UserContext.Provider value={{ value, setValue, dark, setDark }}>
      <MyLoader active={value}>
        <Box
          sx={{
            display: "flex",
            background: dark
              ? "linear-gradient(90deg, #281a4b 0%, #281a4b 15%, #314f8b 100%)"
              : "white",
            height: "auto",
            minHeight: "100vh",
          }}
        >
          <Sidebar user={user} />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <DrawerHeader />
            <Routes>
              {/* Device Routes */}
              <Route exact path="/" element={<HomePage />} />
              <Route exact path="/devices" element={<ListDevice />} />
              <Route exact path="/createDevice" element={<CreateDevice />} />
              <Route exact path="/showDevice/:id" element={<ShowDevice />} />
              <Route exact path="/editDevice/:id" element={<EditDevice />} />
              <Route exact path="/trackDevice/:id" element={<TrackDevice />} />
              <Route exact path="/monitor" element={<Monitor />} />
              <Route exact path="/history/:id" element={<History />} />
              <Route exact path="/trace/:id" element={<Trace />} />
              {/* <Route exact path="/geofence" element={<Map />} /> */}
              <Route exact path="/geofence" element={<PolygonMap />} />
              {/* News Routes */}
              <Route exact path="/news" element={<ListNews />} />
              <Route exact path="/createNews" element={<CreateNews />} />
              <Route exact path="/showNews/:id" element={<ShowNews />} />
              <Route exact path="/editNews/:id" element={<EditNews />} />

              {/* Users Routes */}
              <Route exact path="/users" element={<ListUsers />} />
              <Route exact path="/createUser" element={<CreateUser />} />
              <Route
                exact
                path="/createContact/:id"
                element={<CreateContact />}
              />
              <Route exact path="/showUser/:id" element={<ShowUser />} />
              <Route exact path="/editUser/:id" element={<EditUser />} />
              <Route exact path="/editContact/:id" element={<EditContact />} />
              <Route
                exact
                path="/showUserContacts/:id"
                element={<UserContacts />}
              />

              {/* Alert Routes */}
              <Route exact path="/alerts" element={<ListAlerts />} />
              <Route exact path="/showAlert/:id" element={<Show />} />

              {/* Settings Routes */}
              <Route exact path="/settings" element={<Settings />} />

              {/* Customer Routes */}
              <Route exact path="/customers" element={<CustomerList />} />
              <Route
                exact
                path="/createCustomer"
                element={<CreateCustomer />}
              />
              <Route
                exact
                path="/editCustomer/:id"
                element={<EditCustomer />}
              />
              <Route exact path="/createAdmin" element={<CreateAdmin />} />
              {/* Messages */}
              <Route exact path="/messages" element={<Message />} />
              <Route exact path="/createMessage" element={<CreateMessage />} />
            </Routes>
          </Box>
        </Box>
      </MyLoader>
    </UserContext.Provider>
  );
};
export default Home;
