import {
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { login, logout } from "../../reducers/userSlice";
import { refresh } from "../../refresh";
import "./styles.scss";
import jwt_decode from "jwt-decode";

const { REACT_APP_API_ENDPOINT } = process.env;

const CreateMessage = () => {
  const { dark, setValue } = useContext(UserContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const auth = useSelector((state) => state.userSlice.user);
  const [usersId, setUsersId] = useState([]);
  const [selectAll, setselectAll] = useState(false);
  useEffect(() => {
    // Accessing scss variable "--background-color"
    // and "--text-color" using plain JavaScript
    // and changing the same according to the state of "darkTheme"
    const root = document.documentElement;
    root?.style.setProperty(
      "--background-color",
      dark ? "rgba(255, 255, 255, 0.08)" : "rgba(0, 0, 0, 0.08)"
    );
    root?.style.setProperty("--text-color", dark ? "#fff" : "#000");
  }, [dark]);
  let valid = message !== "" && title !== "" && usersId.length > 0;
  const onSubmit = () => {
    if (valid) {
      setLoading(true);

      axios
        .post(
          `${REACT_APP_API_ENDPOINT}/api/sendMessage`,
          {
            title: title,
            message: message,
            users: usersId,
          },
          {
            headers: {
              Authorization: "Bearer " + auth.token,
            },
          }
        )
        .then((data) => {
          console.log(data);
          navigate("/messages");
        })
        .catch((err) => {
        })

        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setValue(true);
    if (!auth) return;
    const roles = jwt_decode(auth.token).roles;
    let url = "";
    url = `${REACT_APP_API_ENDPOINT}/api/AllUsers`;
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + auth.token,
        },
      })
      .then((data) => {
        const users = data.data["hydra:member"];
        let usersWithRoleUser = [];
        users.forEach((user) => {
          if (user.roles.length === 1 && user.roles[0] === "ROLE_USER") {
            usersWithRoleUser.push(user);
          }
        });
        setUsers(usersWithRoleUser);
      })
      .catch((e) => {
        refresh(dispatch, navigate);
      })
      .finally(() => {
        setValue(false);
      });
  }, [dispatch, navigate, setValue, auth]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setUsersId(typeof value === "string" ? value.split(",") : value);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        height: "auto",
        width: 350,
      },
    },
  };

  const SelectAllHandler = () => {
    setselectAll((prev) => !prev);
    if (selectAll) setUsersId([]);
    else setUsersId(users.map((u) => u.id));
  };

  return (
    <div>
      <h1 style={{ color: dark ? "white" : "", marginBottom: "50px" }}>
        send message
      </h1>
      <InputLabel
        id="demo-multiple-checkbox-label"
        style={{ color: dark ? "white" : "", marginBottom: "20px" }}
      >
        Title
      </InputLabel>
      <TextField
        value={title}
        style={{ marginBottom: "20px" }}
        onChange={(event) => {
          setTitle(event.target.value);
        }}
        aria-describedby="my-helper-text"
        variant="outlined"
        sx={{
          input: { color: dark ? "white" : "" },
          "& .MuiOutlinedInput-root": {
            "& > fieldset": { borderColor: dark ? "white" : "" },
          },
        }}
        InputLabelProps={{
          style: { color: dark ? "#fff" : "" },
        }}
      />
      <InputLabel
        id="demo-multiple-checkbox-label"
        style={{ color: dark ? "white" : "", marginBottom: "20px" }}
      >
        Message
      </InputLabel>
      <TextareaAutosize
        minRows={10}
        aria-label="minimum height"
        style={{ width: "100%", marginBottom: "20px" }}
        value={message}
        onChange={(event) => {
          setMessage(event.target.value);
        }}
        aria-describedby="my-helper-text"
        label="Content"
        variant="outlined"
        sx={{
          input: { color: dark ? "white" : "" },
          "& .MuiOutlinedInput-root": {
            "& > fieldset": { borderColor: dark ? "white" : "" },
          },
        }}
        InputLabelProps={{
          style: { color: dark ? "#fff" : "" },
        }}
      />
      <InputLabel
        style={{ color: dark ? "white" : "" }}
        id="demo-multiple-checkbox-label"
      >
        Users
      </InputLabel>
      {users.length > 0 && (
        <Select
          style={{ marginBottom: "40px", minWidth: "200px", maxWidth: "200px" }}
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={usersId}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
          sx={{
            color: dark ? "white" : "",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: dark ? "white" : "",
            },
            ".MuiSvgIcon-root ": {
              fill: "white !important",
            },
          }}
        >
          {users.map((data) => (
            <MenuItem key={data.id} value={data.id}>
              <Checkbox checked={usersId.indexOf(data.id) > -1} />
              <img src={data.imgUrl} alt="" /> &nbsp; &nbsp;
              <ListItemText primary={data.email} />
            </MenuItem>
          ))}
        </Select>
      )}

      <>
        <Checkbox
          checked={selectAll}
          onChange={SelectAllHandler}
          sx={{
            color: dark ? "white" : "",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: dark ? "white" : "",
            },
          }}
        />
        <span style={{ color: dark ? "white" : "" }}>SelectAll</span>
      </>

      <br />
      <Button
        disabled={!valid || loading}
        onClick={onSubmit}
        style={{
          width: "20%",
          borderRadius: 10,
          padding: "5px 20px",
          fontSize: "18px",
          backgroundColor: "#00A79D",
          alignSelf: "center",
        }}
        variant="contained"
      >
        Submit
      </Button>
    </div>
  );
};

export default CreateMessage;
