import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Button,
} from "@mui/material";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { UserContext } from "../../context/UserContext";
import { login, logout } from "../../reducers/userSlice";
import { refresh } from "../../refresh";
import { toast } from "react-toastify";
import { useRef } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import "./style.css";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const { REACT_APP_API_ENDPOINT } = process.env;

export const CreateAdmin = () => {
  const navigate = useNavigate();

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [plainPassword, setPlainPassword] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { dark, setValue } = useContext(UserContext);
  const auth = useSelector((state) => state.userSlice.user);
  const [customerList, setCustomerList] = useState([]);
  const [customerId, setCustomerId] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--dark",
      dark ? "transparent" : ""
    );
  }, [dark]);

  useEffect(() => {
    if (auth == null) {
      navigate("/login");
      return;
    }

    setValue(true);
    axios
      .get(`${REACT_APP_API_ENDPOINT}/api/AllCustomers`, {
        headers: {
          Authorization: "Bearer " + auth.token,
        },
      })
      .then((data) => {
        console.log(data.data);
        setCustomerList(data.data["hydra:member"]);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          refresh(dispatch, navigate);
        }
      })
      .finally(() => {
        setValue(false);
      });
  }, [auth, dispatch, navigate, setValue]);

  // imageList, addUpdateIndex,
  const onChange = (e) => {
    setFile(e.target.files[0]);
  };

  let valid =
    firstname !== "" &&
    lastname !== "" &&
    email !== "" &&
    phone !== "" &&
    typeof phone === "string" &&
    isValidPhoneNumber(phone) &&
    customerId !== "";

  const onSubmit = () => {
    if (
      firstname !== "" &&
      lastname !== "" &&
      email !== "" &&
      phone !== "" &&
      typeof phone === "string" &&
      isValidPhoneNumber(phone) &&
      customerId !== ""
    ) {
      setLoading(true);
      var formData = new FormData();

      formData.append("firstname", firstname);

      formData.append("lastname", lastname);

      formData.append("email", email);

      formData.append("phone", phone);

      formData.append("plainPassword", plainPassword);

      formData.append("customer", "/api/customers/" + customerId);

      formData.append("file", file);

      axios
        .post(`${REACT_APP_API_ENDPOINT}/api/addAdmin`, formData, {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((data) => {
          console.log(data);
          navigate("/users");
        })
        .catch((err) => {
          console.log(err);
          if (err.response?.data["violations"] !== undefined) {
            toast.error(err.response?.data["violations"][0].message);
            return;
          }
          if (err.response.status === 401) {
            const refresh_token = localStorage.getItem("refresh_token");
            if (refresh_token) {
              axios
                .post(`${REACT_APP_API_ENDPOINT}/token/refresh`, {
                  refresh_token: refresh_token,
                })
                .then((data) => {
                  console.log("refresh");

                  axios
                    .post(`${REACT_APP_API_ENDPOINT}/api/addAdmin`, formData, {
                      headers: {
                        Authorization: "Bearer " + data.data.token,
                        "Content-Type": "multipart/form-data",
                      },
                    })
                    .then(() => {
                      dispatch(
                        login({
                          loggedIn: true,
                          token: data.data.token,
                        })
                      );
                      navigate("/users");
                    })
                    .catch((err) => {
                      console.log(err);
                      if (err.response?.data["violations"] !== undefined) {
                        toast.error(
                          err.response?.data["violations"][0].message
                        );
                        return;
                      }
                    });
                })
                .catch((e) => {
                  console.log(e);
                  dispatch(logout());
                  navigate("/login");
                });
            } else {
              console.log("no refresh token");
              dispatch(logout());
              navigate("/login");
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    // Accessing scss variable "--background-color"
    // and "--text-color" using plain JavaScript
    // and changing the same according to the state of "darkTheme"
    const root = document.documentElement;
    root?.style.setProperty("--text-color", dark ? "#fff" : "#000");
  }, [dark]);

  const fileInput = useRef(null);

  const handleChangeCustomer = (event) => {
    setCustomerId(event.target.value);
  };

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  // console.log(isValidPhoneNumber(phone));

  return (
    <>
      <h2 style={{ color: dark ? "white" : "" }}>Create Customer Admin</h2>
      <div style={{ width: "100%", marginBottom: "5%", marginTop: "3%" }}>
        <div style={{ width: "80%", margin: "auto" }}>
          <FormGroup>
            <TextField
              style={{ marginBottom: "20px" }}
              value={firstname}
              onChange={(event) => {
                setFirstname(event.target.value);
              }}
              aria-describedby="my-helper-text"
              label="Firstname"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
            />
            <TextField
              style={{ marginBottom: "20px" }}
              value={lastname}
              onChange={(event) => {
                setLastname(event.target.value);
              }}
              aria-describedby="my-helper-text"
              label="Lastname"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
            />
            <TextField
              style={{ marginBottom: "20px" }}
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              aria-describedby="my-helper-text"
              label="Email"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
            />
            <div style={{ position: "relative" }}>
              {passwordType === "password" ? (
                <AiFillEyeInvisible
                  style={{
                    fontSize: "30px",
                    position: "absolute",
                    marginLeft: "93%",
                    marginTop: 10,
                    cursor: "pointer",
                    zIndex: 10,
                    color: dark ? "white" : "",
                  }}
                  onClick={togglePassword}
                />
              ) : (
                <AiFillEye
                  style={{
                    fontSize: "30px",
                    position: "absolute",
                    marginLeft: "93%",
                    marginTop: 10,
                    cursor: "pointer",
                    zIndex: 10,
                    color: dark ? "white" : "",
                  }}
                  onClick={togglePassword}
                />
              )}
            </div>
            <TextField
              type={passwordType}
              style={{ marginBottom: "20px" }}
              value={plainPassword}
              onChange={(event) => {
                setPlainPassword(event.target.value);
              }}
              aria-describedby="my-helper-text"
              label="Plain Password"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
            />
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* 
              <TextField
                style={{ marginBottom: "20px", width: "100%" }}
                value={phone}
                onChange={(event) => {
                  setPhone(event.target.value);
                }}
                aria-describedby="my-helper-text"
                label="Phone"
                variant="outlined"
                sx={{
                  input: { color: dark ? "white" : "" },
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": { borderColor: dark ? "white" : "" },
                  },
                }}
                InputLabelProps={{
                  style: { color: dark ? "#fff" : "" },
                }}
              /> */}
              <PhoneInput
                international
                defaultCountry="US"
                value={phone}
                onChange={setPhone}
                style={{ width: "100%" }}
              />
            </div>
            <InputLabel
              style={{ color: dark ? "white" : "" }}
              id="demo-checkbox-label"
            >
              Customer
            </InputLabel>
            <Select
              style={{ marginBottom: "20px" }}
              labelId="demo-checkbox-label"
              id="demo-checkbox"
              value={customerId}
              onChange={handleChangeCustomer}
              input={<OutlinedInput label="Tag" />}
              MenuProps={MenuProps}
              labelstyle={{ color: "#ff0000" }}
              sx={{
                color: dark ? "white" : "",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: dark ? "white" : "",
                },
                ".MuiSvgIcon-root ": {
                  fill: "white !important",
                },
              }}
            >
              {customerList.map((data) => (
                <MenuItem key={data.id} value={data.id}>
                  <ListItemText primary={data.name} />
                </MenuItem>
              ))}
            </Select>
            <div>
              <button
                onClick={() => {
                  fileInput.current.click();
                }}
                htmlFor="files"
                className="btn"
                style={{
                  color: "white",
                  backgroundColor: "#00A79D",
                  marginBottom: "20px",
                }}
              >
                Select Image
              </button>
              <input
                ref={fileInput}
                type="file"
                onChange={onChange}
                style={{ visibility: "hidden" }}
              />
            </div>
            <Button
              disabled={!valid || loading}
              onClick={onSubmit}
              style={{
                width: "20%",
                borderRadius: 10,
                padding: "5px 20px",
                fontSize: "18px",
                backgroundColor: "#00A79D",
                alignSelf: "center",
              }}
              variant="contained"
            >
              Submit
            </Button>
          </FormGroup>
        </div>
      </div>
    </>
  );
};
