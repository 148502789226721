import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { UserContext } from "../../context/UserContext";
import { login, logout } from "../../reducers/userSlice";
import secureLocalStorage from "react-secure-storage";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const { REACT_APP_API_ENDPOINT } = process.env;

export const CreateUser = () => {
  const navigate = useNavigate();

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isoCountry, setIsoCountry] = useState("");
  const [plainPassword, setPlainPassword] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const { dark } = useContext(UserContext);
  const auth = useSelector((state) => state.userSlice.user);
  const dispatch = useDispatch();
  useEffect(() => {
    axios.get(`${REACT_APP_API_ENDPOINT}/api/countries?page=1`).then((res) => {
      setCountries(res.data["hydra:member"]);
    });
  }, []);

  // imageList, addUpdateIndex,
  const onChange = (e) => {
    setFile(e.target.files[0]);
  };

  let valid =
    firstname !== "" &&
    lastname !== "" &&
    email !== "" &&
    phone !== "" &&
    isoCountry !== "";

  const onSubmit = () => {
    if (
      firstname !== "" &&
      lastname !== "" &&
      email !== "" &&
      phone !== "" &&
      isoCountry !== ""
    ) {
      setLoading(true);
      var formData = new FormData();

      formData.append("firstname", firstname);

      formData.append("lastname", lastname);

      formData.append("email", email);

      formData.append("phone", phone);

      formData.append("plainPassword", plainPassword);

      formData.append("isoCountry", isoCountry);

      formData.append("file", file);

      axios
        .post(`${REACT_APP_API_ENDPOINT}/api/users`, formData, {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((data) => {
          console.log(data);
          navigate("/users");
        })
        .catch((err) => {
          const refresh_token = localStorage.getItem("refresh_token");
          if (refresh_token) {
            axios
              .post(`${REACT_APP_API_ENDPOINT}/token/refresh`, {
                refresh_token: refresh_token,
              })
              .then((data) => {
                console.log("refresh");

                axios
                  .post(`${REACT_APP_API_ENDPOINT}/api/users`, formData, {
                    headers: {
                      Authorization: "Bearer " + data.data.token,
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then(() => {
                    dispatch(
                      login({
                        loggedIn: true,
                        token: data.data.token,
                      })
                    );
                    navigate("/users");
                  });
              })
              .catch((e) => {
                console.log(e);
                dispatch(logout());
                navigate("/login");
              });
          } else {
            console.log("no refresh token");
            dispatch(logout());
            navigate("/login");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    // Accessing scss variable "--background-color"
    // and "--text-color" using plain JavaScript
    // and changing the same according to the state of "darkTheme"
    const root = document.documentElement;
    root?.style.setProperty("--text-color", dark ? "#fff" : "#000");
  }, [dark]);

  const handleChange = (event) => {
    setIsoCountry(event.target.value);
  };

  return (
    <>
      <h2 style={{ color: dark ? "white" : "" }}>Create User</h2>
      <div style={{ width: "100%", marginBottom: "5%", marginTop: "3%" }}>
        <div style={{ width: "80%", margin: "auto" }}>
          <FormGroup>
            <TextField
              value={firstname}
              onChange={(event) => {
                setFirstname(event.target.value);
              }}
              aria-describedby="my-helper-text"
              label="Firstname"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
            />
            <TextField
              value={lastname}
              onChange={(event) => {
                setLastname(event.target.value);
              }}
              aria-describedby="my-helper-text"
              label="Lastname"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
            />
            <TextField
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              aria-describedby="my-helper-text"
              label="Email"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
            />
            <TextField
              value={plainPassword}
              onChange={(event) => {
                setPlainPassword(event.target.value);
              }}
              aria-describedby="my-helper-text"
              label="Plain Password"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
            />
            <TextField
              value={phone}
              onChange={(event) => {
                setPhone(event.target.value);
              }}
              aria-describedby="my-helper-text"
              label="Phone"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
            />
            <InputLabel
              style={{ color: dark ? "white" : "" }}
              id="demo-checkbox-label"
            >
              Country
            </InputLabel>
            <Select
              labelId="demo-checkbox-label"
              id="demo-checkbox"
              value={isoCountry}
              renderValue={(selected) => selected}
              onChange={handleChange}
              input={<OutlinedInput label="Tag" />}
              MenuProps={MenuProps}
              labelStyle={{ color: "#ff0000" }}
              sx={{
                color: dark ? "white" : "",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: dark ? "white" : "",
                },
                ".MuiSvgIcon-root ": {
                  fill: "white !important",
                },
              }}
            >
              {countries.map((data) => (
                <MenuItem key={data.id} value={data.iso_code_2}>
                  <img src={data.flagUrl} alt="" /> &nbsp; &nbsp;
                  <ListItemText primary={data.name} />
                </MenuItem>
              ))}
            </Select>
            <div>
              <label
                for="files"
                class="btn"
                style={{ color: "white", backgroundColor: "#00A79D" }}
              >
                Select Image
              </label>
              <input
                type="file"
                onChange={onChange}
                style={{ visibility: "hidden" }}
              />
            </div>
            <Button
              disabled={!valid || loading}
              onClick={onSubmit}
              style={{
                width: "20%",
                borderRadius: 10,
                padding: "5px 20px",
                fontSize: "18px",
                backgroundColor: "#00A79D",
                alignSelf: "center",
              }}
              variant="contained"
            >
              Submit
            </Button>
          </FormGroup>
        </div>
      </div>
    </>
  );
};
