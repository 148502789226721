import { FormGroup } from "@mui/material";
import axios from "axios";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { refresh } from "../../refresh";
import { Div, Label } from "../news/divNewsDetail";
const ShowDevice = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const auth = useSelector((state) => state.userSlice.user);
  const { setValue, dark } = useContext(UserContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [device, setDevice] = useState();

  const id = useParams()["id"];
  useEffect(() => {
    setValue(true);
    axios
      .get(`${REACT_APP_API_ENDPOINT}/api/devices/` + id, {
        headers: { Authorization: "Bearer " + auth.token },
      })
      .then((data) => setDevice(data.data))
      .catch((err) => {
        if (err.response.status === 401) {
          refresh(dispatch, navigate);
        }
      })
      .finally(() => {
        setValue(false);
      });
  }, [REACT_APP_API_ENDPOINT, auth, id, dispatch, navigate, setValue]);
  console.log(device);
  return (
    <>
      <h2 style={{ color: dark ? "white" : "" }}>Show Device</h2>
      <div style={{ width: "100%", marginBottom: "5%", marginTop: "5%" }}>
        <div style={{ width: "80%", margin: "auto" }}>
          {device && (
            <FormGroup>
              <Label dark={dark}>Name</Label>
              <br />
              <Div dark={dark}>{device.device_id}</Div>
              <br />
              <Label dark={dark}>Operating System</Label>
              <br />
              <Div dark={dark}>{device.type}</Div>
              <br />

              <Label dark={dark}>Full Name</Label>
              <br />
              <Div dark={dark}>
                {device.user.firstname + " " + device.user.lastname}
              </Div>

              <Label dark={dark}>User</Label>
              <br />
              <Div dark={dark}>{device.user.email}</Div>
              <br />

              <Label dark={dark}>Battery</Label>
              <br />
              <Div dark={dark}>{device.battery ?? 0}%</Div>
              <br />

              <Label dark={dark}>Satellites Fixed</Label>
              <br />
              <Div dark={dark}>{device.satellitesFixed ?? 0}</Div>
              <br />

              <Label dark={dark}>Brand</Label>
              <br />
              <Div dark={dark}>{device.deviceInfo?.brand ?? ''}</Div>
              <br />

              <Label dark={dark}>Model</Label>
              <br />
              <Div dark={dark}>{device.deviceInfo?.model ?? ''}</Div>
              <br />

              <Label dark={dark}>SDK</Label>
              <br />
              <Div dark={dark}>{device.deviceInfo?.sdk ?? ''}</Div>
              <br />

              <Label dark={dark}>Manufacturer Id</Label>
              <br />
              <Div dark={dark}>{device.manufacturer_id ?? ''}</Div>
              <br />
            </FormGroup>
          )}
        </div>
      </div>
    </>
  );
};

export default ShowDevice;
