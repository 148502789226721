import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { FormGroup, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Div, Label } from "../news/divNewsDetail";
import { makeStyles } from "@mui/styles";
import { UserContext } from "../../context/UserContext";
import { login, logout } from "../../reducers/userSlice";
import { refresh } from "../../refresh";
import secureLocalStorage from "react-secure-storage";

const { REACT_APP_API_ENDPOINT } = process.env;

const useHelperTextStyles = makeStyles(() => ({
  root: {
    color: "#9694ad",
  },
}));

export const EditContact = () => {
  const { setValue, dark } = useContext(UserContext);
  const helperTextStyles = useHelperTextStyles();
  const params = useParams();
  const id = params["id"];
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const auth = useSelector((state) => state.userSlice.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth == null) {
      navigate("/login");
      return;
    }
  }, [auth, navigate]);

  useEffect(() => {
    setValue(true);
    if (auth == null) {
      navigate("/login");
      return;
    }
    axios
      .get(`${REACT_APP_API_ENDPOINT}/api/contacts/` + id, {
        headers: {
          Authorization: "Bearer " + auth.token,
        },
      })
      .then((data) => {
        console.log(data.data);
        setFirstname(data.data.firstname);
        setLastname(data.data.lastname);
        setEmail(data.data.email);
        setPhone(data.data.phone);
      })
      .catch((err) => {
        refresh(dispatch, navigate);
      })
      .finally(() => {
        setValue(false);
      });
  }, [id, navigate, auth, setValue, dispatch]);

  let valid = email !== "" && phone !== "";

  const onSubmit = () => {
    if (email !== "" && phone !== "") {
      setLoading(true);

      axios
        .put(
          `${REACT_APP_API_ENDPOINT}/api/contacts/` + id,
          {
            email: email,
            phone: phone,
          },
          {
            headers: {
              Authorization: "Bearer " + auth.token,
            },
          }
        )
        .then((data) => {
          console.log(data);
          navigate("/users");
        })
        .catch((err) => {
          const refresh_token = localStorage.getItem("refresh_token");
          if (refresh_token) {
            axios
              .post(`${REACT_APP_API_ENDPOINT}/token/refresh`, {
                refresh_token: refresh_token,
              })
              .then((data) => {
                console.log("refresh");
                axios
                  .put(
                    `${REACT_APP_API_ENDPOINT}/api/contacts/` + id,
                    {
                      email: email,
                      phone: phone,
                    },
                    {
                      headers: {
                        Authorization: "Bearer " + data.data.token,
                      },
                    }
                  )
                  .then(() => {
                    dispatch(
                      login({
                        loggedIn: true,
                        token: data.data.token,
                      })
                    );
                    navigate("/users");
                  });
              })
              .catch((e) => {
                console.log(e);
                dispatch(logout());
                navigate("/login");
              });
          } else {
            console.log("no refresh token");
            dispatch(logout());
            navigate("/login");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <h2 style={{ color: dark ? "white" : "" }}>Edit Contact</h2>
      <div style={{ width: "100%", marginBottom: "5%" }}>
        <div style={{ width: "80%", margin: "auto" }}>
          {firstname && (
            <FormGroup>
              <Label dark={dark}>Firstname</Label>
              <Div dark={dark}>{firstname}</Div>
              <Label dark={dark}>Lastname</Label>
              <Div dark={dark}>{lastname}</Div>
              <TextField
                style={{ marginBottom: "20px" }}
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                aria-describedby="my-helper-text"
                label="Email"
                variant="outlined"
                sx={{
                  input: { color: dark ? "white" : "" },
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": { borderColor: dark ? "white" : "" },
                  },
                }}
                InputLabelProps={{
                  style: { color: dark ? "#fff" : "" },
                }}
                FormHelperTextProps={{
                  classes: {
                    root: helperTextStyles.root,
                  },
                }}
              />
              <TextField
                style={{ marginBottom: "20px" }}
                value={phone}
                onChange={(event) => {
                  setPhone(event.target.value);
                }}
                aria-describedby="my-helper-text"
                label="Phone"
                variant="outlined"
                sx={{
                  input: { color: dark ? "white" : "" },
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": { borderColor: dark ? "white" : "" },
                  },
                }}
                InputLabelProps={{
                  style: { color: dark ? "#fff" : "" },
                }}
                FormHelperTextProps={{
                  classes: {
                    root: helperTextStyles.root,
                  },
                }}
              />
              <Button
                disabled={!valid || loading}
                onClick={onSubmit}
                variant="contained"
                style={{
                  width: "20%",
                  borderRadius: 10,
                  padding: "5px 20px",
                  fontSize: "18px",
                  backgroundColor: "#00A79D",
                  alignSelf: "center",
                }}
              >
                Submit
              </Button>
            </FormGroup>
          )}
        </div>
      </div>
    </>
  );
};
