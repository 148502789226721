import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import jwt_decode from "jwt-decode";
import LogoArian from "../../assets/logo/Logo.svg";
import LogoTextImg from "../../assets/logo/Ariane in white.svg";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Aos from "aos";
import { logout } from "../../reducers/userSlice";
import { useNavigate } from "react-router";
import { Avatar, Menu, MenuItem, Tooltip } from "@mui/material";
import Logout from "@mui/icons-material/Logout";
import { LogoImage, NavbarButtonsContainer } from "./NavbarElements";

import TabletAndroidIcon from "@mui/icons-material/TabletAndroid";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import MonitorIcon from "@mui/icons-material/Monitor";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { UserContext } from "../../context/UserContext";
import news from "../../assets/logo/Asset 1.png";
import back from "../../assets/logo/back.png";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import "./style.css";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import MessageIcon from "@mui/icons-material/Message";

const drawerWidth = 190;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100%)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  zIndex: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Sidebar = () => {
  const { dark, setDark } = useContext(UserContext);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const user = useSelector((state) => state.userSlice.user);
  const [allowed, setAllowed] = useState(false);
  const handleDrawerOpen = () => {
    setOpen((prev) => !prev);
  };

  const dispatch = useDispatch();
  useState(() => {
    Aos.init({ duration: 500 });
  }, []);

  const handleLogout = () => {
    dispatch(logout());
  };
  useEffect(() => {
    console.log(user);
    if (user == null) {
      console.log(user);
      navigate("/login");
      return;
    }
    const roles = jwt_decode(user.token).roles;
    console.log(roles);
    if (roles.includes("ROLE_ADMIN")) {
      setAllowed(true);
    }
  }, [navigate, user]);

  const [anchorEl, setAnchorEl] = useState("");

  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CssBaseline />

      <AppBar position="fixed" open={open}>
        <Toolbar>
          <ArrowBackIosIcon
            onClick={() => navigate(-1)}
            style={{ width: 50, margin: "auto", fontSize: 25 }}
          />
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...open,
            }}
          >
            <MenuIcon />
          </IconButton>

          <NavbarButtonsContainer>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div></div>

              <div variant="h6" style={{ marginLeft: 330, marginTop: 10 }}>
                <img style={{ width: 30 }} src={LogoArian} /><br />
                <img style={{ width: 40 }} src={LogoTextImg} />
              </div>
              <div>
                <IconButton
                  sx={{ ml: 1 }}
                  onClick={() => {
                    setDark((prev) => {
                      localStorage.setItem("dark", !prev);
                      return !prev;
                    });
                  }}
                  color="inherit"
                >
                  {dark ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={openMenu ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? "true" : undefined}
                  >
                    {user && (
                      <>
                        <Avatar
                          sx={{ width: 40, height: 40 }}
                          src={jwt_decode(user.token).avatar}
                        ></Avatar>
                        &nbsp;&nbsp;
                        <Typography style={{ color: "white" }}>
                          {jwt_decode(user.token).firstname +
                            " " +
                            jwt_decode(user.token).lastname}
                        </Typography>
                      </>
                    )}
                    {!user && (
                      <Avatar sx={{ width: 40, height: 40 }}> G </Avatar>
                    )}
                  </IconButton>
                </Tooltip>

                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={openMenu}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "hidden",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  {/* <MenuItem>
                <Avatar /> Profile
              </MenuItem>
              <MenuItem>
                <Avatar /> My account
              </MenuItem>
              <Divider />
              <MenuItem>
                <ListItemIcon>
                  <PersonAdd fontSize="small" />
                </ListItemIcon>
                Add another account
              </MenuItem>
              <MenuItem>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem> */}
                  <MenuItem onClick={() => handleLogout()}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            </Box>
          </NavbarButtonsContainer>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {
            background: dark
              ? "linear-gradient(90deg, #251142 0%, #281a4b 100%)"
              : "",
            color: dark ? "white" : "",
            borderRightColor: dark ? "#50456c" : "rgba(0, 0, 0, 0.08)",
            borderRightWidth: 2,
          },
        }}
      >
        <DrawerHeader></DrawerHeader>
        <List style={{ marginTop: 0 }}>
          <ListItem key={"device"} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => {
                setPage(1);
                navigate("/devices");
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2,
                backgroundColor:
                  page === 1
                    ? dark
                      ? "#314f8b"
                      : "rgba(0, 0, 0, 0.08)"
                    : "none",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2.5 : "auto",
                  justifyContent: "center",
                }}
              >
                <TabletAndroidIcon style={{ color: "#84d4ec" }} />
              </ListItemIcon>
              <ListItemText
                primary={"Devices"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <Divider
            style={{
              height: 2,
              backgroundColor: "white",
              marginInline: 15,
              margin: 5,
            }}
          />

          <ListItem key={"news"} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => {
                setPage(2);
                navigate("/news");
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2,
                backgroundColor:
                  page === 2
                    ? dark
                      ? "#314f8b"
                      : "rgba(0, 0, 0, 0.08)"
                    : "none",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2.5 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={news} style={{ width: 23 }} alt="" />
              </ListItemIcon>
              <ListItemText
                primary={"Risk Feed"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          <Divider
            style={{
              height: 2,
              backgroundColor: "white",
              marginInline: 15,
              margin: 5,
            }}
          />

          <ListItem key={"usres"} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => {
                setPage(3);
                navigate("/users");
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2,
                backgroundColor:
                  page === 3
                    ? dark
                      ? "#314f8b"
                      : "rgba(0, 0, 0, 0.08)"
                    : "none",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2.5 : "auto",
                  justifyContent: "center",
                }}
              >
                <PersonOutlineOutlinedIcon style={{ color: "#84d4ec" }} />
              </ListItemIcon>
              <ListItemText primary={"Users"} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>

          <Divider
            style={{
              height: 2,
              backgroundColor: "white",
              marginInline: 15,
              margin: 5,
            }}
          />

          <ListItem key={"sos"} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => {
                setPage(4);
                navigate("/alerts");
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2,
                backgroundColor:
                  page === 4
                    ? dark
                      ? "#314f8b"
                      : "rgba(0, 0, 0, 0.08)"
                    : "none",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2.5 : "auto",
                  justifyContent: "center",
                }}
              >
                <NotificationsIcon style={{ color: "#84d4ec" }} />
              </ListItemIcon>
              <ListItemText
                primary={"Sos LogBook"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          <Divider
            style={{
              height: 2,
              backgroundColor: "white",
              marginInline: 15,
              margin: 5,
            }}
          />

          <ListItem key={"settings"} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => {
                setPage(5);
                navigate("/settings");
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2,
                backgroundColor:
                  page === 5
                    ? dark
                      ? "#314f8b"
                      : "rgba(0, 0, 0, 0.08)"
                    : "none",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2.5 : "auto",
                  justifyContent: "center",
                }}
              >
                <SettingsIcon style={{ color: "#84d4ec" }} />
              </ListItemIcon>
              <ListItemText
                primary={"Settings"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          <Divider
            style={{
              height: 2,
              backgroundColor: "white",
              marginInline: 15,
              margin: 5,
            }}
          />

          <ListItem key={"monitor"} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => {
                setPage(6);
                navigate("/monitor");
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2,
                backgroundColor:
                  page === 6
                    ? dark
                      ? "#314f8b"
                      : "rgba(0, 0, 0, 0.08)"
                    : "none",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2.5 : "auto",
                  justifyContent: "center",
                }}
              >
                <MonitorIcon style={{ color: "#84d4ec" }} />
              </ListItemIcon>
              <ListItemText
                primary={"Monitoring"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          <Divider
            style={{
              height: 2,
              backgroundColor: "white",
              marginInline: 15,
              margin: 5,
            }}
          />

          <ListItem key={"geofence"} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => {
                setPage(7);
                navigate("/geofence");
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2,
                backgroundColor:
                  page === 7
                    ? dark
                      ? "#314f8b"
                      : "rgba(0, 0, 0, 0.08)"
                    : "none",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2.5 : "auto",
                  justifyContent: "center",
                }}
              >
                <MapOutlinedIcon style={{ color: "#84d4ec" }} />
              </ListItemIcon>
              <ListItemText
                primary={"Geofence"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          <Divider
            style={{
              height: 2,
              backgroundColor: "white",
              marginInline: 15,
              margin: 5,
            }}
          />
          {allowed && (
            <ListItem
              key={"customers"}
              disablePadding
              sx={{ display: "block" }}
            >
              <ListItemButton
                onClick={() => {
                  setPage(8);
                  navigate("/customers");
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2,
                  backgroundColor:
                    page === 8
                      ? dark
                        ? "#314f8b"
                        : "rgba(0, 0, 0, 0.08)"
                      : "none",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2.5 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <SupervisorAccountIcon style={{ color: "#84d4ec" }} />
                </ListItemIcon>
                <ListItemText
                  primary={"Customers"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
              <Divider
                style={{
                  height: 2,
                  backgroundColor: "white",
                  marginInline: 15,
                  margin: 5,
                }}
              />
            </ListItem>
          )}

          <ListItem key={"messages"} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => {
                setPage(9);
                navigate("/messages");
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2,
                backgroundColor:
                  page === 9
                    ? dark
                      ? "#314f8b"
                      : "rgba(0, 0, 0, 0.08)"
                    : "none",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2.5 : "auto",
                  justifyContent: "center",
                }}
              >
                <MessageIcon style={{ color: "#84d4ec" }} />
              </ListItemIcon>
              <ListItemText
                primary={"Messages"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default Sidebar;
