import styled from "styled-components";

export const Div = styled.div`
  padding: 10px;
  border: ${(props) => (props.dark ? "2px solid white" : "2px solid black")};
  border-radius: 10px;
  color: ${(props) => (props.dark ? "white" : "")};
  margin-bottom: 20px;
`;

export const DivDescription = styled.div`
  padding: 10px;
  border: ${(props) => (props.dark ? "2px solid white" : "2px solid black")};
  height: 400px;
  overflow: scroll;
  text-align: justify; /* For Edge */
  -moz-text-align-last: justify; /* For Firefox prior 58.0 */
  text-align-last: justify;
  color: ${(props) => (props.dark ? "white" : "")};
  margin-bottom: 20px;
`;

export const Label = styled.h3`
  color: ${(props) => (props.dark ? "white" : "")};
  margin-bottom: 20px;
`;
