import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Button,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { UserContext } from "../../context/UserContext";
import { login, logout } from "../../reducers/userSlice";
import { refresh } from "../../refresh";
import secureLocalStorage from "react-secure-storage";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const { REACT_APP_API_ENDPOINT } = process.env;

export const EditUser = () => {
  const params = useParams();
  const id = params["id"];
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [isoCountry, setIsoCountry] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const dispatch = useDispatch();
  const { setValue, dark } = useContext(UserContext);

  const auth = useSelector((state) => state.userSlice.user);

  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`${REACT_APP_API_ENDPOINT}/api/countries?page=1`).then((res) => {
      setCountries(res.data["hydra:member"]);
    });
  }, []);

  const onChange = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    if (!auth) return;
    setValue(true);
    axios
      .get(`${REACT_APP_API_ENDPOINT}/api/users/` + id, {
        headers: {
          Authorization: "Bearer " + auth.token,
        },
      })
      .then((data) => {
        console.log(data.data);
        setFirstname(data.data.firstname);
        setLastname(data.data.lastname);
        setEmail(data.data.email);
        setPhone(data.data.phone);
        setFileUrl(data.data.fileUrl);
        setIsoCountry(data.data.isoCountry);
      })
      .catch((err) => {
        refresh(dispatch, navigate);
      })
      .finally(() => {
        setValue(false);
      });
  }, [id, navigate, auth, setValue, dispatch]);

  let valid =
    firstname !== "" &&
    lastname !== "" &&
    email !== "" &&
    phone !== "" &&
    isoCountry !== "";

  const onSubmit = () => {
    if (
      firstname !== "" &&
      lastname !== "" &&
      email !== "" &&
      phone !== "" &&
      isoCountry !== ""
    ) {
      setLoading(true);
      var formData = new FormData();

      formData.append("firstname", firstname);

      formData.append("lastname", lastname);

      formData.append("email", email);

      formData.append("phone", phone);

      formData.append("isoCountry", isoCountry);

      if (file) formData.append("file", file);

      axios
        .post(`${REACT_APP_API_ENDPOINT}/api/updateUser/` + id, formData, {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((data) => {
          console.log(data);
          navigate("/users");
        })
        .catch((err) => {
          const refresh_token = localStorage.getItem("refresh_token");
          if (refresh_token) {
            axios
              .post(`${REACT_APP_API_ENDPOINT}/token/refresh`, {
                refresh_token: refresh_token,
              })
              .then((data) => {
                console.log("refresh");
                axios
                  .post(
                    `${REACT_APP_API_ENDPOINT}/api/updateUser/` + id,
                    formData,
                    {
                      headers: {
                        Authorization: "Bearer " + data.data.token,
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  )
                  .then(() => {
                    dispatch(
                      login({
                        loggedIn: true,
                        token: data.data.token,
                      })
                    );
                    navigate("/users");
                  });
              })
              .catch((e) => {
                console.log(e);
                dispatch(logout());
                navigate("/login");
              });
          } else {
            console.log("no refresh token");
            dispatch(logout());
            navigate("/login");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    // Accessing scss variable "--background-color"
    // and "--text-color" using plain JavaScript
    // and changing the same according to the state of "darkTheme"
    const root = document.documentElement;
    root?.style.setProperty("--text-color", dark ? "#fff" : "#000");
  }, [dark]);

  const handleChange = (event) => {
    setIsoCountry(event.target.value);
  };

  return (
    <>
      <h2 style={{ color: dark ? "white" : "" }}>Edit User</h2>
      <div style={{ width: "100%", marginBottom: "5%" }}>
        <div style={{ width: "80%", margin: "auto" }}>
          <div style={{ width: "100%", textAlign: "center" }}>
            {fileUrl !== "" && (
              <Box
                style={{ maxWidth: "500px" }}
                component="img"
                alt=""
                src={fileUrl}
              />
            )}
          </div>
          <br />
          <br />
          <FormGroup>
            <TextField
              value={firstname}
              onChange={(event) => {
                setFirstname(event.target.value);
              }}
              aria-describedby="my-helper-text"
              label="Firstname"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
            />
            <br />
            <TextField
              value={lastname}
              onChange={(event) => {
                setLastname(event.target.value);
              }}
              aria-describedby="my-helper-text"
              label="Lastname"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
            />
            <br />
            <TextField
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              aria-describedby="my-helper-text"
              label="Email"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
            />
            <br />
            <TextField
              value={phone}
              onChange={(event) => {
                setPhone(event.target.value);
              }}
              aria-describedby="my-helper-text"
              label="Phone"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
            />
            <br />
            <InputLabel
              id="demo-checkbox-label"
              style={{ color: dark ? "white" : "" }}
            >
              Country
            </InputLabel>
            <br />
            {countries.length > 0 && (
              <Select
                labelId="demo-checkbox-label"
                id="demo-checkbox"
                value={isoCountry}
                renderValue={(selected) => selected}
                onChange={handleChange}
                MenuProps={MenuProps}
                sx={{
                  color: dark ? "white" : "",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: dark ? "white" : "",
                  },
                  ".MuiSvgIcon-root ": {
                    fill: "white !important",
                  },
                }}
              >
                {countries.map((data) => (
                  <MenuItem key={data.id} value={data.iso_code_2}>
                    <img src={data.flagUrl} alt="" /> &nbsp; &nbsp;
                    <ListItemText primary={data.name} />
                  </MenuItem>
                ))}
              </Select>
            )}

            <br />
            <br />
            <div>
              <label
                for="files"
                class="btn"
                style={{ color: "white", backgroundColor: "#00A79D" }}
              >
                Select Image
              </label>
              <input
                type="file"
                onChange={onChange}
                style={{ visibility: "hidden" }}
              />
            </div>
            <br />
            <br />
            <Button
              disabled={!valid || loading}
              onClick={onSubmit}
              style={{
                width: "20%",
                borderRadius: 10,
                padding: "5px 20px",
                fontSize: "18px",
                backgroundColor: "#00A79D",
                alignSelf: "center",
              }}
              variant="contained"
            >
              Submit
            </Button>
          </FormGroup>
        </div>
      </div>
    </>
  );
};
