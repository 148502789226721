import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { login, logout, setItemPerPage } from "../../reducers/userSlice.js";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { tableCellClasses } from "@mui/material/TableCell";
import Pagination from "../../components/Pagination";

import "../../index.css";

import Moment from "moment";
import {
  Button,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext.js";
import { refresh } from "../../refresh.js";
import DialogDeleteConfirmation from "../../components/DialogDeleteConfirmation/index.js";
import secureLocalStorage from "react-secure-storage";
import jwt_decode from "jwt-decode";
var moment = require('moment-timezone');
const ListAlerts = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const user = useSelector((state) => state.userSlice.user);
  const itemPerPage = useSelector((state) => state.userSlice.itemPerPage);
  const navigate = useNavigate();
  const [alertsList, setAlertsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [totalCount, setTotalCount] = useState(0);
  const [pageOrdering, setPageOrdering] = useState(0);
  const [id, setId] = useState(null);
  const { setValue, dark } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [openOne, setOpenOne] = useState(false);
  const [userId, setUserId] = useState("");
  const [users, setUsers] = useState([]);

  const [customerId, setCustomerId] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [allowed, setAllowed] = useState(false);

  const tableContainerSx = {
    border: "0px",
    marginTop: 4,
    borderRadius: 2,
    backgroundColor: "rgba(0, 0, 0, 0)",
    padding: "10%",
    paddingBottom: 0,
    paddingTop: 0,
    maxWidth: '80vw'
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseOne = () => {
    setOpenOne(false);
  };

  const deleteAllHandler = () => {
    setValue(true);
    let url = `${REACT_APP_API_ENDPOINT}/api/locations/deleteAll?userId=${userId}`;
    axios
      .delete(url, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then(() => {
        setAlertsList([]);
        setTotalCount(0);
        setCurrentPage(1);
        // setPageOrdering((prev) => prev + 1);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          const refresh_token = localStorage.getItem("refresh_token");
          if (refresh_token) {
            axios
              .post(`${REACT_APP_API_ENDPOINT}/token/refresh`, {
                refresh_token: refresh_token,
              })
              .then((data) => {
                console.log("refresh");
                axios
                  .delete(url, {
                    headers: {
                      Authorization: "Bearer " + data.data.token,
                    },
                  })
                  .then(() => {
                    setAlertsList([]);
                    setTotalCount(0);
                    setCurrentPage(1);
                    // setPageOrdering((prev) => prev + 1);
                    dispatch(
                      login({
                        loggedIn: true,
                        token: data.data.token,
                      })
                    );
                  });
              })
              .catch((e) => {
                console.log(e);
                dispatch(logout());
                navigate("/login");
              });
          } else {
            console.log("no refresh token");
            dispatch(logout());
            navigate("/login");
          }
        }
      })
      .finally(() => {
        setOpen(false);
        setValue(false);
      });
  };

  const deleteHandler = (id) => {
    setValue(true);
    axios
      .delete(`${REACT_APP_API_ENDPOINT}/api/locations/` + id, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        const userList = alertsList.filter((item) => item.id !== id);
        setAlertsList(userList);
        setTotalCount((prev) => prev - 1);
        setCurrentPage(1);
        setPageOrdering((prev) => prev + 1);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          const refresh_token = localStorage.getItem("refresh_token");
          if (refresh_token) {
            axios
              .post(`${REACT_APP_API_ENDPOINT}/token/refresh`, {
                refresh_token: refresh_token,
              })
              .then((data) => {
                console.log("refresh");
                axios
                  .delete(`${REACT_APP_API_ENDPOINT}/api/locations/` + id, {
                    headers: {
                      Authorization: "Bearer " + data.data.token,
                    },
                  })
                  .then((res) => {
                    const userList = alertsList.filter(
                      (item) => item.id !== id
                    );
                    setAlertsList(userList);
                    setTotalCount((prev) => prev - 1);
                    setCurrentPage(1);
                    setPageOrdering((prev) => prev + 1);
                    dispatch(
                      login({
                        loggedIn: true,
                        token: data.data.token,
                      })
                    );
                  });
              })
              .catch((e) => {
                console.log(e);
                dispatch(logout());
                navigate("/login");
              });
          } else {
            console.log("no refresh token");
            dispatch(logout());
            navigate("/login");
          }
        }
      })
      .finally(() => {
        setOpenOne(false);
        setValue(false);
      });
  };

  const handleChangeCustomer = (event) => {
    const value = event.target.value;
    setCustomerId(value);
  };

  useEffect(() => {
    if (user === null) return;
    const roles = jwt_decode(user.token).roles;
    if (roles.includes("ROLE_ADMIN")) {
      setValue(true);
      axios
        .get(`${REACT_APP_API_ENDPOINT}/api/AllCustomers`, {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        })
        .then((data) => {
          setCustomerList(data.data["hydra:member"]);
          setAllowed(true);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            refresh(dispatch, navigate);
          }
        })
        .finally(() => {
          setValue(false);
        });
    }
  }, [REACT_APP_API_ENDPOINT, dispatch, navigate, setValue, user]);

  useEffect(() => {
    setValue(true);
    if (!user) return;
    let url = "";
    url = `${REACT_APP_API_ENDPOINT}/api/AllUsers`;
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((data) => {
        setUsers(data.data["hydra:member"]);
      })
      .catch((e) => {
        refresh(dispatch, navigate);
      })
      .finally(() => {
        setValue(false);
      });
  }, [user, dispatch, navigate, setValue, REACT_APP_API_ENDPOINT]);

  useEffect(() => {
    if (user == null) {
      navigate("/login");
      return;
    }
    if (userId === "") {
      setValue(true);
      let url = "";
      if (customerId === "") {
        url = `${REACT_APP_API_ENDPOINT}/api/locations?page=${currentPage}&itemsPerPage=${itemPerPage}&order%5BcreatedAt%5D=desc`;
      } else {
        url = `${REACT_APP_API_ENDPOINT}/api/locations?page=${currentPage}&itemsPerPage=${itemPerPage}&order%5BcreatedAt%5D=desc&customer=${customerId}`;
      }
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        })
        .then((data) => {
          console.log(data.data["hydra:member"], data.data["hydra:totalItems"]);
          setAlertsList(data.data["hydra:member"]);
          setTotalCount(data.data["hydra:totalItems"]);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            refresh(dispatch, navigate);
          }
        })
        .finally(() => {
          setValue(false);
        });
    } else {
      setCurrentPage(1);
      setValue(true);
      let url = "";
      if (customerId === "") {
        url = `${REACT_APP_API_ENDPOINT}/api/locations?page=${currentPage}&itemsPerPage=${itemPerPage}&order%5BcreatedAt%5D=desc&device.user.id=${userId}`;
      } else {
        url = `${REACT_APP_API_ENDPOINT}/api/locations?page=${currentPage}&itemsPerPage=${itemPerPage}&order%5BcreatedAt%5D=desc&customer=${customerId}&device.user.id=${userId}`;
      }
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        })
        .then((data) => {
          console.log(data.data["hydra:member"], data.data["hydra:totalItems"]);
          setAlertsList(data.data["hydra:member"]);
          setTotalCount(data.data["hydra:totalItems"]);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            refresh(dispatch, navigate);
          }
        })
        .finally(() => {
          setValue(false);
        });
    }
  }, [
    REACT_APP_API_ENDPOINT,
    currentPage,
    dispatch,
    navigate,
    user,
    pageOrdering,
    setValue,
    userId,
    itemPerPage,
    customerId,
  ]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    dispatch(setItemPerPage(value));
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>
          {allowed && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
              }}
            >
              <div>
                <InputLabel
                  id="demo-multiple-checkbox-label"
                  style={{ color: dark ? "white" : "" }}
                >
                  Customer
                </InputLabel>
                <Select
                  style={{ width: "100px", height: "40px" }}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={customerId}
                  onChange={handleChangeCustomer}
                  input={<OutlinedInput label="Customer" />}
                  MenuProps={MenuProps}
                  sx={{
                    color: dark ? "white" : "",
                    background: dark
                      ? "linear-gradient(90deg, #314f8b 0%, #281a4b 95%, #281a4b 100%)"
                      : "",
                    "& .MuiOutlinedInput-root": {
                      "& > fieldset": { borderColor: dark ? "white" : "" },
                    },
                  }}
                >
                  {customerList.map((data, index) => (
                    <MenuItem key={index} value={data.id}>
                      <ListItemText primary={data.name} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
              &nbsp; &nbsp;
              <div style={{ height: 50, marginTop: 20 }}>
                <button
                  onClick={() => setCustomerId("")}
                  style={{
                    color: "white",
                    padding: 10,
                    background:
                      "linear-gradient(90deg, #314f8b 0%, #281a4b 95%, #281a4b 100%)",
                    border: "1px solid white",
                    borderRadius: 10,
                  }}
                >
                  reset
                </button>
              </div>
            </div>
          )}
        </div>

        <Dialog
          open={open}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Warning"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to delete all the alerts ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button
              onClick={() => {
                deleteAllHandler();
              }}
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <InputLabel
              id="demo-multiple-checkbox-label"
              style={{ color: dark ? "white" : "" }}
            >
              Filter by User
            </InputLabel>
            <br></br>
            <Select
              style={{ width: 200 }}
              labelId="demo-checkbox-label"
              id="demo-checkbox"
              value={userId}
              onChange={(event) => {
                setUserId(event.target.value);
              }}
              input={<OutlinedInput label="Tag" />}
              MenuProps={MenuProps}
              labelstyle={{ color: "#ff0000" }}
              sx={{
                color: dark ? "white" : "",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: dark ? "white" : "",
                },
                ".MuiSvgIcon-root ": {
                  fill: "white !important",
                },
              }}
            >
              {users.map((data) => (
                <MenuItem key={data.id} value={data.id} title={data.email}>
                  <ListItemText primary={data.email} />
                </MenuItem>
              ))}
            </Select>
          </div>
          &nbsp; &nbsp;
          <div style={{ height: 50, marginTop: 50 }}>
            <button
              onClick={() => setUserId("")}
              style={{
                color: "white",
                padding: 10,
                background:
                  "linear-gradient(90deg, #314f8b 0%, #281a4b 95%, #281a4b 100%)",
                border: "1px solid white",
                borderRadius: 10,
                marginRight: 10
              }}
            >
              reset
            </button>
            <Button variant="contained" color="error" onClick={handleClickOpen}>
              Delete All
            </Button>
          </div>
        </div>
      </div>
      <br />
      <br />
      <DialogDeleteConfirmation
        open={openOne}
        handleClose={handleCloseOne}
        message={"Are you sure you want to delete this alert ?"}
        deleteHandler={deleteHandler}
        id={id}
      />
      <TableContainer sx={tableContainerSx}>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                "& th": {
                  fontSize: "0.8rem",
                  color: dark ? "white" : "",
                },
              }}
            >
              <TableCell align="center">devices id</TableCell>
              <TableCell align="center">sent on</TableCell>
              <TableCell align="center">user</TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alertsList.map((row, index) => (
              <TableRow
                hover
                key={index}
                sx={{
                  "& td": {
                    color: dark ? "white" : "",
                    fontSize: "0.6rem",
                  },
                }}
              >
                <TableCell align="center">{row.device.device_id}</TableCell>
                <TableCell align="center">
                  {moment.tz(row.createdAt, moment.tz.guess(true)).format('YYYY-MM-DD HH:mm')}
                </TableCell>
                <TableCell align="center">{row.device.user.email}</TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#13a89e",
                      fontSize: 11,
                      padding: 1,
                    }}
                    onClick={() => {
                      navigate("/showAlert/" + row.id);
                    }}
                  >
                    Show
                  </Button>
                </TableCell>
                <TableCell align="center">
                  <button
                    onClick={() => {
                      setId(row.id);
                      setOpenOne(true);
                    }}
                    style={{ fontSize: 13 }}
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <hr style={{ color: dark ? "white" : "black" }} />
      <br />
      <br />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <InputLabel
          id="demo-multiple-checkbox-label"
          style={{ color: dark ? "white" : "", fontSize: "10px" }}
        >
          Rows per page
        </InputLabel>
        &nbsp; &nbsp; &nbsp;
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          value={itemPerPage}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected}
          MenuProps={MenuProps}
          sx={{
            color: dark ? "white" : "",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: dark ? "white" : "",
            },
            ".MuiSvgIcon-root ": {
              fill: "white !important",
            },
          }}
        >
          <MenuItem key={1} value={5}>
            <ListItemText primary={5} />
          </MenuItem>

          <MenuItem key={2} value={20}>
            <ListItemText primary={20} />
          </MenuItem>

          <MenuItem key={3} value={50}>
            <ListItemText primary={50} />
          </MenuItem>
        </Select>
      </div>

      <Pagination
        className="pagination-bar"
        page={currentPage}
        count={Math.ceil(totalCount / itemPerPage)}
        onChange={(event, value) => {
          setCurrentPage(value);
        }}
      />
    </>
  );
};

export default ListAlerts;
