import { FormGroup } from "@mui/material";
import Box from "@mui/material/Box";
import { Div, Label } from "../../pages/news/divNewsDetail";

const UserInfo = (props) => {
  return (
    <>
      <FormGroup>
        <img src={props.user.imgUrl} width="400" alt="" />
        <Label dark={props.dark}>Full name</Label>
        <Div dark={props.dark}>
          {props.user.firstname + " " + props.user.lastname}
        </Div>
        <Label dark={props.dark}>Email</Label>
        <Div dark={props.dark}>{props.user.email}</Div>
        <Label dark={props.dark}>Phone</Label>
        <Div dark={props.dark}>{props.user.phone}</Div>
        {props.user.country && (
          <>
            <p style={{ color: props.dark ? "white" : "" }}>Country:</p>
            <Box
              display="flex"
              flexDirection="column"
              style={{ width: "60px", marginLeft: "1%" }}
            >
              <img
                style={{ width: "60px", height: "25%" }}
                src={props.user.country?.flagUrl}
                alt=""
              />
              <small
                style={{
                  textAlign: "center",
                  color: props.dark ? "white" : "",
                }}
              >
                {props.user.country?.name}
              </small>
            </Box>
          </>
        )}
      </FormGroup>
    </>
  );
};

export default UserInfo;
