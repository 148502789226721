import React, { useState, useRef, useEffect } from "react";
import GoogleMapReact from 'google-map-react';
import useSupercluster from "use-supercluster";
import "./style.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import image from "../../assets/logo/map-marker_hfipes.png";
import Popup from "../../components/Popup";
import { refresh } from "../../refresh";
import jwt_decode from "jwt-decode";

// const Marker = ({ children }) => <div>{children}</div>;


export default function Monitor() {
  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);
  const auth = useSelector((state) => state.userSlice.user);
  const [points, setPoints] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { REACT_APP_API_ENDPOINT } = process.env;
  const REACT_APP_MAP_API = process.env.REACT_APP_MAP_API;
  const changeIntervalRef = useRef(null);
  const getMapOptions = useRef(null);
  // 314f8b e5ir wa7ad
  // 251142 awal wa7ad
  // 281a4b bi nos
  useEffect(() => {
    getMapOptions.current = (maps) => {
      return {
        streetViewControl: true,
        scaleControl: true,
        fullscreenControl: true,
        styles: [
          {
            featureType: "poi.business",
            elementType: "labels",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
        ],
        gestureHandling: "greedy",
        labels: true,
        zoomControl: true,
        mapTypeControl: true,
        mapTypeId: maps.MapTypeId.ROADMAP,
        mapTypeControlOptions: {
          style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: maps.ControlPosition.TOP_CENTER,
          mapTypeIds: [
            maps.MapTypeId.ROADMAP,
            maps.MapTypeId.SATELLITE,
            maps.MapTypeId.HYBRID,
          ],
        },

        scrollwheel: true,
        clickableIcons: false,
      };
    };
    if (!auth) {
      navigate("/login");
      return;
    }
    let url = `${REACT_APP_API_ENDPOINT}/api/getAllDevices`;
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + auth.token,
        },
      })
      .then((data) => {
        const d = data.data["hydra:member"];
        if (d.length !== 0) {
          setPoints(
            d.map(
              (crime) =>
                crime.lastLng !== null &&
                crime.lastLat !== null && {
                  type: "Feature",
                  properties: {
                    cluster: false,
                    crimeId: crime.id,
                    category: crime.type,
                    detail: crime,
                  },
                  geometry: {
                    type: "Point",
                    coordinates: [
                      parseFloat(crime.lastLng),
                      parseFloat(crime.lastLat),
                    ],
                  },
                }
            )
          );
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          refresh(dispatch, navigate);
        }
      });
    changeIntervalRef.current = setInterval(() => {
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + auth.token,
          },
        })
        .then((data) => {
          const d = data.data["hydra:member"];
          console.log(d);
          if (d.length !== 0) {
            setPoints(
              d.map(
                (crime) =>
                  crime.lastLng !== null &&
                  crime.lastLat !== null && {
                    type: "Feature",
                    properties: {
                      cluster: false,
                      crimeId: crime.id,
                      category: crime.type,
                      detail: crime,
                    },
                    geometry: {
                      type: "Point",
                      coordinates: [
                        parseFloat(crime.lastLng),
                        parseFloat(crime.lastLat),
                      ],
                    },
                  }
              )
            );
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            refresh(dispatch, navigate);
          }
        });
    }, 60000);
    return () => {
      clearInterval(changeIntervalRef.current);
    };
  }, [REACT_APP_API_ENDPOINT, auth, dispatch, getMapOptions, navigate]);

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  const [selectedMarker, setSelectedMarker] = useState(null);

  const Cluster = ({ children }) => (
    children
  );
  const Marker = ({ children, marker }) => (
    <div className="marker">
      {selectedMarker && selectedMarker === marker && (
        <Popup marker={marker} setSelectedMarker={setSelectedMarker} />
      )}
      <div className="marker-text">{children}</div>
    </div>
  );

  return (
    <div style={{ height: '85vh', width: '100%' }}>
      <GoogleMapReact
        options={getMapOptions.current}
        bootstrapURLKeys={{ key: REACT_APP_MAP_API }}
        defaultCenter={{ lat: 15, lng: 15 }}
        defaultZoom={1}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          console.log("loaded");
          mapRef.current = map;
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ]);
        }}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } =
            cluster.properties;

          if (isCluster) {
            return (
              <Cluster
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
              >
                <div
                  className="cluster-marker"
                  style={{
                    width: `${20 + (pointCount / points.length) * 20}px`,
                    height: `${20 + (pointCount / points.length) * 20}px`,
                  }}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id),
                      30
                    );
                    mapRef.current.setZoom(expansionZoom);
                    mapRef.current.panTo({ lat: latitude, lng: longitude });
                  }}
                >
                  {pointCount}
                </div>
              </Cluster>
            );
          }

          return (
            <Marker lat={latitude} lng={longitude} marker={cluster}>
              <button
                className="crime-marker"
                onClick={() => setSelectedMarker(cluster)}
              >
                <img src={image} alt="device" />
              </button>
            </Marker>
          );
        })}
      </GoogleMapReact>
    </div>
  );
}
