import {
  Box,
  Button,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Page from "../../components/Pagination";
import { login, logout, setItemPerPage } from "../../reducers/userSlice";
import SearchIcon from "@mui/icons-material/Search";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "./styles.scss";

// import required modules
import { FreeMode, Pagination } from "swiper";
import { UserContext } from "../../context/UserContext";
import { refresh } from "../../refresh";
import DialogDeleteConfirmation from "../../components/DialogDeleteConfirmation";
import secureLocalStorage from "react-secure-storage";
import jwt_decode from "jwt-decode";

const ListNews = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const user = useSelector((state) => state.userSlice.user);
  const itemPerPage = useSelector((state) => state.userSlice.itemPerPage);

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [totalCount, setTotalCount] = useState(0);
  const [newsList, setNewsList] = useState([]);
  const [pageOrdering, setPageOrdering] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const { setValue, dark } = useContext(UserContext);
  const [id, setId] = useState("");
  const [open, setOpen] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [allowed, setAllowed] = useState(false);

  const deleteHandler = (id) => {
    setValue(true);
    axios
      .delete(`${REACT_APP_API_ENDPOINT}/api/news/` + id, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        const newList = newsList.filter((item) => item.id !== id);
        setNewsList(newList);
        setTotalCount((prev) => prev - 1);
        setCurrentPage(1);
        setPageOrdering((prev) => prev + 1);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          const refresh_token = localStorage.getItem("refresh_token");
          if (refresh_token) {
            axios
              .post(`${REACT_APP_API_ENDPOINT}/token/refresh`, {
                refresh_token: refresh_token,
              })
              .then((data) => {
                console.log("refresh");
                axios
                  .delete(`${REACT_APP_API_ENDPOINT}/api/news/` + id, {
                    headers: {
                      Authorization: "Bearer " + data.data.token,
                    },
                  })
                  .then((res) => {
                    const newList = newsList.filter((item) => item.id !== id);
                    setNewsList(newList);
                    setTotalCount((prev) => prev - 1);
                    setCurrentPage(1);
                    setPageOrdering((prev) => prev + 1);
                    dispatch(
                      login({
                        loggedIn: true,
                        token: data.data.token,
                      })
                    );
                  });
              })
              .catch((e) => {
                console.log(e);
                dispatch(logout());
                navigate("/login");
              });
          } else {
            console.log("no refresh token");
            dispatch(logout());
            navigate("/login");
          }
        }
      })
      .finally(() => {
        setOpen(false);
        setValue(false);
      });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeCustomer = (event) => {
    const value = event.target.value;
    setCustomerId(value);
  };

  useEffect(() => {
    if (user === null) return;
    const roles = jwt_decode(user.token).roles;
    if (roles.includes("ROLE_ADMIN")) {
      setValue(true);
      axios
        .get(`${REACT_APP_API_ENDPOINT}/api/AllCustomers`, {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        })
        .then((data) => {
          setCustomerList(data.data["hydra:member"]);
          setAllowed(true);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            refresh(dispatch, navigate);
          }
        })
        .finally(() => {
          setValue(false);
        });
    }
  }, [REACT_APP_API_ENDPOINT, dispatch, navigate, setValue, user]);

  useEffect(() => {
    if (!user) {
      dispatch(logout());
      navigate("/login");
      return;
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    if (searchTerm === "") {
      setValue(true);
      let url = "";
      if (customerId === "") {
        url = `${REACT_APP_API_ENDPOINT}/api/news?page=${currentPage}&itemsPerPage=${itemPerPage + 1
          }&order%5BpublishedAt%5D=desc`;
      } else {
        url = `${REACT_APP_API_ENDPOINT}/api/news?page=${currentPage}&itemsPerPage=${itemPerPage + 1
          }&order%5BpublishedAt%5D=desc&customer=${customerId}`;
      }
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        })
        .then((data) => {
          console.log(data);
          setNewsList(data.data["hydra:member"]);
          setTotalCount(data.data["hydra:totalItems"]);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            refresh(dispatch, navigate);
          }
        })
        .finally(() => {
          setValue(false);
        });
    } else {
      const delayDebounceFn = setTimeout(() => {
        setCurrentPage(1);
        setValue(true);
        let url = "";
        if (customerId === "") {
          url =
            `${REACT_APP_API_ENDPOINT}/api/news?page=${currentPage}&itemsPerPage=${itemPerPage + 1
            }&order%5BpublishedAt%5D=desc&country.name=` + searchTerm;
        } else {
          url =
            `${REACT_APP_API_ENDPOINT}/api/news?page=${currentPage}&itemsPerPage=${itemPerPage + 1
            }&order%5BpublishedAt%5D=desc&customer=${customerId}&country.name=` +
            searchTerm;
        }
        axios
          .get(url, {
            headers: {
              Authorization: "Bearer " + user.token,
            },
          })
          .then((res) => {
            console.log(res);
            setNewsList(res.data["hydra:member"]);
            setTotalCount(res.data["hydra:totalItems"]);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              refresh(dispatch, navigate);
            }
          })
          .finally(() => {
            setValue(false);
          });
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [
    REACT_APP_API_ENDPOINT,
    currentPage,
    dispatch,
    navigate,
    searchTerm,
    user,
    pageOrdering,
    setValue,
    itemPerPage,
    customerId,
  ]);

  const ITEM_HEIGHT = 48;

  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    dispatch(setItemPerPage(value));
  };

  return (
    <>
      <div style={{ float: "right" }}>
        <InputLabel
          id="demo-multiple-checkbox-label"
          style={{ color: dark ? "white" : "", marginLeft: 30 }}
        >
          Country
        </InputLabel>
        <SearchIcon
          style={{
            position: "position",
            marginTop: "9px",
            color: dark ? "white" : "black",
          }}
        />
        &nbsp;
        <TextField
          InputLabelProps={{
            style: { color: dark ? "#fff" : "#000" },
          }}
          sx={{
            input: {
              color: dark ? "white" : "",
            },
            "& .MuiOutlinedInput-root": {
              "& > fieldset": { borderColor: dark ? "white" : "" },
            },
          }}
          size="small"
          id="outlined-basic"
          variant="outlined"
          placeholder="Country"
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
        ></TextField>
      </div>
      <br />
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#13a89e",
            height: 50,
            marginTop: 2,
          }}
          onClick={() => {
            navigate("/createNews");
          }}
        >
          Create
        </Button>
        &nbsp; &nbsp;
        {allowed && (
          <>
            <div>
              <InputLabel
                id="demo-multiple-checkbox-label"
                style={{ color: dark ? "white" : "" }}
              >
                Customer
              </InputLabel>
              <Select
                style={{ width: "100px", height: "40px" }}
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                value={customerId}
                onChange={handleChangeCustomer}
                input={<OutlinedInput label="Customer" />}
                MenuProps={MenuProps}
                sx={{
                  color: dark ? "white" : "",
                  background: dark
                    ? "linear-gradient(90deg, #314f8b 0%, #281a4b 95%, #281a4b 100%)"
                    : "",
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": { borderColor: dark ? "white" : "" },
                  },
                }}
              >
                {customerList.map((data, index) => (
                  <MenuItem key={index} value={data.id}>
                    <ListItemText primary={data.name} />
                  </MenuItem>
                ))}
              </Select>
            </div>

            &nbsp; &nbsp;
            <div style={{ height: 50, marginTop: 20 }}>
              <button
                onClick={() => setCustomerId("")}
                style={{
                  color: "white",
                  padding: 10,
                  background:
                    "linear-gradient(90deg, #314f8b 0%, #281a4b 95%, #281a4b 100%)",
                  border: "1px solid white",
                  borderRadius: 10,
                }}
              >
                reset
              </button>
            </div>
          </>
        )}

      </div>

      <DialogDeleteConfirmation
        open={open}
        handleClose={handleClose}
        message={"Are you sure you want to delete this news ?"}
        deleteHandler={deleteHandler}
        id={id}
      />

      <br />
      <br />
      {/* <Box
        justifyContent={"center"}
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        width="100%"
        gap={5}
        paddingX={20}
      > */}
      <Grid container spacing={5} style={{ width: "100%", margin: "auto" }}>
        {newsList.map((data, index) => {
          let preview = 0;
          if (data.country.length === 1) {
            preview = 1;
          }
          if (data.country.length === 2) {
            preview = 2;
          }

          if (data.country.length >= 3) {
            preview = 3;
          }
          return (
            <>
              <Grid item md={4} key={index}>
                <Paper
                  sx={{
                    height: "auto",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    boxShadow: "none",
                    borderRight:
                      (index + 1) % 3 !== 0
                        ? dark
                          ? "3px solid #56567f"
                          : "3px solid rgba(0, 0, 0, 0.3)"
                        : "",
                    marginLeft: -5,
                  }}
                  key={data.id}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Box textAlign="center" marginTop={1}>
                      <Typography
                        variant="subtitle2"
                        style={{ color: dark ? "white" : "" }}
                      >
                        {data.title.toUpperCase()}
                      </Typography>
                    </Box>
                    {
                      allowed &&
                      <Box textAlign="center" marginTop={1}>
                        <Typography
                          variant="subtitle2"
                          style={{ color: dark ? "white" : "" }}
                        >
                          Created by: {data.customer?.name ?? 'super admin'}
                        </Typography>
                      </Box>
                    }
                    <br />
                    <div>
                      <img
                        src={data.fileUrl}
                        alt=""
                        width="250px"
                        height="150px"
                      />
                    </div>
                    <Swiper
                      modules={[FreeMode, Pagination]}
                      pagination={{
                        dynamicBullets: true,
                      }}
                      freeMode={true}
                      className="mySwiper"
                      slidesPerView={preview}
                      spaceBetween={30}
                      style={{ height: 130, marginTop: -20 }}
                    >
                      {data.country.map((data, index) => (
                        <SwiperSlide
                          key={index}
                          style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                        >
                          <img
                            style={{
                              width: "50px",
                              height: "33%",
                              borderRadius: "50%",
                            }}
                            src={data.flagUrl}
                            alt=""
                          />
                          <small style={{ color: dark ? "white" : "" }}>
                            {data.iso_code_2}
                          </small>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </Box>
                  {/* <Box display="flex" justifyContent="space-around" width="100%">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <div>
                  <Typography variant="subtitle2" sx={{ mb: -1 }}>
                    Author: {data.author}
                  </Typography>
                </div>
              </Box>
            </Box> */}
                  <Box display="flex" justifyContent="center">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <div>
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: "#13a89e",
                            fontSize: 11,
                            padding: 1,
                          }}
                          onClick={() => {
                            navigate("/showNews/" + data.id);
                          }}
                        >
                          Show
                        </Button>
                        &nbsp;
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: "#92c8ec",
                            fontSize: 11,
                            padding: 1,
                          }}
                          onClick={() => {
                            navigate("/editNews/" + data.id);
                          }}
                        >
                          Edit
                        </Button>
                        &nbsp;
                        <button
                          onClick={() => {
                            setId(data.id);
                            setOpen(true);
                          }}
                          className="btn btn-danger"
                          style={{ fontSize: 13, paddingTop: 7, paddingBottom: 7 }}
                        >
                          Delete
                        </button>
                      </div>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            </>
          );
        })}
      </Grid>
      {/* </Box> */}
      <br />
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <InputLabel
          id="demo-multiple-checkbox-label"
          style={{ color: dark ? "white" : "", fontSize: "10px" }}
        >
          Rows per page
        </InputLabel>
        &nbsp; &nbsp; &nbsp;
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          value={itemPerPage}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected}
          MenuProps={MenuProps}
          sx={{
            color: dark ? "white" : "",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: dark ? "white" : "",
            },
            ".MuiSvgIcon-root ": {
              fill: "white !important",
            },
          }}
        >
          <MenuItem key={1} value={5}>
            <ListItemText primary={5} />
          </MenuItem>

          <MenuItem key={2} value={20}>
            <ListItemText primary={20} />
          </MenuItem>

          <MenuItem key={3} value={50}>
            <ListItemText primary={50} />
          </MenuItem>
        </Select>
      </div>

      <Page
        className="pagination-bar"
        page={currentPage}
        count={Math.ceil(totalCount / itemPerPage)}
        onChange={(event, value) => {
          setCurrentPage(value);
        }}
      />
    </>
  );
};

export default ListNews;
