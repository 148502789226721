import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { login, logout } from "./reducers/userSlice";

const { REACT_APP_API_ENDPOINT } = process.env;

export const refresh = (dispatch, navigate) => {
  const refresh_token = localStorage.getItem("refresh_token");
  if (refresh_token) {
    axios
      .post(`${REACT_APP_API_ENDPOINT}/token/refresh`, {
        refresh_token: refresh_token,
      })
      .then((data) => {
        console.log("refresh");
        dispatch(
          login({
            loggedIn: true,
            token: data.data.token,
          })
        );
      })
      .catch((e) => {
        console.log(e);
        dispatch(logout());
        navigate("/login");
      });
  } else {
    console.log("no refresh token");
    dispatch(logout());
    navigate("/login");
  }
};
