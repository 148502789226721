import LogoArian from "../../assets/logo/Logo.svg";
import moment from 'moment-timezone';

export default function Popup({ marker, setSelectedMarker }) {
  const detail = marker.properties.detail;

  function convertUtcToLocal(utcDateTime) {
    // Convert UTC date/time to local time zone
    const localDateTime = moment.utc(utcDateTime).local();

    // Format the local date/time as desired
    const formattedDateTime = localDateTime.format('YYYY-MM-DD HH:mm:ss');

    return formattedDateTime;
  }
  return (
    <div className="info-window">
      <div className="info-window-header">
        <div style={{ display: 'flex', alignItems: 'center', color: 'black' }} >
          <img
            src={LogoArian}
            alt=""
            loading="lazy"
            width="25"
            height="25"
            title=""
          />
          <b style={{ fontSize: '0.8rem' }}>{detail.device_id}</b>
        </div>
        <svg className="close" width="25" height="25" viewBox="0 0 24 24" onClick={() => setSelectedMarker(null)}>
          <line x1="0" y1="0" x2="24" y2="24" stroke="black" stroke-width="2" />
          <line x1="0" y1="24" x2="24" y2="0" stroke="black" stroke-width="2" />
        </svg>
      </div>
      <div className="info-window-content">
        <div className="small">
          Name: {detail.name}
        </div>
        <div className="small">
          User: {detail.user?.email}
        </div>
        <div className="small">
          Type: {detail.type}
        </div>
        <div className="small">
          Status: {detail.status === 0 ? 'offline' : 'online'}
        </div>
        <div className="small">
          LastSeen: {convertUtcToLocal(detail.updatedAt)}
        </div>
      </div>
    </div>
  );
}
