import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import defaultImage from "../../assets/images/user-profiler.png";
import "../../index.css";
import Moment from "moment";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { login, logout } from "../../reducers/userSlice";
import axios from "axios";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import secureLocalStorage from "react-secure-storage";

const { REACT_APP_API_ENDPOINT } = process.env;

const UserContacts = (props) => {
  const [open, setOpen] = useState(false);
  const { setValue } = useContext(UserContext);
  const [id, setId] = useState(null);

  const tableContainerSx = {
    border: "0px",
    marginTop: 4,
    borderRadius: 2,
    backgroundColor: "rgba(0, 0, 0, 0)",
  };

  const deleteHandler = (id) => {
    setValue(true);
    axios
      .delete(`${REACT_APP_API_ENDPOINT}/api/contacts/` + id, {
        headers: {
          Authorization: "Bearer " + props.auth.token,
        },
      })
      .then((res) => {
        const contactList = props.contacts.filter((item) => item.id !== id);
        props.setContacts(contactList);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          const refresh_token = localStorage.getItem("refresh_token");
          if (refresh_token) {
            axios
              .post(`${REACT_APP_API_ENDPOINT}/token/refresh`, {
                refresh_token: refresh_token,
              })
              .then((data) => {
                console.log("refresh");
                axios
                  .delete(`${REACT_APP_API_ENDPOINT}/api/contacts/` + id, {
                    headers: {
                      Authorization: "Bearer " + data.data.token,
                    },
                  })
                  .then((res) => {
                    const contactList = props.contacts.filter(
                      (item) => item.id !== id
                    );
                    props.setContacts(contactList);

                    props.dispatch(
                      login({
                        loggedIn: true,
                        token: data.data.token,
                      })
                    );
                  });
              })
              .catch((e) => {
                console.log(e);
                props.dispatch(logout());
                props.navigate("/login");
              });
          } else {
            console.log("no refresh token");
            props.dispatch(logout());
            props.navigate("/login");
          }
        }
      })
      .finally(() => {
        setOpen(false);
        setValue(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#13a89e",
        }}
        onClick={() => {
          props.navigate("/createContact/" + props.user.id);
        }}
      >
        Create
      </Button>
      <br />
      <br />
      <br />
      <h3 style={{ color: props.dark ? "white" : "" }}>
        User Emergency Contacts
      </h3>
      <br />
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Warning"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this contact ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button
            onClick={() => {
              deleteHandler(id);
            }}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      {props.contacts.length > 0 && (
        <TableContainer sx={tableContainerSx}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  "& th": {
                    fontSize: "1rem",
                    color: props.dark ? "white" : "",
                  },
                }}
              >
                <TableCell align="center">image</TableCell>
                <TableCell align="center">firstname</TableCell>
                <TableCell align="center">lastname</TableCell>
                <TableCell align="center">created at</TableCell>
                <TableCell align="center">user</TableCell>
                <TableCell align="center">mobile number</TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.contacts.map((row, index) => (
                <TableRow
                  hover
                  key={index}
                  sx={{
                    "& td": {
                      color: props.dark ? "white" : "",
                    },
                  }}
                >
                  <TableCell align="center">
                    {row.fileUrl.includes("images") && (
                      <img
                        src={row.fileUrl}
                        alt=""
                        width="50"
                        style={{ borderRadius: "50%" }}
                      />
                    )}
                    {!row.fileUrl.includes("images") && (
                      <img
                        src={defaultImage}
                        alt=""
                        width="50"
                        style={{ borderRadius: "50%" }}
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">{row.firstname}</TableCell>
                  <TableCell align="center">{row.lastname}</TableCell>
                  <TableCell align="center">
                    {Moment(row.createdAt).format("MMMM Do YYYY")}
                  </TableCell>
                  <TableCell align="center">{row.email}</TableCell>
                  <TableCell align="center">{row.phone}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#92c8ec",
                      }}
                      onClick={() => {
                        props.navigate("/editContact/" + row.id);
                      }}
                    >
                      Edit
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#ff0000",
                      }}
                      onClick={() => {
                        setId(row.id);
                        setOpen(true);
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {props.contacts.length === 0 && (
        <h3 style={{ color: "white", textAlign: "center" }}>No Contacts</h3>
      )}
    </>
  );
};

export default UserContacts;
