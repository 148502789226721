import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  Box,
  Button,
  Checkbox,
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "../../reducers/userSlice";
import { makeStyles } from "@mui/styles";
import { UserContext } from "../../context/UserContext";
import { refresh } from "../../refresh";
import "./styles.scss";

const { REACT_APP_API_ENDPOINT } = process.env;

const EditNews = () => {
  const fileInput = useRef(null);

  const { setValue, dark } = useContext(UserContext);

  const useHelperTextStyles = makeStyles(() => ({
    root: {
      color: dark ? "#9694ad" : "",
    },
  }));

  const helperTextStyles = useHelperTextStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const id = params["id"];

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [isoCountry, setIsoCountry] = useState([]);

  const user = useSelector((state) => state.userSlice.user);

  // imageList, addUpdateIndex,
  const onChange = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    // Accessing scss variable "--background-color"
    // and "--text-color" using plain JavaScript
    // and changing the same according to the state of "darkTheme"
    const root = document.documentElement;
    root?.style.setProperty("--text-color", dark ? "#fff" : "#000");
  }, [dark]);

  useEffect(() => {
    if (!user) return;
    axios.get(`${REACT_APP_API_ENDPOINT}/api/countries?page=1`).then((res) => {
      setCountries(res.data["hydra:member"]);
    });
  }, [user]);

  useEffect(() => {
    if (!user) return;

    setValue(true);
    axios
      .get(`${REACT_APP_API_ENDPOINT}/api/news/` + id, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((data) => {
        let isoArray = [];
        data.data.country.map((c) => isoArray.push(c.iso_code_2));
        setIsoCountry(isoArray);
        setTitle(data.data.title);
        setDescription(data.data.description);
        setUrl(data.data.url);
        setFileUrl(data.data.fileUrl);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          refresh(dispatch, navigate);
        }
      })
      .finally(() => {
        setValue(false);
      });
  }, [dispatch, id, navigate, setValue, user]);

  let valid = title !== "";

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setIsoCountry(typeof value === "string" ? value.split(",") : value);
  };

  const ITEM_HEIGHT = 48;

  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        borderColor: "white",
      },
    },
  };

  const onSubmit = () => {
    if (title !== "") {
      setLoading(true);
      var formData = new FormData();

      formData.append("title", title);

      formData.append("description", description);

      formData.append("url", url);

      isoCountry.forEach((element) => {
        formData.append("isoCountry[]", element);
      });

      formData.append("file", file);

      axios
        .post(`${REACT_APP_API_ENDPOINT}/api/updateNews/` + id, formData, {
          headers: {
            Authorization: "Bearer " + user.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((data) => {
          console.log(data);
          navigate("/news");
        })
        .catch((err) => {
          if (err.response.status === 401) {
            const refresh_token = localStorage.getItem("refresh_token");
            if (refresh_token) {
              axios
                .post(`${REACT_APP_API_ENDPOINT}/token/refresh`, {
                  refresh_token: refresh_token,
                })
                .then((data) => {
                  console.log("refresh");

                  axios
                    .post(
                      `${REACT_APP_API_ENDPOINT}/api/updateNews/` + id,
                      formData,
                      {
                        headers: {
                          Authorization: "Bearer " + data.data.token,
                          "Content-Type": "multipart/form-data",
                        },
                      }
                    )
                    .then(() => {
                      dispatch(
                        login({
                          loggedIn: true,
                          token: data.data.token,
                        })
                      );
                      navigate("/news");
                    });
                })
                .catch((e) => {
                  console.log(e);
                  dispatch(logout());
                  navigate("/login");
                });
            } else {
              console.log("no refresh token");
              dispatch(logout());
              navigate("/login");
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    // Accessing scss variable "--background-color"
    // and "--text-color" using plain JavaScript
    // and changing the same according to the state of "darkTheme"
    const root = document.documentElement;
    root?.style.setProperty(
      "--background-color",
      dark ? "rgba(255, 255, 255, 0.08)" : "rgba(0, 0, 0, 0.08)"
    );
    root?.style.setProperty("--text-color", dark ? "#fff" : "#000");
  }, [dark]);
  return (
    <>
      <h2 style={{ color: dark ? "white" : "" }}>Edit News</h2>
      <div style={{ width: "100%", marginBottom: "5%", marginTop: "5%" }}>
        <div style={{ width: "80%", margin: "auto" }}>
          <div style={{ width: "100%", textAlign: "center" }}>
            {fileUrl !== "" && (
              <Box
                style={{ maxWidth: "500px" }}
                component="img"
                alt="The house from the offer."
                src={fileUrl}
              />
            )}
          </div>
          <FormGroup>
            <TextField
              value={title}
              style={{ marginBottom: "20px" }}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
              aria-describedby="my-helper-text"
              label="Title"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
              FormHelperTextProps={{
                classes: {
                  root: helperTextStyles.root,
                },
              }}
            />
            <InputLabel
              id="demo-multiple-checkbox-label"
              style={{ color: dark ? "white" : "" }}
            >
              Content
            </InputLabel>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={5}
              style={{ width: "100%", marginBottom: "20px" }}
              value={description}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
              aria-describedby="my-helper-text"
              label="Content"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
              FormHelperTextProps={{
                classes: {
                  root: helperTextStyles.root,
                },
              }}
            />
            <TextField
              value={url}
              style={{ marginBottom: "20px" }}
              onChange={(event) => {
                setUrl(event.target.value);
              }}
              aria-describedby="my-helper-text"
              label="Url"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
              FormHelperTextProps={{
                classes: {
                  root: helperTextStyles.root,
                },
              }}
            />
            <InputLabel
              id="demo-multiple-checkbox-label"
              style={{ color: dark ? "white" : "" }}
            >
              Country
            </InputLabel>
            {countries.length > 0 && (
              <Select
                style={{ marginBottom: "20px" }}
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={isoCountry}
                onChange={handleChange}
                labelstyle={{ color: "#ff0000" }}
                input={
                  <OutlinedInput
                    style={{ color: dark ? "white" : "" }}
                    label="Tag"
                  />
                }
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                sx={{
                  color: dark ? "white" : "",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: dark ? "white" : "",
                  },
                  ".MuiSvgIcon-root ": {
                    fill: "white !important",
                  },
                }}
              >
                {countries.map((data) => (
                  <MenuItem key={data.id} value={data.iso_code_2}>
                    <Checkbox
                      checked={isoCountry.indexOf(data.iso_code_2) > -1}
                    />
                    <img src={data.flagUrl} alt="" /> &nbsp; &nbsp;
                    <ListItemText primary={data.name} />
                  </MenuItem>
                ))}
              </Select>
            )}
            <div style={{ color: dark ? "white" : "" }}>max image size 2mb</div>
            <br />
            <div>
              <button
                onClick={() => {
                  fileInput.current.click();
                }}
                htmlFor="files"
                className="btn"
                style={{
                  color: "white",
                  backgroundColor: "#00A79D",
                  marginBottom: "20px",
                }}
              >
                Select Image
              </button>
              <input
                type="file"
                ref={fileInput}
                onChange={onChange}
                style={{ visibility: "hidden" }}
              />
              <div style={{ color: dark ? "white" : "" }}>
                {fileInput.current?.value.split("\\")[2]}
              </div>
            </div>
            <Button
              disabled={!valid || loading}
              onClick={onSubmit}
              style={{
                width: "20%",
                borderRadius: 10,
                padding: "5px 20px",
                fontSize: "18px",
                backgroundColor: "#00A79D",
                alignSelf: "center",
              }}
              variant="contained"
            >
              Submit
            </Button>
          </FormGroup>
        </div>
      </div>
    </>
  );
};

export default EditNews;
