import { HomeContainer, LogoImage, LogoText } from "./HomeStyle";
import LogoArian from "../../assets/logo/Logo.svg";
import LogoTextImg from "../../assets/logo/Ariane in white.svg";
import LogoTextImg1 from "../../assets/logo/Ariane in Blue.svg";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { refresh } from "../../refresh";

const HomePage = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const user = useSelector((state) => state.userSlice.user);
  const { dark } = useContext(UserContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if(user === null){
      return null;
    }
    axios
      .get(`${REACT_APP_API_ENDPOINT}/api/me`, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
        refresh(dispatch, navigate);
      });
  }, [REACT_APP_API_ENDPOINT, dispatch, navigate, user]);

  return (
    <HomeContainer>
      <LogoImage src={LogoArian} />
      {dark && <LogoText src={LogoTextImg} />}
      {!dark && <LogoText src={LogoTextImg1} />}
    </HomeContainer>
  );
};

export default HomePage;
