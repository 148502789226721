import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { refresh } from "../../refresh";
import { GoogleMap, Marker, withGoogleMap } from "react-google-maps";
import withScriptjs from "react-google-maps/lib/async/withScriptjs";
  const REACT_APP_MAP_API = process.env.REACT_APP_MAP_API;
const ShowAlert = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;


  const user = useSelector((state) => state.userSlice.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [alert, setAlert] = useState(null);
  const { value, setValue } = useContext(UserContext);

  const id = useParams()["id"];

  useEffect(() => {
    setValue(true);
    axios
      .get(`${REACT_APP_API_ENDPOINT}/api/locations/${id}`, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((data) => {
        console.log(data.data);
        setAlert(data.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          refresh(dispatch, navigate);
        }
      })
      .finally(() => {
        setValue(false);
      });
  }, [REACT_APP_API_ENDPOINT, dispatch, id, navigate, user, setValue]);

  console.log(alert);
  return (
    <>
      {alert && (
        <>
          <div
            style={{
              position: "absolute",
              width: "40%",
            }}
          >
            <div>
              <GoogleMap
                defaultZoom={3}
                center={{
                  lng: parseFloat(alert.longitude),
                  lat: parseFloat(alert.latitude),
                }}
              >
                <Marker
                  position={{
                    // CN Tower default
                    lng: parseFloat(alert.longitude),
                    lat: parseFloat(alert.latitude),
                  }}
                />
              </GoogleMap>
            </div>
          </div>
          <div
            style={{
              width: "20%",
              marginLeft: "55%",
              top: 100,
              position: "absolute",
            }}
          >
            <TableContainer component={Paper}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    hover
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">User</TableCell>
                    <TableCell align="center">
                      {alert.device.user.email}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    hover
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">Device Id</TableCell>
                    <TableCell align="center">
                      {alert.device.device_id}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    hover
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">Latitude</TableCell>
                    <TableCell align="center">{alert.latitude}</TableCell>
                  </TableRow>
                  <TableRow
                    hover
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">Longitude</TableCell>
                    <TableCell align="center">{alert.longitude}</TableCell>
                  </TableRow>

                  <TableRow
                    hover
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">Altitude</TableCell>
                    <TableCell align="center">{alert.altitude}</TableCell>
                  </TableRow>
                  <TableRow
                    hover
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">Bearing</TableCell>
                    <TableCell align="center">{alert.bearing}</TableCell>
                  </TableRow>
                  <TableRow
                    hover
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">Velocity</TableCell>
                    <TableCell align="center">{alert.velocity}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}
      {!alert && value === false && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <h1>No location to show</h1>
        </div>
      )}
    </>
  );
};

const MapComponent = withScriptjs(withGoogleMap(ShowAlert));

const Show = () => (
  <>
    <MapComponent
      googleMapURL={`https://maps.googleapis.com/maps/api/js?libraries=geometry,drawing&key=${REACT_APP_MAP_API}`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `80vh`, width: "50%" }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  </>
);

export default Show;
