import {
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Button,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { UserContext } from "../../context/UserContext";
import { login, logout } from "../../reducers/userSlice";
import { refresh } from "../../refresh";
import defaultImage from "../../assets/images/user-profiler.png";
// import secureLocalStorage from "react-secure-storage";

const { REACT_APP_API_ENDPOINT } = process.env;

const CreateDevice = () => {
  const auth = useSelector((state) => state.userSlice.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [user, setUser] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { dark } = useContext(UserContext);

  useEffect(() => {
    // Accessing scss variable "--background-color"
    // and "--text-color" using plain JavaScript
    // and changing the same according to the state of "darkTheme"
    const root = document.documentElement;
    root?.style.setProperty("--text-color", dark ? "#fff" : "#000");
  }, [dark]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  let valid = name !== "" && type !== "" && user !== "";

  const onSubmit = () => {
    if (valid) {
      setLoading(true);

      axios
        .post(
          `${REACT_APP_API_ENDPOINT}/api/devices`,
          {
            name: name,
            type: type,
            user: "/api/users/" + user,
          },
          {
            headers: {
              Authorization: "Bearer " + auth.token,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((data) => {
          console.log(data);
          navigate("/devices");
        })
        .catch((err) => {
          const refresh_token = localStorage.getItem("refresh_token");
          if (refresh_token) {
            axios
              .post(`${REACT_APP_API_ENDPOINT}/token/refresh`, {
                refresh_token: refresh_token,
              })
              .then((data) => {
                console.log("refresh");
                axios
                  .post(
                    `${REACT_APP_API_ENDPOINT}/api/devices`,
                    {
                      name: name,
                      type: type,
                      user: "/api/users/" + user,
                    },
                    {
                      headers: {
                        Authorization: "Bearer " + data.data.token,
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  )
                  .then((data) => {
                    console.log(data);
                    dispatch(
                      login({
                        loggedIn: true,
                        token: data.data.token,
                      })
                    );
                    navigate("/");
                  });
              })
              .catch((e) => {
                console.log(e);
                dispatch(logout());
                navigate("/login");
              });
          } else {
            console.log("no refresh token");
            dispatch(logout());
            navigate("/login");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleChange = (event) => {
    setUser(event.target.value);
  };

  useEffect(() => {
    if (!auth) {
      return;
    }
    axios
      .get(`${REACT_APP_API_ENDPOINT}/api/getAllUsers`, {
        headers: {
          Authorization: "Bearer " + auth.token,
        },
      })
      .then((data) => {
        setUsers(data.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          refresh(dispatch, navigate);
        }
      });
  }, [dispatch, navigate, auth]);

  return (
    <>
      <h2 style={{ color: dark ? "white" : "" }}>Create Device</h2>
      <div style={{ width: "100%", marginBottom: "5%", marginTop: "5%" }}>
        <div style={{ width: "80%", margin: "auto" }}>
          <FormGroup>
            <TextField
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
              id="outlined-basic"
              label="Name"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
            ></TextField>
            <br />
            <TextField
              value={type}
              onChange={(event) => {
                setType(event.target.value);
              }}
              id="outlined-basic"
              label="Type"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
            ></TextField>
            <br />
            <InputLabel
              style={{ color: dark ? "white" : "" }}
              id="demo-checkbox-label"
            >
              User
            </InputLabel>
            <br />
            <Select
              labelId="demo-checkbox-label"
              id="demo-checkbox"
              value={user}
              onChange={handleChange}
              input={<OutlinedInput label="Users" />}
              renderValue={(selected) => selected}
              MenuProps={MenuProps}
              labelStyle={{ color: "#ff0000" }}
              sx={{
                color: "white",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: dark ? "white" : "",
                },
                ".MuiSvgIcon-root ": {
                  fill: "white !important",
                },
              }}
            >
              {users.map((data) => (
                <MenuItem key={data.id} value={data.id} title={data.email}>
                  {data.fileUrl.includes("images") && (
                    <img
                      src={data.fileUrl}
                      alt=""
                      width="50"
                      style={{ borderRadius: "50%" }}
                    />
                  )}
                  {!data.fileUrl.includes("images") && (
                    <img
                      src={defaultImage}
                      alt=""
                      width="50"
                      style={{ borderRadius: "50%" }}
                    />
                  )}{" "}
                  &nbsp; &nbsp;
                  <ListItemText primary={data.email} />
                </MenuItem>
              ))}
            </Select>
            <br />
            <br />

            <Button
              disabled={!valid || loading}
              onClick={onSubmit}
              style={{
                width: "20%",
                borderRadius: 10,
                padding: "5px 20px",
                fontSize: "18px",
                backgroundColor: "#00A79D",
                alignSelf: "center",
              }}
              variant="contained"
            >
              Submit
            </Button>
          </FormGroup>
        </div>
      </div>
    </>
  );
};

export default CreateDevice;
