import "./App.css";
import { ToastContainer } from "react-toastify";
import { Routes, Route, useNavigate } from "react-router-dom";
import Account from "./pages/account";
import { useEffect, useState } from "react";
// import axios from "axios";
import { useSelector } from "react-redux";
import Home from "./pages/home";
import jwt_decode from "jwt-decode";
import { useCallback } from "react";
function App() {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const user = useSelector((state) => state.userSlice.user);
  const navigate = useNavigate();
  const [notification, setNotification] = useState();

  // const showMessage = useCallback((message) => {
  //   const tests = JSON.parse(message.data);
  //   console.log(tests.status);
  //   sendNotification({
  //     title: "New Notification",
  //     message: tests.status,
  //     icon: "https://cdn2.iconfinder.com/data/icons/mixed-rounded-flat-icon/512/megaphone-64.png",
  //   });
  // }, []);
  useEffect(() => {
    if (user == null) {
      console.log(user);
      navigate("/login");
      return;
    }
    // axios
    //   .get(`${REACT_APP_API_ENDPOINT}/api/me`, {
    //     headers: {
    //       Authorization: "Bearer " + user.token,
    //     },
    //   })
    //   .then((data) => {
    //     console.log(data);
    //   })
    //   .catch((err) => {
    //     // console.log(err);
    //     navigate('/login')
    //   });
  }, [REACT_APP_API_ENDPOINT, navigate, user]);

  // function sendNotification(data) {
  //   if (data === undefined || !data) {
  //     return false;
  //   }
  //   var title = data.title === undefined ? "Notification" : data.title;
  //   var clickCallback = data.clickCallback;
  //   var message = data.message === undefined ? "null" : data.message;
  //   var icon =
  //     data.icon === undefined
  //       ? "https://cdn2.iconfinder.com/data/icons/mixed-rounded-flat-icon/512/megaphone-64.png"
  //       : data.icon;
  //   var sendNotification = function () {
  //     var notification = new Notification(title, {
  //       icon: icon,
  //       body: message,
  //     });
  //     if (clickCallback !== undefined) {
  //       notification.onclick = function () {
  //         clickCallback();
  //         notification.close();
  //       };
  //     }
  //   };

  //   if (!window.Notification) {
  //     return false;
  //   } else {
  //     if (Notification.permission === "default") {
  //       Notification.requestPermission(function (p) {
  //         if (p !== "denied") {
  //           sendNotification();
  //         }
  //       });
  //     } else {
  //       sendNotification();
  //     }
  //   }
  // }
  // useEffect(() => {
  //   // if (user == null) {
  //   //   console.log(user);
  //   //   return;
  //   // }
  //   // if (!("Notification" in window)) {
  //   //   console.log("Browser does not support desktop notification");
  //   // } else {
  //   //   Notification.requestPermission();
  //   // }
  //   // Notification.requestPermission(function (result) {
  //   //   if (result === "denied") {
  //   //     console.log("Permission wasn't granted. Allow a retry.");
  //   //     return;
  //   //   } else if (result === "default") {
  //   //     console.log("The permission request was dismissed.");
  //   //     return;
  //   //   }
  //   //   console.log("Permission was granted for notifications");
  //   // });
  //   // const roles = jwt_decode(user.token).roles;
  //   // let url = null;
  //   // let eventSource = null;
  //   // // dev https://mercure.sosapp.obdev.net/.well-known/mercure
  //   // if (roles.includes("ROLE_ADMIN")) {
  //   //   url = new URL(`https://mercure.arianesos.com/.well-known/mercure`);
  //   //   url.searchParams.append("topic", `super_admin_notifications`);
  //   //   console.log("admin connected");
  //   // }

  //   // if (roles.includes("ROLE_CUSTOMER")) {
  //   //   const cId = jwt_decode(user.token).customer;
  //   //   url = new URL(`https://mercure.arianesos.com/.well-known/mercure`);
  //   //   console.log(`customer_notifications/${cId}`);
  //   //   url.searchParams.append("topic", `customer_notifications/${cId}`);
  //   //   console.log("customer connected");
  //   // }

  //   // if (url !== null) {
  //   //   eventSource = new EventSource(url);
  //   //   eventSource.addEventListener("message", showMessage, true);
  //   // }

  //   // return () => {
  //   //   if (eventSource !== null) eventSource.close();
  //   // };
  // }, [showMessage, user]);

  return (
    <>
      <ToastContainer hideProgressBar />
      <Routes>
        <Route exact path="/*" element={<Home />} />
        <Route exact path="/login" element={<Account />} />
      </Routes>
    </>
  );
}

export default App;
