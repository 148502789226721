import { FormGroup, Button, TextField } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { UserContext } from "../../context/UserContext";
import { login, logout } from "../../reducers/userSlice";
import { refresh } from "../../refresh";

const { REACT_APP_API_ENDPOINT } = process.env;

const EditCustomer = () => {
  const auth = useSelector((state) => state.userSlice.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const { dark, setValue } = useContext(UserContext);
  const id = useParams()["id"];

  useEffect(() => {
    // Accessing scss variable "--background-color"
    // and "--text-color" using plain JavaScript
    // and changing the same according to the state of "darkTheme"
    const root = document.documentElement;
    root?.style.setProperty("--text-color", dark ? "#fff" : "#000");
  }, [dark]);

  useEffect(() => {
    if (auth == null) {
      navigate("/login");
      return;
    }
    setValue(true);
    axios
      .get(`${REACT_APP_API_ENDPOINT}/api/customers/${id}`, {
        headers: {
          Authorization: "Bearer " + auth.token,
        },
      })
      .then((data) => {
        setName(data.data.name);
      })
      .catch((e) => {
        console.log(e);
        if (e.response.status === 401) {
          refresh(dispatch, navigate);
        }
      })
      .finally(() => {
        setValue(false);
      });
  }, [auth, dispatch, id, navigate, setValue]);

  let valid = name !== "";

  const onSubmit = () => {
    if (valid) {
      setLoading(true);

      axios
        .put(
          `${REACT_APP_API_ENDPOINT}/api/customers/${id}`,
          {
            name: name,
          },
          {
            headers: {
              Authorization: "Bearer " + auth.token,
            },
          }
        )
        .then((data) => {
          console.log(data);
          navigate("/customers");
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            const refresh_token = localStorage.getItem("refresh_token");
            if (refresh_token) {
              axios
                .post(`${REACT_APP_API_ENDPOINT}/token/refresh`, {
                  refresh_token: refresh_token,
                })
                .then((data) => {
                  console.log("refresh");
                  axios
                    .put(
                      `${REACT_APP_API_ENDPOINT}/api/customers/${id}`,
                      {
                        name: name,
                      },
                      {
                        headers: {
                          Authorization: "Bearer " + data.data.token,
                        },
                      }
                    )
                    .then((data) => {
                      console.log(data);
                      dispatch(
                        login({
                          loggedIn: true,
                          token: data.data.token,
                        })
                      );
                      navigate("/");
                    });
                })
                .catch((e) => {
                  console.log(e);
                  dispatch(logout());
                  navigate("/login");
                });
            } else {
              console.log("no refresh token");
              dispatch(logout());
              navigate("/login");
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <h2 style={{ color: dark ? "white" : "" }}>Create Customer</h2>
      <div style={{ width: "100%", marginBottom: "5%", marginTop: "5%" }}>
        <div style={{ width: "80%", margin: "auto" }}>
          <FormGroup>
            <TextField
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
              id="outlined-basic"
              label="Name"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
            ></TextField>
            <br />
            <Button
              disabled={!valid || loading}
              onClick={onSubmit}
              style={{
                width: "20%",
                borderRadius: 10,
                padding: "5px 20px",
                fontSize: "18px",
                backgroundColor: "#00A79D",
                alignSelf: "center",
              }}
              variant="contained"
            >
              Submit
            </Button>
          </FormGroup>
        </div>
      </div>
    </>
  );
};

export default EditCustomer;
