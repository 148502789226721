import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "../../components/Pagination";

import "../../index.css";
import Button from "@mui/material/Button";

import moment from 'moment-timezone';
import {
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TableCell,
} from "@mui/material";
import { UserContext } from "../../context/UserContext.js";
import { refresh } from "../../refresh.js";
import { login, logout, setItemPerPage } from "../../reducers/userSlice";
import useWindowDimensions from "../../hooks/dimension";
import DialogDeleteConfirmation from "../../components/DialogDeleteConfirmation";

const MenuProps = {
  PaperProps: {
    style: {
      height: "auto",
      width: 250,
    },
  },
};

const CustomerList = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const user = useSelector((state) => state.userSlice.user);
  const itemPerPage = useSelector((state) => state.userSlice.itemPerPage);
  const navigate = useNavigate();
  const [deviceList, setDeviceList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [totalCount, setTotalCount] = useState(0);
  const [pageOrdering, setPageOrdering] = useState(0);
  const { setValue, dark } = useContext(UserContext);
  const [id, setId] = useState(null);
  const [open, setOpen] = useState(false);
  const { width } = useWindowDimensions();

  const handleChangePage = (event) => {
    const {
      target: { value },
    } = event;
    dispatch(setItemPerPage(value));
  };

  const handleClose = () => {
    setOpen(false);
  };

  function convertUtcToLocal(utcDateTime) {
    // Convert UTC date/time to local time zone
    const localDateTime = moment.utc(utcDateTime).local();

    // Format the local date/time as desired
    const formattedDateTime = localDateTime.format('YYYY-MM-DD HH:mm:ss');

    return formattedDateTime;
  }

  const deleteHandler = (id) => {
    setValue(true);
    axios
      .delete(`${REACT_APP_API_ENDPOINT}/api/customers/` + id, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        const newList = deviceList.filter((item) => item.id !== id);
        setDeviceList(newList);
        setTotalCount((prev) => prev - 1);
        setCurrentPage(1);
        setPageOrdering((prev) => prev + 1);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          const refresh_token = localStorage.getItem("refresh_token");
          if (refresh_token) {
            axios
              .post(`${REACT_APP_API_ENDPOINT}/token/refresh`, {
                refresh_token: refresh_token,
              })
              .then((data) => {
                console.log("refresh");
                axios
                  .delete(`${REACT_APP_API_ENDPOINT}/api/customers/` + id, {
                    headers: {
                      Authorization: "Bearer " + data.data.token,
                    },
                  })
                  .then((res) => {
                    const newList = deviceList.filter((item) => item.id !== id);
                    setDeviceList(newList);
                    setTotalCount((prev) => prev - 1);
                    setCurrentPage(1);
                    setPageOrdering((prev) => prev + 1);
                    dispatch(
                      login({
                        loggedIn: true,
                        token: data.data.token,
                      })
                    );
                  });
              })
              .catch((e) => {
                console.log(e);
                dispatch(logout());
                navigate("/login");
              });
          } else {
            console.log("no refresh token");
            dispatch(logout());
            navigate("/login");
          }
        }
      })
      .finally(() => {
        setValue(false);
        setOpen(false);
      });
  };

  useEffect(() => {
    if (user == null) {
      navigate("/login");
      return;
    }

    setValue(true);
    axios
      .get(
        `${REACT_APP_API_ENDPOINT}/api/customers?page=${currentPage}&itemsPerPage=${itemPerPage}&order%5BcreatedAt%5D=desc`,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((data) => {
        console.log(data.data);
        setDeviceList(data.data["hydra:member"]);
        setTotalCount(data.data["hydra:totalItems"]);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          refresh(dispatch, navigate);
        }
      })
      .finally(() => {
        setValue(false);
      });
  }, [
    REACT_APP_API_ENDPOINT,
    currentPage,
    dispatch,
    navigate,
    user,
    pageOrdering,
    itemPerPage,
    setValue,
  ]);

  const tableContainerSx = {
    border: "0px",
    marginTop: 4,
    borderRadius: 2,
    backgroundColor: "rgba(0, 0, 0, 0)",
    width: "100%",
    maxWidth: width - 260,
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#13a89e",
            }}
            onClick={() => {
              navigate("/createCustomer");
            }}
          >
            Create
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#13a89e",
            }}
            onClick={() => {
              navigate("/createAdmin");
            }}
          >
            Add Admin
          </Button>
        </div>
        <DialogDeleteConfirmation
          open={open}
          handleClose={handleClose}
          message={"Are you sure you want to delete this customer ?"}
          deleteHandler={deleteHandler}
          id={id}
        />
      </div>
      <br />
      <br />
      <TableContainer sx={tableContainerSx}>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                "& th": {
                  fontSize: "0.8rem",
                  color: dark ? "white" : "",
                },
              }}
            >
              <TableCell align="center">customer name</TableCell>
              <TableCell align="center">customer access code</TableCell>
              <TableCell align="center">created on</TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deviceList.map((row, index) => (
              <TableRow
                hover
                key={index}
                sx={{
                  "& td": {
                    color: dark ? "white" : "",
                    fontSize: "0.6rem",
                  },
                }}
              >
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center" style={{ fontFamily: "roboto", fontSize: 15 }}>
                  {row.accessCode}
                </TableCell>
                <TableCell align="center">
                  {convertUtcToLocal(row.createdAt)}
                </TableCell>

                <TableCell align="center">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#92c8ec",
                      fontSize: 11,
                      padding: 1,
                    }}
                    onClick={() => {
                      navigate("/editCustomer/" + row.id);
                    }}
                  >
                    Edit
                  </Button>
                </TableCell>
                <TableCell align="center">
                  <button
                    onClick={() => {
                      setId(row.id);
                      setOpen(true);
                    }}
                    style={{ fontSize: 13 }}
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <hr style={{ color: dark ? "white" : "black" }} />
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <InputLabel
          id="demo-multiple-checkbox-label"
          style={{ color: dark ? "white" : "", fontSize: "10px" }}
        >
          Rows per page
        </InputLabel>
        &nbsp; &nbsp; &nbsp;
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          value={itemPerPage}
          onChange={handleChangePage}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected}
          MenuProps={MenuProps}
          sx={{
            color: dark ? "white" : "",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: dark ? "white" : "",
            },
            ".MuiSvgIcon-root ": {
              fill: "white !important",
            },
          }}
        >
          <MenuItem key={1} value={5}>
            <ListItemText primary={5} />
          </MenuItem>

          <MenuItem key={2} value={20}>
            <ListItemText primary={20} />
          </MenuItem>

          <MenuItem key={3} value={50}>
            <ListItemText primary={50} />
          </MenuItem>
        </Select>
      </div>

      <Pagination
        className="pagination-bar"
        page={currentPage}
        count={Math.ceil(totalCount / itemPerPage)}
        onChange={(event, value) => {
          setCurrentPage(value);
        }}
      />
    </>
  );
};

export default CustomerList;
