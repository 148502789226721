import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import {
  withGoogleMap,
  GoogleMap,
  Polyline,
  Marker,
  OverlayView,
  InfoWindow,
} from "react-google-maps";
import withScriptjs from "react-google-maps/lib/async/withScriptjs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Moment from "moment";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { refresh } from "../../refresh";
// import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Checkbox,
  createTheme,
  FormControlLabel,
  ThemeProvider,
} from "@mui/material";

const { REACT_APP_API_ENDPOINT } = process.env;
const REACT_APP_MAP_API = process.env.REACT_APP_MAP_API;

const Map = () => {
  const [dateFrom, setDateFrom] = React.useState(null);
  const [dateTo, setDateTo] = React.useState(null);
  const [checkAll, setCheckAll] = useState(false);
  const auth = useSelector((state) => state.userSlice.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = useParams()["id"];
  const [selected1, setSelected1] = useState(false);
  const [selected2, setSelected2] = useState(false);
  const { setValue, value, dark } = useContext(UserContext);
  const [center, setCenter] = useState({
    lat: 18.559008,
    lng: -68.388881,
  });

  const [path, setPath] = useState([]);

  useEffect(() => {
    if (auth !== null) {
      if (checkAll) {
        setValue(true);
        console.log("all");
        axios
          .get(`${REACT_APP_API_ENDPOINT}/api/trackers?device=${id}`, {
            headers: {
              Authorization: "Bearer " + auth.token,
            },
          })
          .then((data) => {
            if (data.data === null || data.data["hydra:member"].length === 0) {
              setPath([]);
              setValue(false);
              return;
            }
            setCenter({
              lat: parseFloat(
                data.data["hydra:member"][data.data["hydra:member"].length - 1]
                  .latitude
              ),
              lng: parseFloat(
                data.data["hydra:member"][data.data["hydra:member"].length - 1]
                  .longitude
              ),
            });
            let d = data.data["hydra:member"].map((element) => ({
              lat: parseFloat(element.latitude),
              lng: parseFloat(element.longitude),
              createdAt: element.createdAt,
            }));
            setPath(d);
          })
          .catch((err) => {
            console.log(err);
            if (err.response && err.response.status === 401) {
              refresh(dispatch, navigate);
            }
          })
          .finally(() => {
            setValue(false);
          });
      } else {
        setPath([]);
        if (dateFrom && dateTo) {
          setValue(true);
          console.log(dateFrom["$y"], dateFrom["$M"] + 1, dateFrom["$D"]);
          axios
            .get(
              `${REACT_APP_API_ENDPOINT}/api/trackers?device=${id}&createdAt%5Bbefore%5D=${
                dateTo["$y"]
              }-${dateTo["$M"] + 1}-${dateTo["$D"]}&createdAt%5Bafter%5D=${
                dateFrom["$y"]
              }-${dateFrom["$M"] + 1}-${dateFrom["$D"]}`,
              {
                headers: {
                  Authorization: "Bearer " + auth.token,
                },
              }
            )
            .then((data) => {
              if (
                data.data === null ||
                data.data["hydra:member"].length === 0
              ) {
                setPath([]);
                setValue(false);
                return;
              }
              setCenter({
                lat: parseFloat(
                  data.data["hydra:member"][
                    data.data["hydra:member"].length - 1
                  ].latitude
                ),
                lng: parseFloat(
                  data.data["hydra:member"][
                    data.data["hydra:member"].length - 1
                  ].longitude
                ),
              });
              let d = data.data["hydra:member"].map((element) => ({
                lat: parseFloat(element.latitude),
                lng: parseFloat(element.longitude),
                createdAt: element.createdAt,
              }));
              setPath(d);
            })
            .catch((err) => {
              console.log(err);
              if (err.response && err.response.status === 401) {
                refresh(dispatch, navigate);
              }
            })
            .finally(() => {
              setValue(false);
            });
        }
      }
    } else {
      navigate("/login");
    }
  }, [
    setPath,
    auth,
    dispatch,
    navigate,
    id,
    setValue,
    dateFrom,
    dateTo,
    checkAll,
  ]);

  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / -20),
  });
  const color = dark ? "#fff" : "";
  const theme = createTheme({
    components: {
      MuiIconButton: {
        styleOverrides: {
          sizeMedium: {
            color,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            color,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color,
          },
        },
      },
    },
  });

  const onChange = ({ center }) => {
    console.log("onChange", center);
    setCenter(center);
  };

  return (
    <>
      <div
        style={{
          color: dark ? "white" : "",
          position: "absolute",
          top: 200,
          right: 20,
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={checkAll}
              sx={{
                color: dark ? "white" : "",
                "&.Mui-checked": {
                  color: dark ? "white" : "",
                },
              }}
              onChange={() => {
                setCheckAll((prev) => !prev);
              }}
            />
          }
          label="Show all traces"
        />

        <br />
        <br />
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="from"
              value={dateFrom}
              onChange={(newValue) => {
                setDateFrom(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    svg: { color: dark ? "#fff" : "" },
                    input: { color: dark ? "#fff" : "" },
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </ThemeProvider>
        <br />
        <br />
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="to"
              value={dateTo}
              onChange={(newValue) => {
                setDateTo(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    svg: { color: dark ? "#fff" : "" },
                    input: { color: dark ? "#fff" : "" },
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </ThemeProvider>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <GoogleMap
          defaultZoom={3}
          center={{ lat: center.lat, lng: center.lng }}
        >
          {path.length > 0 && (
            <>
              {/* {selected1 && (
                <OverlayView
                  position={{ lat: path[0].lat, lng: path[0].lng }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  getPixelPositionOffset={getPixelPositionOffset}
                >
                  <div>
                    <div class="popup-bubble">
                      <button style={{ height: 60 }}>
                        {Moment(path[0].createdAt).format("MMMM Do YYYY")}
                      </button>
                    </div>
                    <div class="popup-bubble-anchor" />
                  </div>
                </OverlayView>
              )} */}

              <Marker
                label={{
                  text: `start`,
                  fontSize: "10px",
                  color: "white",
                }}
                position={{
                  // CN Tower default
                  lat: path[0].lat,
                  lng: path[0].lng,
                }}
                onClick={() => {
                  setSelected1((prev) => !prev);
                  setCenter({ lat: path[0].lat, lng: path[0].lng });
                }}
              />
              {selected1 && (
                <InfoWindow
                  position={{ lat: path[0].lat, lng: path[0].lng }}
                  onCloseClick={() => setSelected1((prev) => !prev)}
                >
                  <p>{Moment(path[0].createdAt).format("MMMM Do YYYY")}</p>
                </InfoWindow>
              )}

              {/* {selected2 && (
                <OverlayView
                  position={{
                    lat: path[path.length - 1].lat,
                    lng: path[path.length - 1].lng,
                  }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  getPixelPositionOffset={getPixelPositionOffset}
                >
                  <div>
                    <div class="popup-bubble">
                      <button style={{ height: 60 }}>
                        {Moment(path[path.length - 1].createdAt).format(
                          "MMMM Do YYYY"
                        )}
                      </button>
                    </div>
                    <div class="popup-bubble-anchor" />
                  </div>
                </OverlayView>
              )} */}
              {selected2 && (
                <InfoWindow
                  position={{
                    lat: path[path.length - 1].lat,
                    lng: path[path.length - 1].lng,
                  }}
                  onCloseClick={() => setSelected2((prev) => !prev)}
                >
                  <p>
                    {Moment(path[path.length - 1].createdAt).format(
                      "MMMM Do YYYY"
                    )}
                  </p>
                </InfoWindow>
              )}
              <Marker
                label={{
                  text: `end`,
                  fontSize: "10px",
                  color: "white",
                }}
                position={{
                  // CN Tower default
                  lat: path[path.length - 1].lat,
                  lng: path[path.length - 1].lng,
                }}
                onClick={() => {
                  setSelected2((prev) => !prev);
                  setCenter({
                    lat: path[path.length - 1].lat,
                    lng: path[path.length - 1].lng,
                  });
                }}
              />
            </>
          )}
          {path.length > 0 && (
            <Polyline path={path} options={{ strokeColor: "#0000FF" }} />
          )}
        </GoogleMap>
      </div>
      {path.length === 0 && value === false && (
        <h1 style={{ color: dark ? "white" : "", textAlign: "center" }}>
          {" "}
          No Track Yet
        </h1>
      )}
    </>
  );
};

const MapComponent = withScriptjs(withGoogleMap(Map));

const Trace = () => (
  <>
    <MapComponent
      googleMapURL={`https://maps.googleapis.com/maps/api/js?libraries=geometry,drawing&key=${REACT_APP_MAP_API}`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `80vh`, width: "80%" }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />  
  </>
);

export default Trace;
