import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { login, logout, setItemPerPage } from "../../reducers/userSlice.js";
import { tableCellClasses } from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "../../components/Pagination";
import defaultImage from "../../assets/images/user-profiler.png";
import "../../index.css";
import Button from "@mui/material/Button";
import Moment from "moment";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext.js";
import { refresh } from "../../refresh.js";
import useWindowDimensions from "../../hooks/dimension.js";
import {
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DialogDeleteConfirmation from "../../components/DialogDeleteConfirmation/index.js";
import jwt_decode from "jwt-decode";

// import secureLocalStorage from "react-secure-storage";

const useStyle = makeStyles({
  tableCell: {
    minWidth: "100px",
  },
});
const useStyle1 = makeStyles({
  tableCell: {
    minWidth: "150px",
  },
});

const useStyle2 = makeStyles({
  tableCell: {
    minWidth: "100px",
  },
});

const ListUsers = () => {
  const classes = useStyle();
  const classes1 = useStyle1();
  const classes2 = useStyle2();

  const { REACT_APP_API_ENDPOINT } = process.env;
  const user = useSelector((state) => state.userSlice.user);
  const itemPerPage = useSelector((state) => state.userSlice.itemPerPage);

  const navigate = useNavigate();
  const [usersList, setUsersList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [totalCount, setTotalCount] = useState(0);
  const [pageOrdering, setPageOrdering] = useState(0);
  const { setValue, dark, value } = useContext(UserContext);
  const { width } = useWindowDimensions();
  const [id, setId] = useState("");
  const [open, setOpen] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [allowed, setAllowed] = useState(false);

  const tableContainerSx = {
    border: "0px",
    marginTop: 4,
    borderRadius: 2,
    backgroundColor: "rgba(0, 0, 0, 0)",
    maxWidth: width - 260,
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteHandler = (id) => {
    setValue(true);
    axios
      .delete(`${REACT_APP_API_ENDPOINT}/api/users/` + id, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        const userList = usersList.filter((item) => item.id !== id);
        setUsersList(userList);
        setTotalCount((prev) => prev - 1);
        setCurrentPage(1);
        setPageOrdering((prev) => prev + 1);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          const refresh_token = localStorage.getItem("refresh_token");
          if (refresh_token) {
            axios
              .post(`${REACT_APP_API_ENDPOINT}/token/refresh`, {
                refresh_token: refresh_token,
              })
              .then((data) => {
                console.log("refresh");
                axios
                  .delete(`${REACT_APP_API_ENDPOINT}/api/users/` + id, {
                    headers: {
                      Authorization: "Bearer " + data.data.token,
                    },
                  })
                  .then((res) => {
                    const userList = usersList.filter((item) => item.id !== id);
                    setUsersList(userList);
                    setTotalCount((prev) => prev - 1);
                    setCurrentPage(1);
                    setPageOrdering((prev) => prev + 1);
                    dispatch(
                      login({
                        loggedIn: true,
                        token: data.data.token,
                      })
                    );
                  });
              })
              .catch((e) => {
                console.log(e);
                dispatch(logout());
                navigate("/login");
              });
          } else {
            console.log("no refresh token");
            dispatch(logout());
            navigate("/login");
          }
        }
      })
      .finally(() => {
        setValue(false);
        setOpen(false);
      });
  };
  const activateDeactivate = (userRow, flag) => {
    setValue(true);
    console.log("aaa", user.token);
    const headers = { 'Authorization': 'Bearer ' + user.token };
    axios
      .post(`${REACT_APP_API_ENDPOINT}/api/users/activateDeactivate/${userRow.id}`, { activated: flag }, { headers })
      .then(() => {
        userRow.activated = flag;
      })
      .catch((err) => { })
      .finally(() => { setValue(false) });
  }
  useEffect(() => {
    if (user === null) return;
    const roles = jwt_decode(user.token).roles;
    if (roles.includes("ROLE_ADMIN")) {
      setValue(true);
      axios
        .get(`${REACT_APP_API_ENDPOINT}/api/AllCustomers`, {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        })
        .then((data) => {
          setCustomerList(data.data["hydra:member"]);
          setAllowed(true);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            refresh(dispatch, navigate);
          }
        })
        .finally(() => {
          setValue(false);
        });
    }
  }, [REACT_APP_API_ENDPOINT, dispatch, navigate, setValue, user]);

  const handleChangeCustomer = (event) => {
    const value = event.target.value;
    setCustomerId(value);
  };

  useEffect(() => {
    if (user == null) {
      navigate("/login");
      return;
    }

    setValue(true);
    let url = "";
    if (customerId === "") {
      url = `${REACT_APP_API_ENDPOINT}/api/users?page=${currentPage}&itemsPerPage=${itemPerPage}&order%5BcreatedAt%5D=desc`;
    } else {
      url = `${REACT_APP_API_ENDPOINT}/api/users?page=${currentPage}&itemsPerPage=${itemPerPage}&order%5BcreatedAt%5D=desc&customer=${customerId}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((data) => {
        console.log(data.data["hydra:member"], data.data["hydra:totalItems"]);
        setUsersList(data.data["hydra:member"]);
        setTotalCount(data.data["hydra:totalItems"]);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          refresh(dispatch, navigate);
        }
      })
      .finally(() => {
        setValue(false);
      });
  }, [
    REACT_APP_API_ENDPOINT,
    currentPage,
    dispatch,
    navigate,
    user,
    pageOrdering,
    setValue,
    itemPerPage,
    customerId,
  ]);

  const ITEM_HEIGHT = 48;

  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    dispatch(setItemPerPage(value));
  };
  const hashMap = new Map([
    ["ROLE_USER", "user"],
    ["ROLE_CUSTOMER", "customer"],
    ["ROLE_ADMIN", "admin"],
  ]);
  console.log(usersList);
  return (
    <>
      {/* <Button
        variant="contained"
        sx={{
          backgroundColor: "#13a89e",
        }}
        onClick={() => {
          navigate("/createUser");
        }}
      >
        Create
      </Button> */}

      {allowed && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <div>
            <InputLabel
              id="demo-multiple-checkbox-label"
              style={{ color: dark ? "white" : "" }}
            >
              Customer
            </InputLabel>
            <Select
              style={{ width: "100px", height: "40px" }}
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={customerId}
              onChange={handleChangeCustomer}
              input={<OutlinedInput label="Customer" />}
              MenuProps={MenuProps}
              sx={{
                color: dark ? "white" : "",
                background: dark
                  ? "linear-gradient(90deg, #314f8b 0%, #281a4b 95%, #281a4b 100%)"
                  : "",
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
            >
              {customerList.map((data, index) => (
                <MenuItem key={index} value={data.id}>
                  <ListItemText primary={data.name} />
                </MenuItem>
              ))}
            </Select>
          </div>
          &nbsp; &nbsp;
          <div style={{ height: 50, marginTop: 20 }}>
            <button
              onClick={() => setCustomerId("")}
              style={{
                color: "white",
                padding: 10,
                background:
                  "linear-gradient(90deg, #314f8b 0%, #281a4b 95%, #281a4b 100%)",
                border: "1px solid white",
                borderRadius: 10,
              }}
            >
              reset
            </button>
          </div>
        </div>
      )}

      <DialogDeleteConfirmation
        open={open}
        handleClose={handleClose}
        message={"Are you sure you want to delete this user ?"}
        deleteHandler={deleteHandler}
        id={id}
      />
      <br />
      <br />
      <TableContainer sx={tableContainerSx}>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                "& th": {
                  fontSize: "0.8rem",
                  color: dark ? "white" : "",
                },
              }}
            >
              <TableCell align="center">image</TableCell>
              <TableCell className={classes.tableCell} align="center">
                device id
              </TableCell>
              <TableCell align="center">firstname</TableCell>
              <TableCell align="center">lastname</TableCell>
              <TableCell className={classes1.tableCell} align="center">
                created on
              </TableCell>
              <TableCell align="center">user</TableCell>
              <TableCell className={classes1.tableCell} align="center">
                mobile number
              </TableCell>
              {allowed && (<>
                <TableCell className={classes2.tableCell} align="center">
                  role
                </TableCell>
                <TableCell align="center"></TableCell>
              </>)}
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usersList.map((row, index) => (
              <TableRow
                hover
                key={index}
                sx={{
                  "& td": {
                    color: dark ? "white" : "",
                    fontSize: "0.6rem",
                  },
                }}
              >
                <TableCell align="center">
                  {row?.fileUrl?.includes("images") && (
                    <img
                      src={row.fileUrl}
                      alt=""
                      width="50"
                      style={{ borderRadius: "50%" }}
                    />
                  )}
                  {!row?.fileUrl?.includes("images") && (
                    <img
                      src={defaultImage}
                      alt=""
                      width="50"
                      style={{ borderRadius: "50%" }}
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  {row.devices[row.devices.length - 1]?.device_id}
                </TableCell>
                <TableCell align="center">{row.firstname}</TableCell>
                <TableCell align="center">{row.lastname}</TableCell>
                <TableCell align="center">
                  {Moment(row.createdAt).format("MMMM Do YYYY")}
                </TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="center">{row.phone}</TableCell>
                <>
                  <TableCell align="center">
                    {hashMap.get(row.roles[0])}
                  </TableCell>
                  <TableCell align="center">
                    <button
                      disabled={value || (hashMap.get(row.roles[0]) === "customer")}
                      style={{ fontSize: 13, width: 90 }}
                      className={row.activated ? "btn btn-danger" : "btn btn-success"}
                      onClick={() => {
                        activateDeactivate(row, !row.activated)
                      }}
                    >
                      {row.activated ? "deactivate" : "activate"}
                    </button>
                  </TableCell>
                </>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#13a89e",
                      fontSize: 11,
                      padding: 1,
                    }}
                    onClick={() => {
                      navigate("/showUser/" + row.id);
                    }}
                  >
                    Show
                  </Button>
                </TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#92c8ec",
                      fontSize: 11,
                      padding: 1,
                    }}
                    onClick={() => {
                      navigate("/editUser/" + row.id);
                    }}
                  >
                    Edit
                  </Button>
                </TableCell>
                <TableCell align="center">
                  <button
                    disabled={(!allowed && hashMap.get(row.roles[0]) === "customer")}
                    onClick={() => {
                      setId(row.id);
                      setOpen(true);
                    }}
                    style={{ fontSize: 13 }}
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <hr style={{ color: dark ? "white" : "black" }} />
      <br />
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <InputLabel
          id="demo-multiple-checkbox-label"
          style={{ color: dark ? "white" : "", fontSize: "10px" }}
        >
          Rows per page
        </InputLabel>
        &nbsp; &nbsp; &nbsp;
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          value={itemPerPage}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected}
          MenuProps={MenuProps}
          sx={{
            color: dark ? "white" : "",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: dark ? "white" : "",
            },
            ".MuiSvgIcon-root ": {
              fill: "white !important",
            },
          }}
        >
          <MenuItem key={1} value={5}>
            <ListItemText primary={5} />
          </MenuItem>

          <MenuItem key={2} value={20}>
            <ListItemText primary={20} />
          </MenuItem>

          <MenuItem key={3} value={50}>
            <ListItemText primary={50} />
          </MenuItem>
        </Select>
      </div>

      <Pagination
        className="pagination-bar"
        page={currentPage}
        count={Math.ceil(totalCount / itemPerPage)}
        onChange={(event, value) => {
          setCurrentPage(value);
        }}
      />
    </>
  );
};

export default ListUsers;
