import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const DialogDeleteConfirmation = (props) => {
  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={props.handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Warning"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Disagree</Button>
        <Button
          onClick={() => {
            props.deleteHandler(props.id);
          }}
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDeleteConfirmation;
