// import "../../index.css";
import {
  Button,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DialogDeleteConfirmation from "../../components/DialogDeleteConfirmation";
import { UserContext } from "../../context/UserContext";
import { refresh } from "../../refresh";
import Moment from "moment";
import useWindowDimensions from "../../hooks/dimension";
import { login, logout, setItemPerPage } from "../../reducers/userSlice";
import jwt_decode from "jwt-decode";
import { tableCellClasses } from "@mui/material/TableCell";
import Pagination from "../../components/Pagination";
import { makeStyles } from "@mui/styles";

const { REACT_APP_API_ENDPOINT } = process.env;

const Message = () => {
  const itemPerPage = useSelector((state) => state.userSlice.itemPerPage);
  const { setValue, dark } = useContext(UserContext);
  const [messages, setMessages] = useState([]);
  const auth = useSelector((state) => state.userSlice.user);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [totalCount, setTotalCount] = useState(0);
  const [pageOrdering, setPageOrdering] = useState(0);
  const [customerId, setCustomerId] = useState("");
  const [id, setId] = useState(null);
  const [open, setOpen] = useState(false);
  const [openAll, setOpenAll] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [allowed, setAllowed] = useState(false);
  const useStyle1 = makeStyles({
    tableCell: {
      minWidth: "150px",
    },
  });
  const MenuProps = {
    PaperProps: {
      style: {
        height: "auto",
        width: 250,
      },
    },
  };

  const handleChangePage = (event) => {
    const {
      target: { value },
    } = event;
    dispatch(setItemPerPage(value));
  };

  const handleChangeCustomer = (event) => {
    const value = event.target.value;
    console.log(value);
    setCustomerId(value);
  };

  const { width } = useWindowDimensions();

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAll = () => {
    setOpenAll(false);
  };

  const deleteAllHandler = () => {
    setValue(true);
    const roles = jwt_decode(auth.token).roles;
    let url = "";
    url = `${REACT_APP_API_ENDPOINT}/api/alerts/deleteAll`;
    axios
      .delete(url, {
        headers: {
          Authorization: "Bearer " + auth.token,
        },
      })
      .then(() => {
        setMessages([]);
        setTotalCount(0);
        setCurrentPage(1);
        // setPageOrdering((prev) => prev + 1);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          const refresh_token = localStorage.getItem("refresh_token");
          if (refresh_token) {
            axios
              .post(`${REACT_APP_API_ENDPOINT}/token/refresh`, {
                refresh_token: refresh_token,
              })
              .then((data) => {
                console.log("refresh");
                axios
                  .delete(url, {
                    headers: {
                      Authorization: "Bearer " + data.data.token,
                    },
                  })
                  .then(() => {
                    setMessages([]);
                    setTotalCount(0);
                    setCurrentPage(1);
                    // setPageOrdering((prev) => prev + 1);
                    dispatch(
                      login({
                        loggedIn: true,
                        token: data.data.token,
                      })
                    );
                  });
              })
              .catch((e) => {
                console.log(e);
                dispatch(logout());
                navigate("/login");
              });
          } else {
            console.log("no refresh token");
            dispatch(logout());
            navigate("/login");
          }
        }
      })
      .finally(() => {
        setOpenAll(false);
        setValue(false);
      });
  }

  const deleteHandler = (id) => {
    setValue(true);
    axios
      .delete(`${REACT_APP_API_ENDPOINT}/api/alerts/` + id, {
        headers: {
          Authorization: "Bearer " + auth.token,
        },
      })
      .then((res) => {
        const newList = messages.filter((item) => item.id !== id);
        setMessages(newList);
        setTotalCount((prev) => prev - 1);
        setCurrentPage(1);
        setPageOrdering((prev) => prev + 1);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          const refresh_token = localStorage.getItem("refresh_token");
          if (refresh_token) {
            axios
              .post(`${REACT_APP_API_ENDPOINT}/token/refresh`, {
                refresh_token: refresh_token,
              })
              .then((data) => {
                console.log("refresh");
                axios
                  .delete(`${REACT_APP_API_ENDPOINT}/api/alerts/` + id, {
                    headers: {
                      Authorization: "Bearer " + data.data.token,
                    },
                  })
                  .then((res) => {
                    const newList = messages.filter((item) => item.id !== id);
                    setMessages(newList);
                    setTotalCount((prev) => prev - 1);
                    setCurrentPage(1);
                    setPageOrdering((prev) => prev + 1);
                    dispatch(
                      login({
                        loggedIn: true,
                        token: data.data.token,
                      })
                    );
                  });
              })
              .catch((e) => {
                console.log(e);
                dispatch(logout());
                navigate("/login");
              });
          } else {
            console.log("no refresh token");
            dispatch(logout());
            navigate("/login");
          }
        }
      })
      .finally(() => {
        setValue(false);
        setOpen(false);
      });
  };
  useEffect(() => {
    if (auth === null) return;
    const roles = jwt_decode(auth.token).roles;
    if (roles.includes("ROLE_ADMIN")) {
      setValue(true);
      axios
        .get(`${REACT_APP_API_ENDPOINT}/api/AllCustomers`, {
          headers: {
            Authorization: "Bearer " + auth.token,
          },
        })
        .then((data) => {
          setCustomerList(data.data["hydra:member"]);
          setAllowed(true);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            refresh(dispatch, navigate);
          }
        })
        .finally(() => {
          setValue(false);
        });
    }
  }, [dispatch, navigate, setValue, auth]);

  const tableContainerSx = {
    border: "0px",
    marginTop: 4,
    borderRadius: 2,
    backgroundColor: "rgba(0, 0, 0, 0)",
    width: "100%",
    maxWidth: width - 260,
  };

  useEffect(() => {
    // Accessing scss variable "--background-color"
    // and "--text-color" using plain JavaScript
    // and changing the same according to the state of "darkTheme"
    const root = document.documentElement;
    root?.style.setProperty(
      "--background-color",
      dark ? "rgba(255, 255, 255, 0.08)" : "rgba(0, 0, 0, 0.08)"
    );
    root?.style.setProperty("--text-color", dark ? "#fff" : "#000");
  }, [dark]);

  useEffect(() => {
    if (!auth) return;
    setValue(true);
    let url = "";
    if (customerId === "") {
      url = `${REACT_APP_API_ENDPOINT}/api/alerts?page=${currentPage}&itemsPerPage=${itemPerPage}&order%5BcreatedAt%5D=desc`;
    } else {
      url = `${REACT_APP_API_ENDPOINT}/api/alerts?page=${currentPage}&itemsPerPage=${itemPerPage}&order%5BcreatedAt%5D=desc&customer=${customerId}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + auth.token,
        },
      })
      .then((res) => {
        setMessages(res.data["hydra:member"]);
        setTotalCount(res.data["hydra:totalItems"]);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          refresh(dispatch, navigate);
        }
      })
      .finally(() => {
        setValue(false);
      });
    return () => { };
  }, [
    auth,
    dispatch,
    navigate,
    setValue,
    pageOrdering,
    currentPage,
    itemPerPage,
    customerId,
  ]);
  const classes1 = useStyle1();
  return (
    <div>
      <div>
        <Button style={{ float: 'right' }} variant="contained" color="error" onClick={() => { setOpenAll(true) }}>
          Delete All
        </Button>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#13a89e",
              height: 50,
              marginTop: 2,
              marginBottom: 2,
            }}
            onClick={() => {
              navigate("/createMessage");
            }}
          >
            Create
          </Button>
          &nbsp; &nbsp;
          {allowed && (
            <>
              <div>
                <InputLabel
                  id="demo-multiple-checkbox-label"
                  style={{ color: dark ? "white" : "" }}
                >
                  Customer
                </InputLabel>
                <Select
                  style={{ width: "100px", height: "40px" }}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={customerId}
                  onChange={handleChangeCustomer}
                  input={<OutlinedInput label="Customer" />}
                  MenuProps={MenuProps}
                  sx={{
                    color: dark ? "white" : "",
                    background: dark
                      ? "linear-gradient(90deg, #314f8b 0%, #281a4b 95%, #281a4b 100%)"
                      : "",
                    "& .MuiOutlinedInput-root": {
                      "& > fieldset": { borderColor: dark ? "white" : "" },
                    },
                  }}
                >
                  {customerList.map((data, index) => (
                    <MenuItem key={index} value={data.id}>
                      <ListItemText primary={data.name} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
              &nbsp; &nbsp;
              <div style={{ height: 50, marginTop: 20 }}>
                <button
                  onClick={() => setCustomerId("")}
                  style={{
                    color: "white",
                    padding: 10,
                    background:
                      "linear-gradient(90deg, #314f8b 0%, #281a4b 95%, #281a4b 100%)",
                    border: "1px solid white",
                    borderRadius: 10,
                  }}
                >
                  reset
                </button>
              </div>
            </>
          )}

        </div>


        <DialogDeleteConfirmation
          open={open}
          handleClose={handleClose}
          message={"Are you sure you want to delete this message ?"}
          deleteHandler={deleteHandler}
          id={id}
        />
        <DialogDeleteConfirmation
          open={openAll}
          handleClose={handleCloseAll}
          message={"Are you sure you want to delete all the messages ?"}
          deleteHandler={deleteAllHandler}
          id={id}
        />
      </div>

      <br />
      <br />
      <TableContainer sx={tableContainerSx}>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                "& th": {
                  fontSize: "0.8rem",
                  color: dark ? "white" : "",
                },
              }}
            >
              <TableCell className={classes1.tableCell} align="center">
                title
              </TableCell>
              <TableCell align="center">message</TableCell>
              <TableCell className={classes1.tableCell} align="center">
                created on
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {messages.map((row, index) => (
              <TableRow
                hover
                key={index}
                sx={{
                  "& td": {
                    color: dark ? "white" : "",
                    fontSize: "0.6rem",
                  },
                }}
              >
                <TableCell align="center">{row.title}</TableCell>
                <TableCell align="center">{row.message}</TableCell>
                <TableCell align="center">
                  {Moment(row.createdAt).format("MMMM Do YYYY")}
                </TableCell>
                <TableCell align="center">{row.user.email}</TableCell>

                {/* <TableCell align="center">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#00c8ff",
                      fontSize: 11,
                      padding: 1,
                    }}
                    onClick={() => {
                      // navigate("/showDevice/" + row.id);
                    }}
                  >
                    recipients
                  </Button>
                </TableCell> */}

                <TableCell align="center">
                  <button
                    onClick={() => {
                      setId(row.id);
                      setOpen(true);
                    }}
                    style={{ fontSize: 13 }}
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <hr style={{ color: dark ? "white" : "black" }} />
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <InputLabel
          id="demo-multiple-checkbox-label"
          style={{ color: dark ? "white" : "", fontSize: "10px" }}
        >
          Rows per page
        </InputLabel>
        &nbsp; &nbsp; &nbsp;
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          value={itemPerPage}
          onChange={handleChangePage}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected}
          MenuProps={MenuProps}
          sx={{
            color: dark ? "white" : "",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: dark ? "white" : "",
            },
            ".MuiSvgIcon-root ": {
              fill: "white !important",
            },
          }}
        >
          <MenuItem key={1} value={5}>
            <ListItemText primary={5} />
          </MenuItem>

          <MenuItem key={2} value={20}>
            <ListItemText primary={20} />
          </MenuItem>

          <MenuItem key={3} value={50}>
            <ListItemText primary={50} />
          </MenuItem>
        </Select>
      </div>

      <Pagination
        className="pagination-bar"
        page={currentPage}
        count={Math.ceil(totalCount / itemPerPage)}
        onChange={(event, value) => {
          setCurrentPage(value);
        }}
      />
    </div>
  );
};

export default Message;
