import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { FormGroup, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { UserContext } from "../../context/UserContext";
import { login, logout } from "../../reducers/userSlice";
import secureLocalStorage from "react-secure-storage";

const { REACT_APP_API_ENDPOINT } = process.env;

export const CreateContact = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { dark } = useContext(UserContext);
  const auth = useSelector((state) => state.userSlice.user);
  const userId = useParams()["id"];
  const fileInput = useRef(null);

  // imageList, addUpdateIndex,
  const onChange = (e) => {
    setFile(e.target.files[0]);
  };

  let valid =
    firstname !== "" && lastname !== "" && email !== "" && phone !== "";

  const onSubmit = () => {
    if (firstname !== "" && lastname !== "" && email !== "" && phone !== "") {
      setLoading(true);
      var formData = new FormData();

      formData.append("firstname", firstname);

      formData.append("lastname", lastname);

      formData.append("email", email);

      formData.append("phone", phone);

      formData.append("file", file);

      axios
        .post(
          `${REACT_APP_API_ENDPOINT}/api/addContact?user=${userId}`,
          formData,
          {
            headers: {
              Authorization: "Bearer " + auth.token,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((data) => {
          console.log(data);
          navigate("/users");
        })
        .catch((err) => {
          const refresh_token = localStorage.getItem("refresh_token");
          if (refresh_token) {
            axios
              .post(`${REACT_APP_API_ENDPOINT}/token/refresh`, {
                refresh_token: refresh_token,
              })
              .then((data) => {
                console.log("refresh");
                axios
                  .post(
                    `${REACT_APP_API_ENDPOINT}/api/addContact?user=${userId}`,
                    formData,
                    {
                      headers: {
                        Authorization: "Bearer " + data.data.token,
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  )
                  .then((data) => {
                    console.log(data);
                    dispatch(
                      login({
                        loggedIn: true,
                        token: data.data.token,
                      })
                    );
                    navigate("/users");
                  });
              })
              .catch((e) => {
                console.log(e);
                dispatch(logout());
                navigate("/login");
              });
          } else {
            console.log("no refresh token");
            dispatch(logout());
            navigate("/login");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    // Accessing scss variable "--background-color"
    // and "--text-color" using plain JavaScript
    // and changing the same according to the state of "darkTheme"
    const root = document.documentElement;
    root?.style.setProperty("--text-color", dark ? "#fff" : "#000");
  }, [dark]);

  return (
    <>
      <h2 style={{ color: dark ? "white" : "" }}>Create Contact</h2>
      <div style={{ width: "100%", marginBottom: "5%", marginTop: "3%" }}>
        <div style={{ width: "80%", margin: "auto" }}>
          <FormGroup>
            <TextField
              style={{ marginBottom: "20px" }}
              value={firstname}
              onChange={(event) => {
                setFirstname(event.target.value);
              }}
              aria-describedby="my-helper-text"
              label="Firstname"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
            />
            <TextField
              style={{ marginBottom: "20px" }}

              value={lastname}
              onChange={(event) => {
                setLastname(event.target.value);
              }}
              aria-describedby="my-helper-text"
              label="Lastname"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
            />
            <TextField
              style={{ marginBottom: "20px" }}

              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              aria-describedby="my-helper-text"
              label="Email"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
            />
            <TextField
              style={{ marginBottom: "20px" }}

              value={phone}
              onChange={(event) => {
                setPhone(event.target.value);
              }}
              aria-describedby="my-helper-text"
              label="Phone"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
            />

            <div>
              <button
                onClick={() => {
                  fileInput.current.click();
                }}
                htmlFor="files"
                className="btn"
                style={{
                  color: "white",
                  backgroundColor: "#00A79D",
                  marginBottom: "20px",
                }}
              >
                Select Image
              </button>
              <input
                ref={fileInput}
                type="file"
                onChange={onChange}
                style={{ visibility: "hidden" }}
              />
            </div>
            <Button
              disabled={!valid || loading}
              onClick={onSubmit}
              style={{
                width: "20%",
                borderRadius: 10,
                padding: "5px 20px",
                fontSize: "18px",
                backgroundColor: "#00A79D",
                alignSelf: "center",
              }}
              variant="contained"
            >
              Submit
            </Button>
          </FormGroup>
        </div>
      </div>
    </>
  );
};
