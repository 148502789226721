import React, {useState} from "react";
import {
    MapContainer,
    TileLayer,
    FeatureGroup,
    Polygon,
    useMap,
    Popup,
    Marker,
} from "react-leaflet";
import {EditControl} from "react-leaflet-draw";
import {useRef} from "react";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import {DivIcon} from 'leaflet';
import {useEffect} from "react";
import jwt_decode from "jwt-decode";
import axios from "axios";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Input,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    Card,
    ListItemText, Typography,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import "./style.css";
import {useDispatch, useSelector} from "react-redux";
import {refresh} from "../../refresh";
import {useNavigate} from "react-router-dom";
import {login, logout} from "../../reducers/userSlice";
import {useNavigatingAway} from "../../hooks/useNavigatingAway";
import {DialogLeavingPage} from "../../components/DialogLeavingPage";
import secureLocalStorage from "react-secure-storage";
import {useContext} from "react";
import {UserContext} from "../../context/UserContext";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import pencilIcon from '../../assets/images/pencil.png';
import binIcon from '../../assets/images/bin.png';
import plusIcon from '../../assets/images/plus.png';
import searchIcon from '../../assets/images/search.png';
import historyIcon from '../../assets/images/history.png';
import messageIcon from '../../assets/images/message.png';
import cancelIcon from '../../assets/images/cancel.png';
import acceptIcon from '../../assets/images/accept.png';
import CloseIcon from '@mui/icons-material/Close';


const useStyles = makeStyles({
    table: {
        height: "auto",
    },
});

function getDistance(origin, destination) {
    // return distance in meters
    var lon1 = toRadian(origin[1]),
        lat1 = toRadian(origin[0]),
        lon2 = toRadian(destination[1]),
        lat2 = toRadian(destination[0]);

    var deltaLat = lat2 - lat1;
    var deltaLon = lon2 - lon1;

    var a =
        Math.pow(Math.sin(deltaLat / 2), 2) +
        Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2);
    var c = 2 * Math.asin(Math.sqrt(a));
    var EARTH_RADIUS = 6371;
    return c * EARTH_RADIUS * 1000;
}

function toRadian(degree) {
    return (degree * Math.PI) / 180;
}

const PolygonMap = () => {
    const [position, setPosition] = useState([33.33, 35.33]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    // const [center, setCenter] = useState({ lat: 24.4539, lng: 54.3773 });
    const [mapLayers, setMapLayers] = useState([]);
    const [polygons, setPolygons] = useState([]);
    const [recordId, setRecordId] = useState(null);
    const [idDeleted, setIdDeleted] = useState("");
    const [name, setName] = useState("");
    const [selectedIndex, setSelectedIndex] = useState("");
    const [ZOOM_LEVEL, setZoom] = useState(3);
    const user = useSelector((state) => state.userSlice.user);
    const mapRef = useRef();
    const {REACT_APP_API_ENDPOINT} = process.env;
    const [sidebarVisible, setSidebarVisible] = useState(false);

    const {setValue, dark} = useContext(UserContext);

    const [customerId, setCustomerId] = useState("");
    const [customerList, setCustomerList] = useState([]);
    const [allowed, setAllowed] = useState(false);
    const [showCreatePolygonDialog, setShowCreatePolygonDialog] = useState(false);
    const [createPolygonForm, setCreatePolygonForm] = useState({
        name: null,
        message: null,
        fenceId: 0,
        recordId: null,
        latlngs: []
    });

    const [devices, setDevices] = useState([]);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showHistoryDialog, setShowHistoryDialog] = useState(false);
    const [messagesHistory, setMessagesHistory] = useState([]);
    //SEND MESSAGE
    const [showSendMessageForm, setShowSendMessageForm] = useState(false);
    const [message, setMessage] = useState("");
    const [notification, setNotification] = useState(null);

    useEffect(() => {
        if (!user) return;
        const roles = jwt_decode(user.token).roles;
        //this code needs refactoring, as a hotfix we will bypass the if/else by adding or 1 == 1
        if (1 === 1 || roles.includes("ROLE_ADMIN")) {
            if (customerId === "" && roles.includes("ROLE_ADMIN")) return;
            setValue(true);
            axios
                .get(
                    `${REACT_APP_API_ENDPOINT}/api/getGeofenceByCustomer?customer=${customerId}`,
                    {
                        headers: {
                            Authorization: "Bearer " + user.token,
                        },
                    }
                )
                .then((data) => {
                    const fences = data.data;
                    if (fences?.length > 0) {

                        var distance = getDistance(
                            [
                                fences[fences.length - 1].coordinates[0]["lat"],
                                fences[fences.length - 1].coordinates[0]["lng"],
                            ],
                            [
                                fences[fences.length - 1].coordinates[
                                fences[fences.length - 1].coordinates.length - 1
                                    ]["lat"],
                                fences[fences.length - 1].coordinates[
                                fences[fences.length - 1].coordinates.length - 1
                                    ]["lng"],
                            ]
                        );
                        const z = Math.log(distance);
                        setZoom(20 - z);
                        mapRef.current.setView([fences[fences.length - 1].coordinates[0].lat, fences[fences.length - 1].coordinates[0].lng], 20 - z)
                        // setPosition(fences[fences.length - 1].latlngs[0]);
                        //console.log(fences);
                        setPolygons(fences);
                        setMapLayers(fences);
                    } else {
                        setPolygons([]);
                        setMapLayers([]);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    if (err?.response?.status === 401) {
                        refresh(dispatch, navigate);
                    }
                })
                .finally(() => {
                    setValue(false);
                });
        } else {
            axios
                .get(`${REACT_APP_API_ENDPOINT}/api/geofences`, {
                    headers: {
                        Authorization: "Bearer " + user.token,
                    },
                })
                .then((data) => {
                    if (data.data["hydra:member"].length > 0) {
                        setRecordId(
                            data.data["hydra:member"][data.data["hydra:totalItems"] - 1].id
                        );

                        localStorage.setItem('recordId', data.data["hydra:member"][data.data["hydra:totalItems"] - 1].id);

                        if (
                            data.data["hydra:member"][data.data["hydra:totalItems"] - 1].fence
                                .length > 0
                        ) {
                            var points =
                                data.data["hydra:member"][data.data["hydra:totalItems"] - 1]
                                    .fence;
                            points.map((point) => {
                                return (point["saved"] = true);
                            });

                            var distance = getDistance(
                                [
                                    points[points.length - 1].latlngs[0]["lat"],
                                    points[points.length - 1].latlngs[0]["lng"],
                                ],
                                [
                                    points[points.length - 1].latlngs[
                                    points[points.length - 1].latlngs.length - 1
                                        ]["lat"],
                                    points[points.length - 1].latlngs[
                                    points[points.length - 1].latlngs.length - 1
                                        ]["lng"],
                                ]
                            );
                            const z = Math.log(distance);
                            setZoom(20 - z);
                            mapRef.current.setView([points[points.length - 1].latlngs[0].lat, points[points.length - 1].latlngs[0].lng], 20 - z)
                            // setPosition(points[points.length - 1].latlngs[0]);
                            //console.log(points);
                            setPolygons(points);
                            setMapLayers(points);
                        }
                    } else {
                        setPolygons([]);
                        setMapLayers([]);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response.status === 401) {
                        refresh(dispatch, navigate);
                    }
                });
        }
    }, [REACT_APP_API_ENDPOINT, customerId, dispatch, navigate, setValue, user]);

    useEffect(() => {
        if (user === null) return;
        const roles = jwt_decode(user.token).roles;
        if (roles.includes("ROLE_ADMIN")) {
            setValue(true);
            axios
                .get(`${REACT_APP_API_ENDPOINT}/api/AllCustomers`, {
                    headers: {
                        Authorization: "Bearer " + user.token,
                    },
                })
                .then((data) => {
                    if (data.data["hydra:member"].length > 0) {
                        setCustomerId(data.data["hydra:member"][0].id);
                    }
                    setCustomerList(data.data["hydra:member"]);
                    setAllowed(true);
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response.status === 401) {
                        refresh(dispatch, navigate);
                    }
                })
                .finally(() => {
                    setValue(false);
                });
        }
    }, [REACT_APP_API_ENDPOINT, dispatch, navigate, setValue, user]);

    // get all devices
    useEffect(() => {
        let url = `${REACT_APP_API_ENDPOINT}/api/getAllDevices`;
        axios
            .get(url, {
                headers: {
                    Authorization: "Bearer " + user.token,
                },
            })
            .then((data) => {
                const d = data.data["hydra:member"];
                if (d.length !== 0) {
                    setDevices(
                        d.map(
                            (device) =>
                                device.lastLng !== null &&
                                device.lastLat !== null && {
                                    type: "Feature",
                                    properties: {
                                        cluster: false,
                                        deviceId: device.id,
                                        category: device.type,
                                        detail: device,
                                    },
                                    geometry: {
                                        type: "Point",
                                        coordinates: [
                                            parseFloat(device.lastLat),
                                            parseFloat(device.lastLng),
                                        ],
                                    },
                                }
                        )
                    );
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401) {
                    refresh(dispatch, navigate);
                }
            });
    }, [user])
    const _onEditStop = (e) => {
        //console.log('onEditStop');
    }

    const _onCreate = (e) => {
        setCanShowDialogLeavingPage(true);
        const {layerType, layer} = e;
        const {_leaflet_id} = layer;
        const firstPolygonPoint = layer.getLatLngs()[0][0];
        const lastPolygonPoint = layer.getLatLngs()[0][layer.getLatLngs()[0].length - 1];
        setPosition([firstPolygonPoint["lat"], firstPolygonPoint["lng"]]);
        var distance = getDistance(
            [firstPolygonPoint["lat"], firstPolygonPoint["lng"]],
            [lastPolygonPoint["lat"], lastPolygonPoint["lng"]]
        );
        const z = Math.log(distance);
        setZoom(20 - z);
        setMapLayers((layers) => [
            ...layers,
            {id: _leaflet_id, latlngs: layer.getLatLngs()[0]},
        ]);
        setShowCreatePolygonDialog(true);
        setCreatePolygonForm({
            ...createPolygonForm,
            fenceId: _leaflet_id,
            latlngs: layer.getLatLngs()[0]
        });
    };

    const _onEdited = (e) => {
        setCanShowDialogLeavingPage(true);
        const {
            layers: {_layers},
        } = e;

        Object.values(_layers).map(({_leaflet_id, editing}) => {
            setMapLayers((layers) =>
                layers.map((l) =>
                    l.id === _leaflet_id
                        ? {...l, latlngs: editing.latlngs[0].flat()}
                        : l
                )
            );
        });
    };

    const named = (id, name) => {
        setCanShowDialogLeavingPage(true);
        setMapLayers((layers) =>
            layers.map((l) => (l.id === id ? {...l, name: name} : l))
        );
    };

    const updatePolygonLayerNameById = (id, name) => {
        setPolygons((polygons) =>
            polygons.map((p) => (p.id === id ? {...p, name: name} : p))
        );
    }


    const _onDeleted = (e) => {
        setCanShowDialogLeavingPage(true);
        const {
            layers: {_layers},
        } = e;

        Object.values(_layers).map(({_leaflet_id}) => {
            setMapLayers((layers) => layers.filter((l) => l.id !== _leaflet_id));
        });
    };
    const blackOptions = {color: "black"};

    const handleClose = () => {
        setOpen(false);
    };

    function ChangeView({center, zoom}) {
        const map = useMap();
        map.flyTo(center, zoom, {duration: 2});
        return null;
    }


    const classes = useStyles();

    const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
    const [showDialogLeavingPage, confirmNavigation, cancelNavigation] = useNavigatingAway(canShowDialogLeavingPage);

    const handleChangeCustomer = (event) => {
        const value = event.target.value;
        setCustomerId(value);
    };

    const toggleSidebarHandle = () => {
        setSidebarVisible(!sidebarVisible);
    }

    const SidebarToggle = ({onClick}) => (
        <div onClick={onClick} className={'geofenses-sidebar-toggle'}>&lt;</div>
    )

    const MenuProps = {
        PaperProps: {
            style: {
                height: "auto",
                width: 250,
            },
        },
    };


    const resetEditPolygonForm = () => {
        setSelectedIndex("");
        setName("");
    }
    const resetCreatePolygonForm = () => {
        setCreatePolygonForm({name: null, message: null, fenceId: 0, recordId: null, latlngs: []})
    }
    const cancelCreatePolygon = () => {
        resetCreatePolygonForm();
        setShowCreatePolygonDialog(false);
    };

    const sendAPIRequestWithTokenVerification = (
        endpoint,
        payload,
        actionName //create/edit/...
    ) => {
        var config = (tkn) => {
            return {
                headers: {
                    Authorization: "Bearer " + tkn,
                },
            }
        };

        const axiosAction = actionName == 'getmessagehistory' ? axios.get(endpoint, config(user.token)) : axios.post(endpoint, payload, config(user.token));
        axiosAction.then((data) => {
            switch (actionName) {
                case 'create':
                    window.location.reload(false);
                    break;
                case 'edit':
                    setShowEditForm(false);
                    break;
                case 'sendmessage':
                    resetSendMessageForm();
                    break;
                case 'getmessagehistory':
                    setMessagesHistory(data.data['hydra:member']);
                    break;

            }
        })
            .catch((e) => {
                //check if invalid token and attempt to refresh
                if (e.response.status === 401) {
                    const refresh_token = localStorage.getItem("refresh_token");
                    if (refresh_token) {
                        const refresh_token_endpoint = `${REACT_APP_API_ENDPOINT}/token/refresh`;
                        const refresh_token_payload = {
                            refresh_token: refresh_token,
                        };
                        axios.post(refresh_token_endpoint, refresh_token_payload)
                            .then((data) => {
                                const axiosActionAfterTokenRefresh = actionName == 'getmessagehistory' ? axios.get(endpoint, config(data.data.token)) : axios.post(endpoint, payload, config(data.data.token));

                                axiosActionAfterTokenRefresh
                                    .then(() => {
                                        dispatch(
                                            login({
                                                loggedIn: true,
                                                token: data.data.token,
                                            })
                                        );
                                        window.location.reload(false);
                                    });

                                switch (actionName) {
                                    case 'create':
                                        window.location.reload(false);
                                        break;
                                    case 'edit':
                                        setShowEditForm(false);
                                        break;
                                    case 'sendmessage':
                                        resetSendMessageForm();
                                        break;
                                    case 'getmessagehistory':
                                        setMessagesHistory(data.data['hydra:member']);
                                        break;

                                }
                            })
                            .catch((e) => {
                                console.log(e);
                                dispatch(logout());
                                navigate("/login");
                            });
                    } else {
                        dispatch(logout());
                        navigate("/login");
                    }
                }
            });
    }
    const confirmCreatePolygon = () => {
        setCanShowDialogLeavingPage(false);
        //id is the id of the current customer geofence record
        var endpoint = `${REACT_APP_API_ENDPOINT}/api/createGeofence?customer=${customerId}`;
        var payload = createPolygonForm;
        sendAPIRequestWithTokenVerification(endpoint, payload, 'create');
    };

    const deleteGeofenceHandler = () => {
        //API TO DELETE GEOFENCE GOES HERE
        setCanShowDialogLeavingPage(false);
        setMapLayers((layers) => layers.filter((l) => l.id !== idDeleted));
        setPolygons((layers) => layers.filter((l) => l.id !== idDeleted));
        setOpen(false);
        var endpoint = `${REACT_APP_API_ENDPOINT}/api/deleteGeofence`;
        var payload = {
            recordId: idDeleted,
            fenceId: idDeleted
        };
        sendAPIRequestWithTokenVerification(endpoint, payload, 'delete');
        setNotification({title: 'Success', message: 'Successfully Deleted'})
    }
    const polygonFormNameChange = (e) => {
        setCreatePolygonForm({...createPolygonForm, recordId: localStorage.getItem("recordId"), name: e.target.value});
    };
    const polygonFormMessageChange = (e) => {
        setCreatePolygonForm({
            ...createPolygonForm,
            recordId: localStorage.getItem("recordId"),
            message: e.target.value
        });
    };

    /**
     *  Geofences list sidebar rows methods
     */
    const navigateToGeofence = (geoRow) => {
        const firstPoint = geoRow.coordinates[0];
        const lastPoint = geoRow.coordinates[geoRow.coordinates.length - 1];
        var distance = getDistance(
            [firstPoint["lat"], firstPoint["lng"]],
            [lastPoint["lat"], lastPoint["lng"]]
        );
        const z = Math.log(distance);
        setZoom(20 - z);
        mapRef.current.setView([geoRow.coordinates[0].lat, geoRow.coordinates[0].lng], 20 - z)
    }
    const editGeofenceIconClick = (rowIndex) => {
        setSelectedIndex(rowIndex);
        setShowEditForm(true);
        setName(polygons[rowIndex].name);
    }
    const historyGeofenceIconClick = (row) => {

        var endpoint = `${REACT_APP_API_ENDPOINT}/api/alerts?geofence=${row.id}`;
        var payload = {};
        sendAPIRequestWithTokenVerification(endpoint, payload, 'getmessagehistory');
        setShowHistoryDialog(true);
    }
    const deleteGeofenceIconClick = (row) => {
        setIdDeleted(row.id);
        setOpen(true);
    }
    const saveGeofenceNameClick = () => {
        setShowEditForm(false);

        //API TO SAVE NAME GOES HERE
        //id is the id of the current customer geofence record
        var edit_geofence_endpoint = `${REACT_APP_API_ENDPOINT}/api/createGeofence?customer=${customerId}`;
        var payload = {
            recordId: polygons[selectedIndex].id,
            fenceId: polygons[selectedIndex].id,
            message: null,
            latlngs: [],
            name: name
        };
        sendAPIRequestWithTokenVerification(edit_geofence_endpoint, payload, 'edit');

        //updating the edited geofence name on the map
        named(polygons[selectedIndex].id, name);
        updatePolygonLayerNameById(polygons[selectedIndex].id, name);
        resetEditPolygonForm();
        setCanShowDialogLeavingPage(false);
    }
    const cancelGeofenceEditClick = () => {
        resetEditPolygonForm();
        setShowEditForm(false);
    }
    //SEND MESSAGE
    const resetSendMessageForm = () => {
        setShowSendMessageForm(false);
        setMessage('');
        setSelectedIndex('');
    }
    const sendMessage = () => {
        var endpoint = `${REACT_APP_API_ENDPOINT}/api/createGeofence?customer=${customerId}`;
        var payload = {
            recordId: polygons[selectedIndex].id,
            fenceId: polygons[selectedIndex].id,
            message: message,
            latlngs: [],
            name: polygons[selectedIndex].name
        };
        sendAPIRequestWithTokenVerification(endpoint, payload, 'sendmessage');
        resetSendMessageForm();
    }
    const cancelSendMessageClick = () => {
        resetSendMessageForm();
    }
    const sendMessageIconClick = (id) => {
        setSelectedIndex(id);
        setShowSendMessageForm(true);
    }


    // Fixing the default icon issue in React Leaflet
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
        iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    });
    return (
        <>
            {/*CREATE POLYGON FORM*/}
            <Dialog fullWidth open={showCreatePolygonDialog} onClose={cancelCreatePolygon}>
                <DialogTitle>Create Geofence</DialogTitle>
                <DialogContent>
                    <input onChange={polygonFormNameChange} className={'form-control mb-3'}
                           placeholder={'Add Geofence Name'} type={'text'} value={createPolygonForm?.name}/>
                    <textarea onChange={polygonFormMessageChange} className={'form-control'}
                              placeholder={'Type Your Message Here'} value={createPolygonForm?.message}/>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={cancelCreatePolygon}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={confirmCreatePolygon}>
                        Send and Save
                    </Button>
                </DialogActions>
            </Dialog>
            {/*LEAVING PAGE DIALOG*/}
            <Box component="form">
                <DialogLeavingPage
                    showDialog={showDialogLeavingPage}
                    setShowDialog={setCanShowDialogLeavingPage}
                    confirmNavigation={confirmNavigation}
                    cancelNavigation={cancelNavigation}
                />
            </Box>
            <div style={{display: "flex", flexDirection: "row"}}>
                {/*LEFT CUSTOMER BAR*/}
                {allowed && (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                        }}
                    >
                        <div>
                            <InputLabel
                                id="demo-multiple-checkbox-label"
                                style={{color: dark ? "white" : ""}}
                            >
                                Customer
                            </InputLabel>
                            <Select
                                style={{width: "100px", height: "40px"}}
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                value={customerId}
                                onChange={handleChangeCustomer}
                                input={<OutlinedInput label="Customer"/>}
                                MenuProps={MenuProps}
                                sx={{
                                    color: dark ? "white" : "",
                                    background: dark
                                        ? "linear-gradient(90deg, #314f8b 0%, #281a4b 95%, #281a4b 100%)"
                                        : "",
                                    "& .MuiOutlinedInput-root": {
                                        "& > fieldset": {borderColor: dark ? "white" : ""},
                                    },
                                }}
                            >
                                {customerList.map((data, index) => (
                                    <MenuItem key={index} value={data.id}>
                                        <ListItemText primary={data.name}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>
                )}
                &nbsp; &nbsp;
                {/*MAP HOLDER*/}
                <MapContainer center={[12, 12]} zoom={ZOOM_LEVEL} ref={mapRef}>
                    <FeatureGroup>
                        <EditControl
                            position="topright"
                            onCreated={_onCreate}
                            onEditStop={_onEditStop}
                            onEdited={_onEdited}
                            onDeleted={_onDeleted}
                            edit={{
                                remove: false,
                                edit: false
                            }}
                            draw={
                                {
                                    rectangle: false,
                                    polyline: false,
                                    circle: false,
                                    circlemarker: false,
                                    marker: false,
                                    polygon: {
                                        shapeOptions: {
                                            color: 'blue'
                                        },
                                        //below is used to override the drawing icon
                                        icon: new DivIcon({
                                            className: 'leaflet-draw-draw-polygon',
                                            // html: ''
                                        })
                                    },
                                }}
                        />
                    </FeatureGroup>

                    <TileLayer
                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {polygons.map((element, index) => {
                        return (
                            <FeatureGroup color="purple" key={index}>
                                <Popup position={element.coordinates}>
                                    <p>{element.name}</p>
                                </Popup>
                                <Polygon
                                    pathOptions={blackOptions}
                                    positions={element.coordinates}
                                />
                            </FeatureGroup>
                        );
                    })}
                    <MarkerClusterGroup
                        chunkedLoading
                    >
                        {devices.map((marker, index) => (
                            <Marker key={index} position={marker?.geometry?.coordinates}>
                                <Popup>{marker?.properties?.detail?.user?.email}</Popup>
                            </Marker>
                        ))}
                    </MarkerClusterGroup>
                </MapContainer>
                {/*RIGHT SIDEBAR FENCES LIST*/}
                <SidebarToggle onClick={toggleSidebarHandle}/>
                {
                    sidebarVisible && <div style={{marginLeft: "10px"}}>
                        <Card style={{height: "85vh", width: "450px"}} component={Paper}>
                            <div className={'text-center text-bold'} style={{padding: 10}}>Geofences</div>
                            {polygons.map((row, index) => (
                                <div
                                    key={index}
                                    sx={{
                                        "&:last-child td, &:last-child th": {border: 0},
                                    }}
                                    className="d-flex align-items-center justify-content-between geofence-item-row"
                                >
                                    <div
                                        style={{cursor: 'pointer', minWidth: '50px'}}
                                        className={'d-flex align-items-center justify-content-between'}
                                        onClick={() => navigateToGeofence(row)}
                                    >
                                        <div style={{padding: 10}}>{row.name}</div>
                                    </div>
                                    <div className={'actions-holder'}>
                                        {/*NAVIGATE ICON*/}
                                        <img
                                            src={searchIcon}
                                            style={{width: '25px', margin: '0 5px'}}
                                            onClick={() => navigateToGeofence(row)}
                                        />
                                        {/*SEND MESSAGE ICON*/}
                                        <img
                                            src={messageIcon}
                                            style={{width: '25px', margin: '0 5px'}}
                                            onClick={() => sendMessageIconClick(index)}
                                        />
                                        {/*HISTORY ICON*/}
                                        <img
                                            src={historyIcon}
                                            style={{width: '25px', margin: '0 5px'}}
                                            onClick={() => historyGeofenceIconClick(row)}
                                        />
                                        {/*EDIT ICON*/}
                                        <img
                                            onClick={() => editGeofenceIconClick(index)}
                                            src={row.name ? pencilIcon : plusIcon}
                                            style={{width: '25px'}}
                                        />
                                        {/*DELETE ICON*/}
                                        {
                                            <img
                                                src={binIcon}
                                                style={{width: '25px'}}
                                                onClick={() => deleteGeofenceIconClick(row)}
                                            />
                                        }
                                    </div>
                                </div>
                            ))}
                        </Card>
                        {/*SEND MESSAGE DIALOG*/}
                        <Dialog
                            open={showSendMessageForm}
                            keepMounted
                            className={'send-message-dialog'}
                            onClose={cancelSendMessageClick}
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle>{"Send Message"}</DialogTitle>
                            <DialogContent>
                                <textarea
                                    placeholder="message"
                                    className={'form-control'}
                                    value={message}
                                    rows={5}
                                    style={{width: 500}}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                                <DialogContentText id="alert-dialog-slide-description">
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={cancelSendMessageClick}>Cancel</Button>
                                <Button
                                    disabled={!message || !message.trim()}
                                    onClick={sendMessage}>
                                    Send
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {/*HISTORY DIALOG*/}
                        <Dialog
                            open={showHistoryDialog}
                            keepMounted
                            className={'geofence-history-dialog'}
                            onClose={() => setShowHistoryDialog(false)}
                            aria-describedby="alert-dialog-slide-description"
                            // style={{width:500}}
                        >
                            <DialogTitle>
                                {"Geofence History"}
                                <IconButton
                                    aria-label="close"
                                    onClick={() => setShowHistoryDialog(false)}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8
                                    }}
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </DialogTitle>
                            <DialogContent>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Message</TableCell>
                                            <TableCell>Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            messagesHistory.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{item.message}</TableCell>
                                                    <TableCell>{item.createdAt.split('T')[0]}</TableCell>
                                                </TableRow>
                                            ))

                                        }
                                    </TableBody>
                                </Table>
                            </DialogContent>
                        </Dialog>
                        {/*EDIT NAME DIALOG*/}
                        <Dialog
                            open={showEditForm}
                            keepMounted
                            onClose={() => setShowEditForm(false)}
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle>{"Edit Geofence Name"}</DialogTitle>
                            <DialogContent>
                                <Input
                                    type="text"
                                    placeholder="name"
                                    className={'form-control'}
                                    value={name}
                                    style={{width: 300}}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <DialogContentText id="alert-dialog-slide-description">
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={cancelGeofenceEditClick}>Cancel</Button>
                                <Button
                                    onClick={saveGeofenceNameClick}>
                                    Save
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {/*DELETE DIALOG*/}
                        <Dialog
                            open={open}
                            keepMounted
                            onClose={handleClose}
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle>{"Warning"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                    Are you sure you want to delete this fence ?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>Disagree</Button>
                                <Button onClick={deleteGeofenceHandler}>Agree</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                }
                {/*NOTIFICATION DIALOG*/}
                <Dialog
                    open={notification != null}
                    keepMounted
                    className={'geofence-notification-dialog'}
                    aria-describedby="alert-dialog-slide-description"
                    // style={{width:500}}
                >
                    <DialogTitle>
                        <img src={notification?.error ? cancelIcon : acceptIcon}
                             style={{width: 30, marginRight: "10px"}}/>

                        {notification?.title}
                        <IconButton
                            aria-label="close"
                            onClick={() => setNotification(null)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8
                            }}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {notification?.message}
                    </DialogContent>
                </Dialog>
                {/* <pre className="text-left" style={{ color: "white" }}>
            {JSON.stringify(mapLayers, 0, 2)}
          </pre> */}
            </div>
        </>
    );
};

export default PolygonMap;
