import {
  FormGroup,
  Button,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { UserContext } from "../../context/UserContext";
import { login, logout } from "../../reducers/userSlice";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const { REACT_APP_API_ENDPOINT } = process.env;

const CreateCustomer = () => {
  const auth = useSelector((state) => state.userSlice.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const { dark } = useContext(UserContext);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [plainPassword, setPlainPassword] = useState("");
  useEffect(() => {
    // Accessing scss variable "--background-color"
    // and "--text-color" using plain JavaScript
    // and changing the same according to the state of "darkTheme"
    const root = document.documentElement;
    root?.style.setProperty("--text-color", dark ? "#fff" : "#000");
  }, [dark]);

  let valid =
    name !== "" &&
    firstname !== "" &&
    lastname !== "" &&
    email !== "" &&
    phone !== "" &&
    typeof phone === "string" &&
    isValidPhoneNumber(phone);

  const onSubmit = () => {
    if (valid) {
      setLoading(true);

      axios
        .post(
          `${REACT_APP_API_ENDPOINT}/api/customers/createcustomerWithAdmin`,
          {
            name: name,
            email: email,
            plainPassword: plainPassword,
            firstname: firstname,
            lastname: lastname,
            phone: phone
          },
          {
            headers: {
              Authorization: "Bearer " + auth.token,
            },
          }
        )
        .then((data) => {
          console.log(data);
          navigate("/customers");
        })
        .catch((err) => {
          console.log(err);
          // const refresh_token = localStorage.getItem("refresh_token");
          // if (refresh_token) {
          //   axios
          //     .post(`${REACT_APP_API_ENDPOINT}/token/refresh`, {
          //       refresh_token: refresh_token,
          //     })
          //     .then((data) => {
          //       console.log("refresh");
          //       axios
          //         .post(
          //           `${REACT_APP_API_ENDPOINT}/api/customers`,
          //           {
          //             name: name,
          //           },
          //           {
          //             headers: {
          //               Authorization: "Bearer " + data.data.token,
          //             },
          //           }
          //         )
          //         .then((data) => {
          //           console.log(data);
          //           dispatch(
          //             login({
          //               loggedIn: true,
          //               token: data.data.token,
          //             })
          //           );
          //           navigate("/");
          //         });
          //     })
          //     .catch((e) => {
          //       console.log(e);
          //       dispatch(logout());
          //       navigate("/login");
          //     });
          // } else {
          //   console.log("no refresh token");
          //   dispatch(logout());
          //   navigate("/login");
          // }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <>
      <h2 style={{ color: dark ? "white" : "" }}>Create Customer</h2>
      <div style={{ width: "100%", marginBottom: "5%", marginTop: "5%" }}>
        <div style={{ width: "80%", margin: "auto" }}>
          <FormGroup>
            <TextField
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
              id="outlined-basic"
              label="Customer Name"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
            ></TextField>
            <br />
            <h2 style={{ color: dark ? "white" : "" }}>Admin info</h2>
            <br />
            <TextField
              style={{ marginBottom: "20px" }}
              value={firstname}
              onChange={(event) => {
                setFirstname(event.target.value);
              }}
              aria-describedby="my-helper-text"
              label="Firstname"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
            />
            <TextField
              style={{ marginBottom: "20px" }}
              value={lastname}
              onChange={(event) => {
                setLastname(event.target.value);
              }}
              aria-describedby="my-helper-text"
              label="Lastname"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
            />
            <TextField
              style={{ marginBottom: "20px" }}
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              aria-describedby="my-helper-text"
              label="Email"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
            />
            <div style={{ position: "relative" }}>
              {passwordType === "password" ? (
                <AiFillEyeInvisible
                  style={{
                    fontSize: "30px",
                    position: "absolute",
                    marginLeft: "93%",
                    marginTop: 10,
                    cursor: "pointer",
                    zIndex: 10,
                    color: dark ? "white" : "",
                  }}
                  onClick={togglePassword}
                />
              ) : (
                <AiFillEye
                  style={{
                    fontSize: "30px",
                    position: "absolute",
                    marginLeft: "93%",
                    marginTop: 10,
                    cursor: "pointer",
                    zIndex: 10,
                    color: dark ? "white" : "",
                  }}
                  onClick={togglePassword}
                />
              )}
            </div>
            <TextField
              type={passwordType}
              style={{ marginBottom: "20px" }}
              value={plainPassword}
              onChange={(event) => {
                setPlainPassword(event.target.value);
              }}
              aria-describedby="my-helper-text"
              label="Plain Password"
              variant="outlined"
              sx={{
                input: { color: dark ? "white" : "" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: dark ? "white" : "" },
                },
              }}
              InputLabelProps={{
                style: { color: dark ? "#fff" : "" },
              }}
            />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <PhoneInput
                international
                defaultCountry="US"
                value={phone}
                onChange={setPhone}
                style={{ width: "100%", marginBottom: "20px" }}
              />
            </div>
            <Button
              disabled={!valid || loading}
              onClick={onSubmit}
              style={{
                width: "20%",
                borderRadius: 10,
                padding: "5px 20px",
                fontSize: "18px",
                backgroundColor: "#00A79D",
                alignSelf: "center",
              }}
              variant="contained"
            >
              Submit
            </Button>
          </FormGroup>
        </div>
      </div>
    </>
  );
};

export default CreateCustomer;
